import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Input } from "@mui/material";

import arrow from "../../assets/images/arrow-down.png";
import swaparrow from "../../assets/images/swaparrow.png";

import TransactionStatus from "./TransactionStatus";

import { dispatch, useSelector } from "../../store";
import { DefaultAssets } from "../../config";
import { usePovegoContext } from "../../context";
import { getAssets, getBalances, swapAssets } from "../../apis";
import toast from "react-hot-toast";
import { UpdateAssets, UpdateBalances } from "../../store/reducers/crypto";

function Swap() {
  const navigate = useNavigate();
  const { setLoading } = usePovegoContext();
  const { swap, balances } = useSelector((state) => state.crypto);
  const { userId, isKycCompleted } = useSelector((state) => state.auth);

  const [status, setStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [sellAmount, setSellAmount] = useState(0);
  const [buyAmount, setBuyAmount] = useState(0);

  const fetchCrypto = async () => {
    try {
      const assets = await getAssets();
      dispatch(UpdateAssets(assets));
      const balances = await getBalances(userId);

      const sorted = assets.map((asset) => {
        let balance = 0;
        const data = balances.filter((item) => item.asset_id === asset.id);
        if (data.length > 0) {
          balance = data[0].balance;
        }

        return {
          ...asset,
          balance,
        };
      });
      dispatch(UpdateBalances(sorted));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSwap = async () => {
    if (!isKycCompleted) {
      toast.error("İşlem yapabilmek için KYC doğrulaması yapmanız gerek.");
      navigate("/kyc", { state: { showAlert: true } });
      return;
    }

    if (swap) {
      try {
        setLoading(true);
        const params = {
          user_id: userId,
          from_asset_id: swap.fromAssetId,
          to_asset_id: swap.toAssetId,
          from_amount: Number(swap.fromAmount),
        };
        const data = await swapAssets(params);
        if (data) {
          toast.success("Başarıyla takas ettiniz");
          await fetchCrypto();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleAmountChange = (value, type) => {
    if (!isKycCompleted) {
      toast.error("İşlem yapabilmek için KYC doğrulaması yapmanız gerek.");
      navigate("/kyc", { state: { showAlert: true } });
      return;
    }

    if (type === "sell") {
      setSellAmount(value);
      setBuyAmount(value * (balances[sellCurrency - 1].price / balances[1].price));
    } else {
      setBuyAmount(value);
      setSellAmount(value * (balances[1].price / balances[sellCurrency - 1].price));
    }
  };

  useEffect(() => {
    if (!swap || !isKycCompleted) {
      toast.error("İşlem yapabilmek için KYC doğrulaması yapmanız gerek.");
      navigate("/kyc", { state: { showAlert: true } });
    }
  }, [swap, isKycCompleted, navigate]);

  return (
    <React.Fragment>
      {swap && balances && (
        <Box
          sx={{
            backgroundColor: "#1b1b1b",
            position: "relative",
            borderRadius: "16px",
            padding: "20px",
            maxWidth: "400px",
            margin: "auto",
          }}
        >
          {!transactionStatus ? (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <img
                  src={arrow}
                  alt="arrow"
                  style={{
                    position: "absolute",
                    left: 0,
                    transform: "rotate(90deg)",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/anasayfa")}
                />
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Inter",
                    fontSize: "19px",
                    fontWeight: 500,
                  }}
                >
                  Takas Özeti
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "40px",
                  textAlign: "center",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7d7d7d",
                    fontFamily: '"Roboto", sans-serif;',
                  }}
                >
                  Sat
                </Typography>
                <Typography
                  sx={{
                    fontSize: 19,
                    color: "#fff",
                    fontWeight: 500,
                    fontFamily: '"SF Mono", monospace',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "5px",
                  }}
                >
                  <img
                    src={DefaultAssets.filter((item) => item.asset_id === swap.fromAssetId)[0].logo}
                    alt="usd"
                    style={{ marginRight: "5px", width: "18px" }}
                  />
                  {Number(Number(swap.fromAmount).toFixed(4))}
                  <span
                    style={{
                      fontFamily: "Inter",
                      marginLeft: "5px",
                    }}
                  >
                    {balances[swap.fromAssetId - 1].symbol}
                  </span>
                </Typography>
                <img
                  src={swaparrow}
                  alt="arrow"
                  style={{
                    margin: "14px 0px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7d7d7d",
                    fontFamily: '"Roboto", sans-serif;',
                  }}
                >
                  Al
                </Typography>
                <Typography
                  sx={{
                    fontSize: 19,
                    color: "#fff",
                    fontWeight: 500,
                    fontFamily: '"SF Mono", monospace',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "5px",
                  }}
                >
                  <img
                    src={DefaultAssets.filter((item) => item.asset_id === swap.toAssetId)[0].logo}
                    alt="usd"
                    style={{ marginRight: "5px", width: "18px" }}
                  />
                  {Number(Number(swap.toAmount).toFixed(4))}
                  <span
                    style={{
                      fontFamily: "Inter",
                      marginLeft: "5px",
                    }}
                  >
                    {balances[swap.toAssetId - 1].symbol}
                  </span>
                </Typography>
              </Box>
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "13px 0px",
                  marginTop: "10px",
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}
                fullWidth
                onClick={() => {
                  handleSwap();
                  setTransactionStatus(true);
                  setStatus(true);
                }}
              >
                Takas onayla
              </Button>
            </Box>
          ) : (
            <Box>
              <TransactionStatus
                status={status}
                setStatus={setStatus}
                swap={swap}
                balances={balances}
              />
            </Box>
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

export default Swap;
