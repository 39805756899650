import React from 'react';
import { Box } from '@mui/material';
import BellNotification from '../components/BellNotification';
import { usePovegoContext } from "../context"
import HeaderLayout from '../components/header';

function Layout(props) {
  const { setSiderOpen, siderOpen } = usePovegoContext()

  const toggleDrawer = () => {
    setSiderOpen(true);
  };

  return (
    <Box sx={{ backgroundColor: '#131313' }}>
      <Box sx={{ position: 'sticky', top: 0, backgroundColor: '#131313', zIndex: 90 }}>
        <HeaderLayout toggleDrawer={toggleDrawer} />
      </Box>
      <Box sx={{  justifyContent: 'space-evenly', paddingBottom: { lg: '30px', xl: '20px' }, minHeight: { xs: '100vh', md: "130vh", xl: '90vh' }, }}>
        <React.Fragment>{props.children}</React.Fragment>
        {siderOpen && <BellNotification open={setSiderOpen} />}
      </Box>
    </Box>
  );
}

export default Layout;
