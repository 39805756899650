import { persistStore } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import {
    useDispatch as useAppDispatch,
    useSelector as useAppSelector,
} from "react-redux";

import rootReducer from "./reducers";
import persistReducer from "./persistReducer";

const store = configureStore({
    reducer: persistReducer(rootReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
});

const persister = persistStore(store);


const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export { store, persister, dispatch, useSelector, useDispatch };