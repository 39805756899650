import React from "react";
import { createContext, useContext, useState } from "react";

export const PovegoContext = createContext();


export const PovegoProvider = ({ children }) => {

    const [isNotify, setNotify] = useState(false)
    const [loading, setLoading] = useState(false);
    const [deposit, setDeposit] = useState('home');
    const [siderOpen, setSiderOpen] = useState(false);
    const [showCurreny, setShowCurreny] = useState(false);
    const [settingOptions, setSettingOptions] = useState("settings");
    const [addressBook, setAddressBook] = useState(0);

    return (
        <PovegoContext.Provider value={{ setLoading, addressBook, setAddressBook, settingOptions, setSettingOptions, loading, showCurreny, setShowCurreny, siderOpen, setSiderOpen, deposit, setDeposit, isNotify, setNotify }}>
            {children}
        </PovegoContext.Provider>
    )
}

export const usePovegoContext = () => useContext(PovegoContext)