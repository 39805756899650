import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assets: [],
    balances: [],
    swap: {}
};

const crypto = createSlice({
    name: "crypto",
    initialState,
    reducers: {
        UpdateAssets(state, action) {
            const data = action.payload;
            state.assets = data;
        },
        UpdateBalances(state, action) {
            const data = action.payload;
            state.balances = data;
        },
        Swap(state, action) {
            const data = action.payload;
            state.swap = data;
        },
    },
});

export default crypto.reducer;

export const { UpdateAssets, UpdateBalances, Swap } = crypto.actions;