import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Container } from "@mui/material";

import logo from "../assets/images/logo.png";
import NotificationImg from "../assets/images/notificaion_bell.png";
import NotificationNoneImg from "../assets/images/notificaion_none.png";

import { dispatch, useSelector } from "../store";
import { getKYC, getNotifications } from "../apis";
import { usePovegoContext } from "../context";
import { UpdateUserKYC } from "../store/reducers/auth";
import { KYCStatus } from "../config";

function HeaderLayout({ toggleDrawer }) {
  const navigate = useNavigate();
  const { setShowCurreny, isNotify, setNotify } = usePovegoContext();
  const { isLoggedIn, username, userId } = useSelector((state) => state.auth);

  const toggle = () => {
    if (username) {
      toggleDrawer();
      setShowCurreny(false);
    } else {
      navigate("/login");
    }
  };

  const fetchNotifications = async () => {
    try {
      const data = await getNotifications(userId)
      if (data.filter(notification => notification.isNew).length > 0) {
        setNotify(true)
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const updateKYC = async () => {
    try {
      const status = await getKYC(userId)
      dispatch(UpdateUserKYC({ kyc: status === KYCStatus.APPROVED ? true : false }))
    } catch (error) {
      dispatch(UpdateUserKYC({ kyc: false }))
    }
  }

  useEffect(() => {
    if (isLoggedIn && userId) {
      updateKYC()
    }
  }, [isLoggedIn, userId])


  useEffect(() => {
    if (userId) {
      fetchNotifications();
    }
    // eslint-disable-next-line 
  }, [userId]);

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          padding: "23px 0px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link to="/">
          <img alt="logo" src={logo} style={{ zIndex: 120, position: "relative" }}></img>
        </Link>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <Link to={'/withdrawal'} style={{ textDecoration: 'none' }}>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, color: '#fff', fontSize: 20, lineHeight: '24px', fontFamily: '"Inter", sans-serif', fontWeight: location.pathname === '/withdrawal' && 700, marginRight: '20px' }}>
              Çekim
            </Typography>
          </Link>
          <Link to={'/deposit'} style={{ textDecoration: 'none' }}>
            <Typography sx={{ display: { xs: 'none', md: 'block' }, color: '#fff', fontSize: 20, lineHeight: '24px', fontFamily: '"Inter", sans-serif', fontWeight: location.pathname === '/deposit' && 700, marginRight: '20px' }}>
              Yatırma
            </Typography>
          </Link> */}
          {isLoggedIn && (
            <img
              style={{ marginRight: "10px", cursor: "pointer" }}
              src={isNotify ? NotificationImg : NotificationNoneImg}
              alt="notification"
              onClick={() => navigate("/home/notification")}
            ></img>
          )}

          <Button
            sx={{
              fontWeight: 500,
              fontSize: 14,
              color: "#fff",
              backgroundColor: "#E63B1F",
              borderRadius: "20px",
              padding: isLoggedIn ? "5px 18px" : "5px 39px",
              textTransform: "none",
              fontFamily: '"Inter", sans-serif',
              "&::first-letter": {
                textTransform: "capitalize",
              },
              "&:hover": {
                background: "#E63B1F",
              },
            }}
            onClick={() => toggle()}
          >
            {isLoggedIn ? username : "Giriş Yap"}
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default HeaderLayout;
