import { combineReducers } from "redux";

import authReducer from "./auth";
import cryptoReducer from "./crypto";

const reducer = combineReducers({
    auth: authReducer,
    crypto: cryptoReducer,
});

export default reducer;