import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, CircularProgress, Typography } from "@mui/material";

import swaparrow from "../../assets/images/swaparrow.png";
import sucessstatus from "../../assets/images/sucessstatus.png";
import errorstatus from "../../assets/images/errorstatus.png";

import { DefaultAssets } from "../../config";
import { usePovegoContext } from "../../context";

function TransactionStatus({ status, swap, balances }) {
  const history = useNavigate();
  const { loading } = usePovegoContext()

  return (
    <React.Fragment>
      <Typography
        sx={{
          fontSize: 19,
          color: "#fff",
          fontWeight: 500,
          fontFamily: '"Inter", sans-serif',
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {loading ? "İşlem sürüyor" : status ? "İşlem gönderildi" : "Bir hata oluştu."}
      </Typography>
      <Box
        sx={{ margin: loading ? "35px 0px" : "20px 0px", display: "flex", justifyContent: "center" }}
      >
        {loading ? <CircularProgress sx={{ color: "#E63B1F", minWidth: '80px', minHeight: '80px' }} /> : status ? <img src={sucessstatus} alt="sucessstatus"></img> : <img src={errorstatus} alt="errorstatus"></img>}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        px={2}
      >
        <Typography
          sx={{
            fontSize: 19,
            color: "#fff",
            fontWeight: 500,
            fontFamily: '"SF Mono", monospace',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5px",
          }}
        >
          <img
            src={DefaultAssets.filter((item) => item.asset_id === swap.fromAssetId)[0].logo}
            alt="logo"
            style={{ marginRight: "5px", width: "18px" }}
          ></img>
          {Number(Number(swap.fromAmount).toFixed(4))}
          <span
            style={{
              fontFamily: '"Inter", sans-serif',
              marginLeft: "5px",
            }}
          >
            {balances[swap.fromAssetId - 1].symbol}
          </span>
        </Typography>
        <img
          src={swaparrow}
          alt="swaparrow"
          style={{ transform: "rotate(-90deg)" }}
        ></img>
        <Typography
          sx={{
            fontSize: 19,
            color: "#fff",
            fontWeight: 500,
            fontFamily: '"SF Mono", monospace',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5px",
          }}
        >
          <img
            src={DefaultAssets.filter((item) => item.asset_id === swap.toAssetId)[0].logo}
            alt="logo"
            style={{ marginRight: "5px", width: "18px" }}
          ></img>
          {Number(Number(swap.toAmount).toFixed(4))}
          <span
            style={{
              fontFamily: '"Inter", sans-serif',
              marginLeft: "5px",
            }}
          >
            {balances[swap.toAssetId - 1].symbol}
          </span>
        </Typography>
      </Box>
      <Button
        sx={{
          fontWeight: 600,
          fontSize: 18,
          color: "#fff",
          backgroundColor: "#E63B1F",
          borderRadius: "16px",
          padding: "13px 0px",
          marginTop: "30px",
          textTransform: "none",
          fontFamily: '"Inter", sans-serif',
          "&::first-letter": {
            textTransform: "capitalize",
          },
          "&:hover": {
            background: "#E63B1F",
          },
        }}
        fullWidth
        onClick={() => history('/anasayfa')}
      >
        {status ? 'Kapat' : 'Vazgeç'}
      </Button>
    </React.Fragment>
  );
}

export default TransactionStatus;
