import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { Box, Button, Typography } from "@mui/material";

import arrow from "../../assets/images/arrow-down.png";

import { useSelector } from "../../store";
import { getBankBook, getCryptoBook } from "../../apis";
import { DefaultAssets } from "../../config";
import { usePovegoContext } from "../../context";

function AddressBook() {
  const { setSettingOptions, setAddressBook } = usePovegoContext()

  const [addresses, setAddresses] = useState([]);
  const { userId, token } = useSelector((state) => state.auth);

  const fetchAddresses = async () => {
    if (!userId || !token) {
      toast.error("Kullanıcı ID veya Auth Token eksik.")
      return;
    }

    try {
      const crypoBook = await getCryptoBook(userId);
      const bankBook = await getBankBook(userId);
      setAddresses([...crypoBook, ...bankBook]);
    } catch (error) {
      toast.error("Adres alınamadı")
    }
  };

  useEffect(() => {
    if (userId && token) {
      fetchAddresses();
    }
    // eslint-disable-next-line
  }, [userId, token]);


  return (
    <React.Fragment>
      {addresses.map(({ label, id, address, asset_id }, index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "24px 20px",
            borderRadius: "16px",
            backgroundColor: "#1b1b1b",
            cursor: "pointer",
            marginTop: index !== 0 ? "5px" : "0px",
          }}

          onClick={() => { setAddressBook(addresses[index]); setSettingOptions("edit") }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={DefaultAssets.filter((item) => item.asset_id === asset_id)[0].logo} alt="arrow"></img>
            <Box sx={{ marginLeft: "10px" }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 19,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                {label}
              </Typography>
              <Typography
                sx={{
                  color: "#7d7d7d",
                  fontSize: 13,
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                {`${address.substring(0, 6)}...${address.substring(address.length - 6)}`}
              </Typography>
            </Box>
          </Box>
          <img
            src={arrow}
            alt="arrow"
            style={{ transform: "rotate(-90deg)" }}
          ></img>
        </Box>
      ))}
      <Button
        sx={{
          fontWeight: 600,
          fontSize: 18,
          color: "#fff",
          backgroundColor: "#E63B1F",
          borderRadius: "16px",
          padding: "11px 0px",
          width: "100%",
          marginTop: "10px",
          textTransform: "none",
          fontFamily: '"Inter", sans-serif',
          "&::first-letter": {
            textTransform: "capitalize",
          },
          "&:hover": {
            background: "#E63B1F",
          },
        }}
        onClick={() => setSettingOptions('newaddress')}
      >
        Yeni adres ekle
      </Button>
    </React.Fragment>
  );
}

export default AddressBook;
