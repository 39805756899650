import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function WhyPovego() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
            
              

              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 700,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 36, lg: 54 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '500px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
               Neden Povego?
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
                Avantajlarımız ve rakiplerimizden farkımız<br/><br/> 
                Povego, güvenilir ve yenilikçi yatırım fırsatları sunarak rakiplerinden ayrılır. Çünkü Povego tokenları, fiziki karşılığa ve gerçek varlığa dayalıdır. Dolayısıyla tokenların değerinde gerçek varlık fiyatlarından bağımsız olarak ani fiyat hareketleri yaşanmaz.<br/><br/>   
                Povego, yalnızca kendi çıkardığı tokenlarla sınırlı kalmayıp, kullanıcıların tokenize etmek istedikleri varlıkların da işlem görebileceği bir platform sunar. Kullanıcılar, belirli varlıklarını tokenize etmek için başvurabilir ve bu varlıklar platformda alım-satıma açılır. Bu hizmet, yatırımcılara daha geniş ve çeşitli yatırım imkânlar sunar. <br/><br/>   
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default WhyPovego;
  