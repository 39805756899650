import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function HowItWorks() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
            
              

              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 700,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 36, lg: 54 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '500px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              Nasıl Çalışır?
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
                Tokenizasyon ve blockchain hakkında temel bilgiler.<br/><br/>
                Povego, gerçek dünya varlıklarını dijital tokenlara dönüştürerek, bu varlıkları blokzincir üzerinde güvenli ve şeffaf bir şekilde saklar. Tokenizasyon, fiziksel varlıkların dijital temsillerini oluşturarak, daha geniş kitlelere yatırım yapma imkânı sunar. Blockchain teknolojisi sayesinde, her işlem kaydedilir ve geri dönülemez şekilde doğrulanır.<br/><br/>
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default HowItWorks;
  