import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function Personal() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              <strong>Kişisel Verilerin İşlenmesine İlişkin Açık Rıza Metni</strong><br/><br/>
              Kişisel Verilerin Korunması mevzuatı kapsamında istisnaya giren (örneğin kanunlarda öngörülmesi veya sözleşmenin ifası için gerekli olması) haller haricinde, kişisel verilerin işlenmesi ve/veya aktarılması kapsamında aşağıdaki hususlara ilişkin açık rızanı talep ediyoruz:<br/><br/>
              6698 sayılı Kişisel Verilerin Korunması Kanunu gereğince, kişisel verilerimin POVEGO FİNANSAL TEKNOLOJİ ANONİM ŞİRKETİ tarafından, hangi amaçla işleneceği, işlenen kişisel verilerin kimlere ve hangi amaçla aktarılabileceği, kişisel veri toplamanın yöntemi ve hukuki sebebi ve Kanun’un 11. Maddesinde sayılan diğer haklarım hususlarında bilgilendirildim. Bu kapsamda tarafıma sunulan Aydınlatma Metnini okumuşve anlamış bulunmaktayım.<br/><br/>
              <strong>Veri Aktarımı Hakkında Açık Rıza</strong><br/><br/>
              Kişisel Veri paylaşımı Taraflar arasında iş ilişkisi gereği sözlü, yazılı veya elektronik ortamda ve sair yollarla gerçekleştirilecektir.<br/><br/>
              Toplanan kişisel verileriniz, Şirketimiz tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli çalışmaların iş birimlerimiz tarafından yapılması; Şirketimizin insan kaynakları politikalarının yürütülmesinin temini; Şirketimizin ve Şirketimiz ile iş ilişkisi içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini; Şirketimizin ticari itibarının ve oluşturduğu güvenin korunması; bilgilendirme yapılması ve iletişim kurulması, kurulan ilişkiden doğan edimlerin ifa edilmesi ve yükümlülüklerin yerine getirilmesi, faaliyetler ile ilgili bilgi verilmesi ve güvenlik amaçlı kullanılması, sair idari veya adli makamlar tarafından getirilen yükümlülüklerin karşılanması, internet sitesinin geliştirilmesi, reklam ve pazarlamasının yapılması adına istatistiksel veya bilimsel araştırma için toplanması, kullanılması, açıklanması, aktarılması ve işlenmesi; Şirketimizin ticari ve iş stratejilerinin belirlenmesi ve uygulanması amaçlarıyla ve ticari faaliyetlerimizi yürütmek çerçevesinde 6698 sayılı Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları dahilinde işlenecektir.<br/><br/>
              Sitemizi kullanan müşterilerimiz; yukarıda yer alan ve Şirketinizce açıklanan 6698 sayılı Kişisel Verilerin Korunması Kanunu’na ilişkin aydınlatma metninin tamamını okuduğunu ve anladığını, bu kapsamda 6698 sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak Şirketimiz ve/veya Şirketimiz adına şubelerimiz, topluluk şirketlerimiz, tarafından ya da internet sitelerimiz ve bunlarla sınırlı olmamak üzere her türlü kanallar aracılığı ile; kişisel ve/veya özel nitelikli kişisel verilerin; tamamen veya kısmen elde edilmesi, kaydedilmesi, depolanması, güncellenmesi, periyodik olarak kontrol edilmesi, yeniden düzenlenmesi, sınıflandırılması, işlendikleri amaç için gerekli olan ya da ilgili kanunda öngörülen süre kadar muhafaza edilmesi, kanuni ya da hizmete ve/veya iş ilişkisine bağlı fiili gereklilikler halinde Şirketimizin birlikte çalıştığı ya da kanunen yükümlü olduğu kamu kurum ve kuruluşlarıyla ve/veya Türkiye'de veya yurt dışında mukim olan 3. kişi hizmet sağlayıcı, tedarikçi firmalar, sigorta şirketleri ile Şirketimiz ve/veya topluluk şirketlerimiz, şubelerimiz ve çözüm ortaklarınız ile paylaşılması, kanuni ya da hizmete ve/veya iş ilişkisine bağlı fiili gereklilikler halinde yurtdışına aktarılması da dahil olmak üzere işlenmesine, konu hakkında tereddüde yer vermeyecek şekilde bilgi sahibi olarak, aydınlatılmış açık rızaları ile söz konusu işlemlerin tümüne onay verdiklerini kabul, beyan ve taahhüt etmişlerdir.<br/><br/> 
              <strong>Yurtdışına Veri Aktarımı Hakkında Açık Rıza</strong><br/><br/>
              www.povego.com.tr sitesindeki üyelik kayıt formunu ve diğer kişisel bilgilerimi doldurmam sonrasında verilerimin tutulduğu, aktarıldığı sistemlerin, e-posta sağlayıcıların veri tabanlarının yurtdışında konumlandırılmış olması nedeniyle kimlik, iletişim ve indirim koduna ilişkin verilerimin yurtdışına aktarım yapılmasına özgür irademle açık rıza veriyorum.<br/><br/>

              Aydınlatma Metni’ nde açıklandığı üzere; işlenen ve saklanan verilerimin neler olduğu ve hangi amaçlar doğrultusunda işlendiği, hangi kişi, kurum ve kuruluşlara aktarım yapıldığı konularında bilgilendirildim. İrademin değişmesi halinde ise 6698 sayılı Kanunun 11. maddesi uyarınca haklarımın neler olduğunun bilincindeyim.<br/><br/>
</Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default Personal;
  