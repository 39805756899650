import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function Team() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
            
              

              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 700,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 36, lg: 54 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
               Ekibimiz
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              
              <br/><strong>Yunus BECİT (CEO)</strong><br/><br/>

1989 İstanbul doğumlu olan Yunus Becit; lise öğrenimini Bayrampaşa Anadolu Lisesi’nde, lisans öğrenimini ise Bahçeşehir Üniversitesi Hukuk Bölümü’nde tamamlamıştır. İstanbul Ticaret Üniversite’sinde Reklamcılık ve Stratejik Marka Yönetimi bölümünde tezli yüksek lisans yapmaktadır.<br/><br/>
Avukat olan Yunus Becit 8 yıldır baroya kayıtlıdır. Aktif olarak ticaretle ve reklamcılıkla uğraşmaktadır.<br/><br/>
Yunus Becit çeşitli kurum ve kişilere iletişim, dijital pazarlama, sosyal medya, algı ve itibar yönetimi ve siyasal iletişim gibi konularda danışmanlık yapmaktadır.<br/><br/>
2011’de kurduğu Papillon İstanbul şirketinde ve 2019’da kurduğu FİA-Finansal İletişim şirketinde Ajans Başkanı/ Yönetim Kurulu Başkanı olarak görevine devam etmektedir. <br/><br/>
2019 yılında kurduğu Finansal İletişim Ajansı hem Türkiye’de hem Dünya’da finans dikeyinde ilk finans iletişim ajansıdır. 6 yıldır Türkiye’nin tüm önemli finans kurumlarına hizmet vermeye devam etmektedir.<br/><br/>
FİA tecrübesiyle Türkiye’nin ilk token pazaryeri olan Povego’yu 2023 yılında kuran Yunus Becit ilk fintech girişimini hayata geçirmiştir.<br/><br/>
Birçok farklı sektörde yatırımcı olarak yer almaktadır. Yatırım yaptığı başlıca sektörler medya, oyun, fintech, yazılım, e-ihracat, enerji sektörleridir.<br/><br/>
Üsküdar Çengelköy’de ikamet eden Yunus Becit üç çocuk babasıdır.<br/><br/>

<strong>Ufuk KORCAN</strong> <br/><br/>
1998 yılında İstanbul Üniversitesi İktisat Fakültesi İktisat Bölümü’nden mezun olan Ufuk Korcan, çalışma hayatına 1997 yılında Radikal Gazetesi’nde Ekonomi Muhabiri olarak başladı. Ardından Vatan Dergi Grubu, Referans Gazetesi ve Vatan Gazetesi’nde finans editörlüğü yaptı. Vatan Gazetesi’nde finans editörlüğünün yanında köşe yazarlığı da yapan Ufuk Korcan, daha sonra haberturk.com'da ekonomi koordinatörlüğü görevini yürüttü. Bu görevinin ardından Akşam Gazetesi’nde Ekonomi Müdürü olarak çalıştı. Son olarak Finansal İletişim Ajansı’nda Genel Yayın Yönetmeni olarak çalışma hayatına devam eden Ufuk Korcan aynı zamanda Dünya Gazetesi’nde köşe yazarlığı yapmaktadır.<br/><br/> 
İyi derecede İngilizce bilen Ufuk Korcan evli ve 1 çocuk babasıdır.<br/><br/>

<strong>Av. Doç. Dr. Harun ERYİĞİT</strong><br/><br/>

<strong>Akademik Görevler</strong><br/>
DOÇENT	İSTANBUL MEDİPOL ÜNİVERSİTESİ/HUKUK FAKÜLTESİ/ÖZEL HUKUK BÖLÜMÜ/TİCARET HUKUKU ANABİLİM DALI 20.07.2023<br/>											
DOKTOR ÖĞRETİM ÜYESİ	İSTANBUL MEDİPOL ÜNİVERSİTESİ/HUKUK FAKÜLTESİ/ÖZEL HUKUK BÖLÜMÜ/TİCARET HUKUKU ANABİLİM DALI 01.02.2020-31.01.2024<br/>
ARAŞTIRMA GÖREVLİSİ	İSTANBUL MEDİPOL ÜNİVERSİTESİ/HUKUK FAKÜLTESİ/ÖZEL HUKUK BÖLÜMÜ/TİCARET HUKUKU ANABİLİM DALI 16.09.2013-31.01.2020	<br/><br/>
<strong>Dersler</strong>	<br/>					
TİCARET HUKUKU ( KIYMETLİ EVRAK VE SİGORTA)<br/>
TİCARET HUKUKU (İYBF) (GÜZ DÖNEMİ)<br/>
SERMAYE PİYASASI HUKUKUNUN TEMEL KAVRAMLARI<br/><br/>					

<strong>Uluslararası bilimsel toplantılarda sunulan ve bildiri kitaplarında (proceedings) basılan bildiriler</strong><br/>
ERYİĞİT HARUN (2021).  Güncel Tartışmalar Işığında Yapay Zekâ ve Anonim Şirket Yönetim Kurullarının Yeniden Şekillenmesi.  Uluslararası Bilişim Ve Teknoloji Hukuku Sempozyumu, 99-126. (Tam Metin Bildiri/Sözlü Sunum) (Yayın No: 7656338)<br/>	
ERYİĞİT HARUN (2021).  DEPREM ÖNCESİ TACİRİN YÜKÜMLÜLÜKLERİ ÜZERİNE YARGITAY KARARLARI ÇERÇEVESİNDE BİR DEĞERLENDİRME.  Ulusal ve Uluslararası Yargı Kararları Işığında Deprem Hukuku (Deprem Öncesi Yükümlülükler – Deprem Sonrası Haklar) Uluslararası Sempozyumu, 175-195. (Tam Metin Bildiri/Sözlü Sunum) (Yayın No: 7656328)<br/><br/>
				
<strong>Yazılan ulusal/uluslararası kitaplar veya kitaplardaki bölümler:</strong><br/>	
Anonim Şirketlerin Yönetim Sözleşmesi Kapsamında Yönetimi ve Sonuçları (2023)., ERYİĞİT HARUN,  On İki Levha Yayıncılık, Basım sayısı:1, Sayfa Sayısı 308, ISBN:978-625-432-466-6, Türkçe(Bilimsel Kitap) (Yayın No: 8247628)	<br/>
6102 Sayılı Türk Ticaret Kanunu Çerçevesinde Anonim Ortaklıklarda Sermayenin Korunması İlkesi (2020)., ERYİĞİT HARUN,  On İki Levha, Basım sayısı:1, Sayfa Sayısı 348, ISBN:978-625-7773-16-4, Türkçe(Bilimsel Kitap) (Yayın No: 6452623)<br/><br/>
		
<strong>Ulusal hakemli dergilerde yayımlanan makaleler :</strong><br/>
ERYİĞİT HARUN (2023).  Kambiyo Senetlerinde İptal Kararının İptali ve Sonuçları.  Bahçeşehir Üniversitesi Hukuk Fakültesi Dergisi, 18(212), 129-173. (Kontrol No: 8267160)	<br/>
ERYİĞİT HARUN (2022).  Markaların Anonim Şirketlerde Sermaye Olarak Taahhüdünde Aktifleştirme Yasağı ve Marka Değerinin Bilançodaki Yerinin Tespiti.  Hacettepe Hukuk Fakültesi Dergisi, 12(1), 299-328., Doi: 10.32957/hacettepehdf.1086464 (Kontrol No: 7918189)<br/>
ERYİĞİT HARUN (2021).  Türk Hukukunda Merkezi Karşı Taraf Kuruluşlarının Temerrüt Haline İlişkin Düzenleme İhtiyacı ve Örnekler Işığında Öneriler.  Istanbul Medipol Universitesi, 8(2), 535-555., Doi: 10.46547/imuhfd.2021.08.02.04 (Kontrol No: 7656349)<br/>
ERYİĞİT HARUN (2020).  Ticari Dava Şartı Olan Arabuluculuk Başvurularında Nispi Ticari Davanın Tespiti.  Istanbul Medipol Universitesi, 7(2), 259-288., Doi: 10.46547/imuhfd.2020.07.2.03 (Kontrol No: 7157703)		<br/>
ERYİĞİT HARUN (2020).  Alacaklıların Korunmasına İlişkin Farklı Yöntemlerin Sermayenin Korunması İlkesi Bağlamında Değerlendirilmesi.  Istanbul Medipol Universitesi, 7(1), 215-237., Doi: 10.46547/imuhfd.2020.07.1.05 (Kontrol No: 6354084)	<br/>
ERYİĞİT HARUN (2017).  Tüzel Kişilerin Ticari Temsilci Olarak Atanıp Atanamayacağı Sorunu.  İstanbul Medipol Üniversitesi Hukuk Fakültesi Dergisi, 4(1), 161-181. (Kontrol No: 6079908)		<br/>
ERYİĞİT HARUN (2015).  İmtiyazlı Pay Sahipleri Özel Kurulunun Organ Sıfatı.  İstanbul Medipol Üniversitesi Hukuk Fakültesi Dergisi, 2(2), 213-227. (Kontrol No: 6079914)<br/>



</Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default Team;
  