import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import setting from "../assets/images/setting.png";
import logout from "../assets/images/logout.png";
import eye from "../assets/images/eye.png";
import logo from "../assets/images/logo.png";
import CloseIcon from "../assets/images/close_icon.png";
import NotificationImg from "../assets/images/notificaion_bell.png";
import NotificationNoneImg from "../assets/images/notificaion_none.png";
import { getActivity } from "../apis";
import { ActivityType, DefaultAssets } from "../config";
import { usePovegoContext } from "../context";
import { Logout } from "../store/reducers/auth";
import { dispatch, useSelector } from "../store";
import { formatDateString } from "../helpers";
import toast from "react-hot-toast";
import translations from './../translation.json'

const translate = (text) => translations[text] || text;

function BellNotification({ open }) {
  const navigate = useNavigate();
  const { isNotify, setSettingOptions } = usePovegoContext();
  const { isLoggedIn, username, userId, isKycCompleted } = useSelector((state) => state.auth);
  const { balances } = useSelector((state) => state.crypto);

  const [activity, setActivity] = useState([]);
  const [showMenu, setShowMenu] = useState("Assets");
  const [isBalanceVisible, setIsBalanceVisible] = useState(true);

  const handleLogout = () => {
    dispatch(Logout())
    open(false);
    navigate("/login");
  };

  const handleEyeClick = () => {
    setIsBalanceVisible(!isBalanceVisible);
  };

  const combinedBalances = DefaultAssets.map(asset => {
    const balanceData = balances.find(balance => balance.id === asset.asset_id);
    return {
      ...asset,
      balance: balanceData ? balanceData.balance : 0,
      price: balanceData ? balanceData.balance * balanceData.price : 0,
    };
  });

  const formatBalance = (balance) => {
    return isBalanceVisible ? Number(balance.toFixed(2)) : '****';
  };

  const formatActivityData = (data) => {
    return data.map(item => ({
      ...item,
      type: translate(item.type),
      status: translate(item.status),
    }));
  };

  useEffect(() => {
    const loadActivity = async () => {
      try {
        const data = await getActivity(userId);
        if (data) {
          setActivity(formatActivityData(data)); // Verileri çevirerek ayarlayın
        } else {
          console.error('Activity fetch failed:', data);
        }
      } catch (error) {
        console.error('Error fetching activity:', error);
      }
    };

    loadActivity();
  }, [userId]);

  return (
    <div>
      <Box
        sx={{
          padding: "10px",
          borderRadius: { sm: "30px" },
          backgroundColor: { xs: "#131313", sm: "#232323" },
          width: { xs: "90%", sm: "420px" },  // Küçük ekranlar için genişlik %90, büyük ekranlar için sabit genişlik
          right: { xs: 15, md: 19, xl: 205 },  // Sağdan boşluk ayarları
          position: "fixed",  // Notification her zaman sabit kalacak
          top: { xs: 10, sm: 20 },  // Üstten boşluk
          height: { xs: "auto", md: "auto" },  // Mobilde ve masaüstünde esnek yükseklik
          zIndex: 1100,  // Daha yüksek bir z-index ile tüm elementlerin üstünde olmasını sağlıyoruz
          transition: "all 0.3s ease",  // Geçiş animasyonu ekleniyor
        }}
        className="bell_notification"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "space-between", md: "end" },
            marginBottom: "13px",
          }}
        >
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <img
              alt="logo"
              src={logo}
              style={{ zIndex: 120, position: "relative" }}
            ></img>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={isNotify ? NotificationImg : NotificationNoneImg}
              alt="notificaion_bell"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/home/notification');
                open(false);
              }}
            ></img>
            <img
              src={CloseIcon}
              onClick={() => open(false)}
              alt="notificaion"
              style={{ marginLeft: "10px", cursor: "pointer" }}
            ></img>
          </Box>
        </Box>
        <Box
          sx={{ minWidth: { xs: "370px", sm: "400px" } }}
          className="bell_inner_section"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "18px 20px",
              borderRadius: "16px",
              backgroundColor: "#1b1b1b",
            }}
          >
            {isLoggedIn ? (
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: 30,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                {username}
              </Typography>
            ) : (
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "13px 0px",
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}
                onClick={() => {
                  open(false);
                  navigate('/login');
                }}
              >
                Login
              </Button>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={setting}
                style={{ cursor: "pointer" }}
                alt="setting"
                onClick={() => {
                  setSettingOptions("settings")
                  navigate("/anasayfa/ayarlar");
                  open(false);
                }}
              ></img>
              {isLoggedIn && (
                <img
                  src={logout}
                  style={{ marginLeft: "35px", cursor: "pointer" }}
                  alt="logout"
                  onClick={handleLogout}
                ></img>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              padding: "22px 20px",
              borderRadius: "16px",
              backgroundColor: "#1b1b1b",
              marginTop: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#7d7d7d",
                  fontWeight: 500,
                  fontSize: 14,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: '"Roboto", sans-serif',
                }}
              >
                Toplam Bakiye
              </Typography>
              <img
                src={eye}
                alt="eye"
                style={{ marginLeft: "8px", cursor: "pointer" }}
                onClick={handleEyeClick}
              ></img>
            </Box>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 500,
                fontSize: 33,
                fontFamily: '"SF Mono", monospace',
              }}
            >
              ₺{formatBalance(combinedBalances.reduce((total, asset) => total + asset.price, 0))}
            </Typography>
            <Typography
              sx={{
                color: "#7d7d7d",
                fontWeight: 500,
                fontSize: 13,
                fontFamily: '"SF Mono", monospace',
              }}
            >
              ${formatBalance(combinedBalances.reduce((total, asset) => total + asset.price, 0) / 33)}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "18px",
                marginTop: "23px",
              }}
            >
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "11px 0px",
                  width: "100%",
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}
                onClick={() => {
                  if (!isKycCompleted) {
                    //toast.error("İşlem yapabilmek için KYC doğrulaması yapmanız gerek.");
                    open(false); // Bildirimi kapat
                    navigate("/kyc", { state: { showAlert: true } });
                  } else {
                    navigate("/parayatırma");
                    open(false); // Bildirimi kapat
                  }
                }}
              >
                Yatırma
              </Button>

              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "11px 0px",
                  width: "100%",
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}
                onClick={() => {
                  if (!isKycCompleted) {
                    //toast.error("İşlem yapabilmek için KYC doğrulaması yapmanız gerek.");
                    open(false); // Bildirimi kapat
                    navigate("/kyc", { state: { showAlert: true } });
                  } else {
                    navigate("/paracekme");
                    open(false); // Bildirimi kapat
                  }
                }}
              >
                Çekme
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: "12px",
            }}
          >
            <Typography
              sx={{
                color: showMenu === "Assets" ? "#fff" : "#7d7d7d",
                fontWeight: 600,
                fontSize: 19,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                fontFamily: '"Inter", sans-serif',
              }}
              onClick={() => setShowMenu("Assets")}
            >
              Varlıklar
            </Typography>
            <Typography
              sx={{
                color: showMenu === "Activity" ? "#fff" : "#7d7d7d",
                fontWeight: 600,
                fontSize: 19,
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                fontFamily: '"Inter", sans-serif',
              }}
              onClick={() => setShowMenu("Activity")}
            >
              Aktivite
            </Typography>
          </Box>

          <Box sx={{ maxHeight: '350px', overflow: 'auto' }}>
            {showMenu === "Assets" ? (
              <React.Fragment>
                {combinedBalances.map((asset) => (
                  <Box
                    key={asset.asset_id}
                    sx={{
                      padding: "16px 20px",
                      borderRadius: "16px",
                      backgroundColor: "#1b1b1b",
                      marginTop: "16px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={asset.logo}
                          alt="asset_logo"
                          style={{ marginRight: "10px" }}
                        ></img>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: 600,
                            fontSize: 19,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            fontFamily: '"Inter", sans-serif',
                          }}
                        >
                          {asset.name}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: 19,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          fontFamily: '"SF Mono", monospace',
                        }}
                      >
                        {formatBalance(asset.balance)}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        color: "#7d7d7d",
                        fontWeight: 500,
                        fontSize: 13,
                        marginTop: "6px",
                        fontFamily: '"Inter", sans-serif',
                      }}
                    >
                      {asset.description}
                    </Typography>
                  </Box>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {(activity.length >= 5 ? activity.slice(0, 5) : activity).map((item, key) => {
                  return (
                    <Box
                      key={key}
                      sx={{
                        padding: "16px 20px",
                        borderRadius: "16px",
                        backgroundColor: "#1b1b1b",
                        marginTop: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {item.type === "SWAP" ? (
                            <div>
                              <img
                                src={DefaultAssets[Number(item.from_asset_id) - 1].logo}
                                alt="activity"
                              ></img>
                              <img
                                src={DefaultAssets[Number(item.to_asset_id) - 1].logo}
                                alt="activity"
                                style={{ left: "-10px", position: "relative" }}
                              ></img>
                            </div>
                          ) : (
                            <img
                              src={ActivityType[[item.type]].logo}
                              alt="activity"
                              style={{ marginRight: "10px" }}
                            ></img>
                          )}

                          <Typography
                            sx={{
                              color: "#fff",
                              fontWeight: 600,
                              fontSize: 19,
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              fontFamily: '"Inter", sans-serif',
                            }}
                          >
                            {translate(ActivityType[[item.type]].name)}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: 500,
                            fontSize: 19,
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            fontFamily: '"SF Mono", monospace',
                          }}
                        >
                          {item.type === "SWAP" ? (
                            <>
                              {item.from_amount}{" "}
                              {
                                DefaultAssets.filter(
                                  (asset) => asset.asset_id === item.from_asset_id
                                )[0].name
                              }
                            </>
                          ) : (
                            <>
                              {item.type === "DEPOSIT" || item.type === "BANK_DEPOSIT"
                                ? item.balance
                                : item.amount}
                              &nbsp;
                              {
                                DefaultAssets.filter(
                                  (asset) => asset.asset_id === item.asset_id
                                )[0].name
                              }
                            </>
                          )}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "6px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#7d7d7d",
                            fontWeight: 500,
                            fontSize: 13,
                            fontFamily: '"Inter", sans-serif',
                          }}
                        >
                          {formatDateString(item.created_at)}
                        </Typography>
                        <Typography
                          sx={{
                            color: item.status === "KABUL EDİLDİ" ? "#00A875" : "#00639e",
                            fontWeight: 500,
                            fontSize: 13,
                            textTransform: "uppercase",
                            fontFamily: '"Inter", sans-serif',
                          }}
                        >
                          {translate(item.status)}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </React.Fragment>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default BellNotification;