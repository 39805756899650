import { styled, TextField } from "@mui/material";

export const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiInputBase-root": {
        color: "white", // Text color
        backgroundColor: "#1B1B1B", // Background color
        borderRadius: "16px", // Rounded corners
        padding: "10px 15px", // Padding
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent", // Border color
            backgroundColor: "#1B1B1B",
        },
        "&:hover fieldset": {
            borderColor: "transparent", // Border color on hover
            backgroundColor: "#1B1B1B",
            border: "none !important",
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent", // Border color on focus
            backgroundColor: "#1B1B1B",
            border: "none !important",
        },
    },
    "& .MuiInputLabel-root": {
        color: "#e4e4e7", // Label color
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#e4e4e7", // Label color on focus
    },
}));

export const CustomLoginTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        color: 'white',
        backgroundColor: '#1B1B1B',
        borderRadius: '16px',
        padding: '10px 15px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'transparent',
            backgroundColor: '#1B1B1B',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
    },
    '& .MuiInputLabel-root': {
        color: '#e4e4e7',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#e4e4e7',
    },
}));