import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Box, Snackbar, Alert, CircularProgress, IconButton } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDropzone } from 'react-dropzone';
import imageCompression from 'browser-image-compression';
import { useLocation, useNavigate } from 'react-router-dom';
import cross from '../assets/images/cross.png';
import { submitKYC } from '../apis';
import { useSelector } from '../store';
import toast from 'react-hot-toast';

const KYCPage = () => {
  const { userId, token } = useSelector((state) => state.auth);

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('');
  const [frontID, setFrontID] = useState(null);
  const [backID, setBackID] = useState(null);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.showAlert) {
      setAlertOpen(true);
    }
  }, [location]);

  const compressImage = async (file) => {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
      };
      return await imageCompression(file, options);
    } catch (error) {
      console.error('Image compression error:', error);
      return file;
    }
  };

  const onDrop = async (acceptedFiles, setImage) => {
    const file = acceptedFiles[0];
    const compressedFile = await compressImage(file);
    setImage(compressedFile);
    setError('');
  };

  const handleRemoveImage = (setImage) => () => {
    setImage(null);
  };

  const { getRootProps: getRootPropsFront, getInputProps: getInputPropsFront, open: openFrontID } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, setFrontID),
    noClick: true,
    noKeyboard: true,
  });

  const { getRootProps: getRootPropsBack, getInputProps: getInputPropsBack, open: openBackID } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, setBackID),
    noClick: true,
    noKeyboard: true,
  });

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+\d{8,15}$/;
    return phoneRegex.test(phoneNumber);
  };

  // const submitForm = async (data) => {
  //   try {
  //     const response = await fetch('https://499f-176-88-78-214.ngrok-free.app/kyc/submit/', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Veri gönderme hatası.');
  //     }

  //     return await response.json();
  //   } catch (error) {
  //     console.error('Veri gönderme hatası:', error);
  //     throw error;
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!frontID) {
      setError('Lütfen kimlik ön yüzünü yükleyin.');
      setTimeout(() => setError(''), 3000);
      return;
    }

    if (!backID) {
      setError('Lütfen selfie yükleyin.');
      setTimeout(() => setError(''), 3000);
      return;
    }

    const sanitizedPhone = phone.replace(/^\+/, '');

    if (!validatePhoneNumber(`+${sanitizedPhone}`)) {
      setError('Geçerli bir telefon numarası girin.');
      setTimeout(() => setError(''), 3000);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("firstName", name);
    formData.append("lastName", surname);
    formData.append("phoneNumber", `+${sanitizedPhone}`);
    formData.append("idPhotoName", frontID.name);
    formData.append("selfiePhotoName", backID.name);
    formData.append("idPhoto", frontID);
    formData.append("selfiePhoto", backID);

    try {
      const response = await submitKYC(formData, userId)
      if (response) {
        toast.success('KYC doğrulamanız tamamlanmıştır.');
        setName('');
        setSurname('');
        setPhone('');
        setFrontID(null);
        setBackID(null);

      }
    } catch (error) {
      toast.error(`Gönderme sırasında bir hata oluştu. Lütfen tekrar deneyin.`);
    } finally {
      setLoading(false);
      setTimeout(() => setSuccessMessage(''), 5000);
      setTimeout(() => setError(''), 3000);
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#121212',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: '20px 20px',
        position: 'relative',
        maxWidth : '600px',
        margin : '0 auto',
      }}
    >

      <IconButton
        aria-label="close"
        sx={{
          color: '#fff',
          position: 'absolute',
          marginLeft: '580px',
          marginTop: '-15px',
          zIndex: 1000,
          // marginLeft:'580px',
          right : '15px',
          marginTop:'-15px',
        }}
        onClick={() => navigate('/anasayfa')}
      >
        <img src={cross} alt="close" style={{ width: '16px', height: '16px' }} />
      </IconButton>

      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: '#1e1e1e',
          padding: '20px',
          borderRadius: '16px',
          boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.5)',
          width: '100%',
          marginTop: '20px',
          marginBottom: '20px',
          '@media (max-width: 600px)': {
            padding: '15px',
            marginTop: '10px',
            marginBottom: '10px',
          },
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Typography variant="h4" sx={{ color: '#fff', marginBottom: 3, textAlign: 'center' }}>
            KYC Doğrulama
          </Typography>

          <TextField
            label="Ad"
            variant="filled"
            fullWidth
            required
            sx={{
              marginBottom: 2,
              backgroundColor: '#333',
              input: { color: '#fff' },
              borderRadius: '16px',
              '@media (max-width: 600px)': {
                fontSize: '14px',
              },
            }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Soyad"
            variant="filled"
            fullWidth
            required
            sx={{
              marginBottom: 2,
              backgroundColor: '#333',
              input: { color: '#fff' },
              borderRadius: '16px',
              '@media (max-width: 600px)': {
                fontSize: '14px',
              },
            }}
            InputLabelProps={{
              style: { color: '#fff' },
            }}
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />

          <PhoneInput
            country={'tr'}
            value={phone}
            onChange={setPhone}
            inputStyle={{
              width: '100%',
              height: '56px',
              backgroundColor: '#333',
              borderRadius: '16px',
              color: '#fff',
              paddingLeft: '48px',
              fontSize: '16px',
              '@media (max-width: 600px)': {
                fontSize: '14px',
                height: '48px',
              },
            }}
            buttonStyle={{
              borderRadius: '16px 0 0 16px',
              backgroundColor: '#333',
              '@media (max-width: 600px)': {
                height: '48px',
              },
            }}
            dropdownClass="custom-dropdown"
            containerStyle={{
              marginBottom: '16px',
              width: '100%',
            }}
          />

          <Box
            {...getRootPropsFront()}
            sx={{
              marginBottom: 2,
              width: '100%',
              backgroundColor: '#333',
              borderRadius: '16px',
              textAlign: 'center',
              color: '#fff',
              position: 'relative',
              fontWeight: 600,
              '@media (max-width: 600px)': {
                fontSize: '14px',
              },
            }}
          >
            <input {...getInputPropsFront()} />
            {frontID ? (
              <>
                <img src={URL.createObjectURL(frontID)} alt="Kimlik Ön Yüzü" style={{ width: '100%', borderRadius: '16px', maxHeight: '300px', objectFit: 'cover' }} />
                <Button
                  onClick={handleRemoveImage(setFrontID)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: '#ff0000',
                    color: '#fff',
                    borderRadius: '0 16px 0 16px',
                    '&:hover': { backgroundColor: '#d00000' },
                  }}
                >
                  Kaldır
                </Button>
              </>
            ) : (
              <Button
                onClick={openFrontID}
                sx={{
                  color: '#fff',
                  width: '100%',
                  height: '100%',
                  padding: '40px',
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                Kimlik Ön Yüzü Yükleyin
              </Button>
            )}
          </Box>

          <Box
            {...getRootPropsBack()}
            sx={{
              marginBottom: 2,
              width: '100%',
              backgroundColor: '#333',
              borderRadius: '16px',
              textAlign: 'center',
              color: '#fff',
              position: 'relative',
              fontWeight: 600,
              '@media (max-width: 600px)': {
                fontSize: '14px',
              },
            }}
          >
            <input {...getInputPropsBack()} />
            {backID ? (
              <>
                <img src={URL.createObjectURL(backID)} alt="Selfie" style={{ width: '100%', borderRadius: '16px', maxHeight: '300px', objectFit: 'cover' }} />
                <Button
                  onClick={handleRemoveImage(setBackID)}
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: '#ff0000',
                    color: '#fff',
                    borderRadius: '0 16px 0 16px',
                    '&:hover': { backgroundColor: '#d00000' },
                  }}
                >
                  Kaldır
                </Button>
              </>
            ) : (
              <Button
                onClick={openBackID}
                sx={{
                  color: '#fff',
                  width: '100%',
                  height: '100%',
                  padding: '40px',
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                Selfie Yükleyin
              </Button>
            )}
          </Box>

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{
              fontWeight: 600,
              fontSize: 18,
              color: "#fff",
              backgroundColor: "#E63B1F",
              borderRadius: "16px",
              padding: "13px 0px",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#E63B1F",
              },
              '@media (max-width: 600px)': {
                fontSize: '14px',
                height: '48px'
              },
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Doğrulama Gönder"}
          </Button>
        </form>

        <Snackbar
          open={alertOpen}
          autoHideDuration={3000}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="warning" onClose={() => setAlertOpen(false)} sx={{ width: '100%' }}>
            İşlem yapabilmek için lütfen KYC doğrulamasını tamamlayın.
          </Alert>
        </Snackbar>

        <Snackbar
          open={Boolean(error)}
          autoHideDuration={3000}
          onClose={() => setError('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="error" onClose={() => setError('')} sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>

        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={3000}
          onClose={() => setSuccessMessage('')}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="success" onClose={() => setSuccessMessage('')} sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default KYCPage;
