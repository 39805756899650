import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function Products() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
            
              

              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 700,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 36, lg: 54 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              Ürünler ve Emtia
              </Typography>
              <br/><br/>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 500,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 28, lg: 40 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >

              Charget nedir? (Elektrik Token’ı)<br/>
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 20px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              Charget, elektrik üretimi ve tüketimini tokenize eden Povego'nun yenilikçi bir ürünüdür. Enerji sektörünün elektrik tokenizasyonu olan Charget, kullanıcıların enerji sektörüne yatırım yapmalarını kolaylaştırır ve enerji ticaretini daha erişilebilir hale getirir. Charget ile hem sürdürülebilir enerji projelerine destek olabilir, hem de geleceğin enerji piyasasında yerinizi alabilirsiniz. Otomobil şarj istasyonlarında kullanılan elektriğin tokenize edilmiş hali olan Charget sayesinde bireyler ve kurumlar ilerideki dönemde elektrik fiyatlarında yaşanacak olası artışlardan etkilenmemek için  bugünden giderlerini kontrol altına alabilir ya da elektrik fiyatlarındaki artıştan gelir elde edebilir.<br/><br/>

              <strong>Neden Elektrik Token'ı?</strong><br/><br/>
              Elektrik sektörü, ekonomik büyümenin ve sürdürülebilir kalkınmanın temel taşlarından biridir. Elektrik tokenları, bu sektörde yenilikçi çözümler sunarak elektrik üretimi, dağıtımı ve tüketimi süreçlerini dönüştürmektedir. İşte elektrik tokenlarının neden önemli olduğuna dair bazı temel nedenler:<br/><br/>
                •	Şeffaflık ve İzlenebilirlik: Blockchain teknolojisi, elektrik üretiminden tüketimine kadar tüm süreçlerin şeffaf ve izlenebilir olmasını sağlar, böylece elektrik piyasasında güveni artırır.<br/><br/>
                •	Verimlilik Artışı: Elektrik tokenları, ticaretin daha hızlı ve maliyet etkin gerçekleşmesini sağlar, aracılara olan ihtiyacı azaltarak verimliliği artırır.<br/><br/>
                •	Yenilenebilir Enerji Teşviki: Bu tokenlar, güneş ve rüzgâr gibi yenilenebilir enerji projelerinin daha kolay finanse edilmesini ve geniş kitlelere ulaşmasını sağlar.<br/><br/>
                •	Esneklik ve Ölçeklenebilirlik: Elektrik tokenları, küçük ölçekli üreticilerin pazara katılımını ve merkezi olmayan enerji üretimini destekler.<br/><br/>
                •	Düşük Karbon Ayak İzi: Tokenlaştırılmış elektrik piyasaları, karbon emisyonlarını azaltmak için daha etkili çözümler sunar.<br/><br/>
                •	Küresel Erişim: Farklı bölgelerde üretilen elektrik, tokenlar sayesinde dünya genelinde ticaret yapılabilir, bu da elektrik arzının daha dengeli ve sürdürülebilir olmasını sağlar.<br/><br/>

              <strong>Artan Elektrik Talebinin Ardındaki Nedenler</strong><br/><br/>
              Elektrik talebi, modern yaşamın gereksinimleri doğrultusunda sürekli artmaktadır. İşte bu talebin ardındaki başlıca nedenler:<br/><br/>
                •	Nüfus Artışı: Artan nüfus, konut ve hizmet talebiyle birlikte elektrik tüketimini artırır.<br/><br/>
                •	Kentleşme: Şehirlere göç, sanayi ve ticaret sektörlerinde daha fazla elektrik kullanımına yol açar.<br/><br/>
                •	Teknolojik Gelişmeler: Elektrikli araçlar ve akıllı sistemler gibi yeni teknolojiler enerji tüketimini artırır.<br/><br/>
                •	Sanayileşme: Gelişen sanayi faaliyetleri büyük miktarda elektrik gerektirir.<br/><br/>
                •	Yaşam Standartlarının Yükselmesi: Daha fazla elektrikli cihaz kullanımı, enerji talebini artırır.<br/><br/>
                •	Dijitalleşme: Bilgisayarlar ve veri merkezleri sürekli artan miktarda elektrik tüketir.<br/><br/>
                •	Yenilenebilir Enerji Kaynaklarının Entegrasyonu: Yenilenebilir enerji kaynakları, enerji depolama ve şebeke yönetimi gereksinimlerini artırır.<br/><br/>
                •	İklim Değişikliği: Artan sıcaklıklar ve ekstrem hava koşulları, soğutma sistemlerinin kullanımını ve enerji talebini artırır.<br/><br/>

              <strong>Elektrik Tokenlarına Talep Neden Artıyor?</strong><br/><br/>
              Elektrik tokenlarına olan talep, enerji sektöründe yenilikçi çözümler sunmaları nedeniyle hızla artmaktadır. Blockchain teknolojisi sayesinde, elektrik üretiminden tüketimine kadar tüm süreçlerin şeffaf ve izlenebilir hale gelmesi, güveni artırır ve hileli faaliyetlerin önüne geçer. Bu tokenlar, enerji ticaretinin daha hızlı ve maliyet etkin bir şekilde gerçekleşmesini sağlar, aracılara olan ihtiyacı azaltarak verimliliği artırır.<br/><br/>
              Ayrıca, elektrik tokenları yenilenebilir enerji projelerinin daha kolay finanse edilmesini ve daha geniş kitlelere ulaşmasını teşvik eder. Küçük ölçekli üreticilerin pazara katılımını sağlayarak enerji üretiminin merkezi olmayan bir yapıya kavuşmasını destekler. Tokenlaştırılmış elektrik piyasaları, karbon emisyonlarını azaltmak için daha etkili çözümler sunar ve enerji ticaretini küresel hale getirir. Bu nedenlerle, elektrik tokenlarına olan talep giderek artmaktadır.
              </Typography>    
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 500,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 28, lg: 40 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >    
              PovCacao nedir?<br/>
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 20px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              Tokenize edilmiş kakao, tarımsal varlıkların dijital token'lar aracılığıyla temsil edilmesi işlemidir. Bu yenilikçi yaklaşımla Povego, yatırımcılara kakao varlıklarına dijital ve güvenli bir şekilde yatırım yapma imkânı tanır. Kakao ürünleri, güvenli ve denetlenen depolarda saklanarak, dijital token'lar aracılığıyla yatırımcılara sunulur. Bu token'lar, kakao piyasasındaki değer dalgalanmalarından yararlanma fırsatı sağlar ve küresel ölçekte değerli bir tarımsal ürün olan kakao üzerinden kazanç elde etme imkânı sunar. Kısacası POVEGO ile Tokenize edilmiş kakao, tarım sektöründe yatırım yapmanın modern, güvenilir ve şeffaf bir yoludur.<br/><br/>

              <strong>Neden Kakao?</strong><br/><br/>
              Kakao, tarım emtiaları arasında en yüksek işlem hacmine sahip olan ürünlerden biridir ve her bir kakao token, belirli bir miktar kakaoyu temsil eder. Çikolata endüstrisinin son yıllarda hızlı bir yükseliş yaşaması ve küresel taleplerin artması, kakaoyu en çok tercih edilen emtialar arasına sokmuştur.<br/><br/>
              Dünyanın en sevilen ve tüketilen tatlılarından biri olan çikolatanın ana bileşeni olan kakao, sadece lezzetli bir malzeme olmanın ötesinde, finansal piyasalarda yer alan önemli bir emtiadır. Theobroma cacao ağacının tohumlarından elde edilen kakao, dünya genelinde büyük talepler gören değerli bir ürüne dönüşmüştür.<br/><br/>

              <strong>Kakao Talebinin Ardındaki Güç</strong><br/><br/>
              2021 yılında küresel çikolata pazarının büyüklüğü yaklaşık 113,16 milyar ABD doları olarak hesaplanmıştır. 2022-2030 döneminde ise %3,7’lik bir büyüme oranı ile kakao emtiasının talebi artmaya devam edecek ve tarım emtiaları arasında yerini daha da sağlamlaştıracaktır. Bu büyüme, artan küresel çikolata tüketimi ve özellikle Asya pazarındaki genişleyen talep ile desteklenmektedir. Gelişen pazarlarda çikolata ürünlerine olan ilginin artması, kakao talebinin sürekli yükselmesine katkı sağlamaktadır. Ayrıca, sağlıklı yaşam trendleri ve kaliteli kakao ürünlerine yönelik artan bilinç, kakao fiyatlarını ve talebini olumlu yönde etkilemektedir. Bu durum, kakao üreticileri ve yatırımcılar için uzun vadede kazançlı fırsatlar sunmaktadır. 2022 yılında tonu 2 bin dolar seviyelerinde bulunan kakao fiyatları, Nisan 2024’te 12 bin doların üzerini gördü.<br/><br/>  

              <strong>Kakao Fiyatları Neden Artıyor?</strong><br/><br/>
              Kakao fiyatlarının artışının arkasında iklim koşulları ve ekonomik faktörler bulunuyor. Dünya kakao üretiminin %60'ını gerçekleştiren Fildişi Sahili ve Gana, aşırı yağışlardan dolayı kakao bitkilerinin zarar görmesiyle meyve veremez hale geldi. Gana hükümetinin çiftçilere ödeme yapamayacağını açıklaması, üreticilerin ürünlerini satmak istememesiyle sonuçlandı. Bağımsız tüccarlar, çiftçilere daha yüksek fiyat teklif ederek stokları aldılar. Bu durumun Forex piyasalarına yansımasıyla kakao fiyatları yükseldi ve yatırımcı ilgisi arttı, bu da fiyatların daha da artmasına neden oldu.
              </Typography>    
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 500,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 28, lg: 40 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >    
              PovNut nedir?<br/>
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 20px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              PovNut, fındık sektörüne yenilikçi bir yaklaşım getiren bir blockchain tokenıdır. Bu token, fındık üretimi, ticareti ve tüketimini dijitalleştirerek daha şeffaf ve verimli hale getirmeyi amaçlar. Povego Fındık tokenları, fındık ticaretinde aracılara olan ihtiyacı azaltarak maliyetleri düşürür ve işlemleri hızlandırır.<br/><br/>
              Üstelik fiziksel fındık ile desteklenen ve kilogram fındık fiyatına endeksli bir token olarak fiziksel varlığa dayalıdır. Bir başka deyişle bu tokenlar, uluslararası denetim gören depolarda saklanan fiziksel fındık ile teminatlandırılır. Bu sayede, PovNut sahipleri, yatırımlarının gerçek fındık varlıkları ile güvence altına alındığından emin olabilirler.<br/><br/>

              <strong>Neden Fındık Token’ı?</strong><br/><br/>
              Günümüzde tarımsal emtia üretimi, tüketimi ve ticaretinin fonlanması, içinde bulunduğumuz global kriz dünyasında her zamankinden daha fazla önem kazanıyor. Fındık, bu tarımsal ürünler arasında ülkeler arası ticarette stratejik bir öneme sahip. Özellikle besleyici değerleri ve çok yönlü kullanımı ile dikkat çeken fındık, sağlıklı yaşam trendleri ile birlikte tüketiciler arasında popülerliğini artırıyor.<br/><br/>
              Ekonomik açıdan bakıldığında, fındık üretimi ve ticareti, üretici ülkeler için önemli bir gelir kaynağı oluşturuyor. Türkiye, İtalya ve ABD gibi fındık üretiminde önde gelen ülkeler, bu stratejik ürünün ticaretinde büyük rol oynuyor. Fındığın dünya genelinde artan talebi, bu ülkelerin ekonomik büyümelerine de katkı sağlıyor.<br/><br/>
              Fındık ticaretinin dijitalleşmesi ve finansal piyasalarla entegrasyonu, sektördeki verimliliği artırıyor. Blockchain tabanlı çözümler, fındık ticaretinin daha şeffaf ve izlenebilir olmasını sağlarken, maliyetleri düşürerek işlemleri hızlandırıyor. Bu yenilikçi yaklaşımlar, fındık üreticileri ve yatırımcıları için yeni fırsatlar sunuyor.<br/><br/>
              Bu yüzden Povego fındık tokenları arz ve talep dengesini daha iyi yönetmeye yardımcı olur. Üreticiler, piyasada oluşan fiyat dalgalanmalarına karşı kendilerini koruyabilir ve ürünlerini en uygun zamanda satabilirler. Bu hem üreticiler hem de tüketiciler için daha stabil bir piyasa ortamı yaratır.<br/><br/>

              <strong>Artan Fındık Talebinin Ardındaki Nedenler</strong><br/><br/>
              Fındık, dünya genelinde artan bir talep görüyor. Bu talep artışının ardında çeşitli ekonomik, sağlık ve endüstriyel faktörler bulunuyor. İşte artan fındık talebinin nedenleri:<br/><br/>
                •	Sağlıklı Beslenme Trendleri Sağlıklı beslenme alışkanlıklarının yaygınlaşması, fındık gibi besleyici gıdalara talebi artırıyor. Yüksek protein, vitamin, mineral ve sağlıklı yağ içeriği ile fındık, sağlıklı bir atıştırmalık ve yemek bileşeni olarak tercih ediliyor.<br/><br/>
                •	Gıda Endüstrisindeki Kullanım Artışı Fındık, çikolata, dondurma ve diğer tatlı ürünlerde yaygın olarak kullanılıyor. Gıda endüstrisindeki inovasyonlar ve yeni ürün geliştirmeler, fındık talebini artırıyor.<br/><br/>
                •	Küresel Pazar Genişlemesi Fındık üreticisi ülkelerin ihracat kapasitelerinin artması ve yeni pazarlara girmeleri, küresel talebi artırıyor. Özellikle Asya ve Orta Doğu gibi gelişen pazarlarda fındık tüketimi hızla artıyor.<br/><br/>
                •	Sanayileşme ve Üretim Kapasitesi Sanayileşme ve teknolojik ilerlemeler, fındık üretim kapasitesini artırıyor. Modern tarım teknikleri, daha verimli ve kaliteli üretim sağlıyor, bu da talebi destekliyor.<br/><br/>
                •	Finansal Araçlar ve Tokenizasyon Fındık tokenları, arz ve talep dengesini yönetmeye yardımcı oluyor. Üreticiler, fiyat dalgalanmalarına karşı korunarak ürünlerini en uygun zamanda satabiliyor. Tokenizasyon, ticareti şeffaf, verimli ve erişilebilir hale getirerek yatırımcı ilgisini artırıyor.<br/><br/>
                •	İklim Değişikliği ve Üretim Koşulları İklim değişikliği, bazı bölgelerde üretimi zorlaştırırken, uygun iklim koşullarına sahip bölgelerde üretimi teşvik ediyor. Üretim koşullarının iyileştirilmesi, fındık arzını güvence altına alarak talep artışını destekliyor.<br/>
                </Typography>    
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 500,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 28, lg: 40 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >    
              Yakında Eklenecek Ürünler<br/>
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
                •	Fıstık<br/><br/>
                •	Kahve<br/><br/>
                •	Petrol<br/><br/>
                •	Gümüş<br/><br/>
                •	Bakır<br/><br/>
                •	Altın<br/><br/>
                •	Arpa<br/><br/>
                •	Buğday<br/><br/>
                •	Şeker<br/><br/>          
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default Products;
  