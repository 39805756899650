
export function formatDateString(dateString) {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Extract the individual components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the components into the desired format
    return `${day}.${month}.${year} - ${hours}:${minutes}:${seconds}`;
}
