import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import cross from "../assets/images/cross.png";
import arrow from "../assets/images/arrow-down.png";
import depositarrow from "../assets/images/depositarrow.png";

import NewAddress from "./SettingsComponent/NewAddress";
import AddressBook from "./SettingsComponent/AddressBook";
import EditAddress from "./SettingsComponent/EditAddress";
import KYCStatus from "./SettingsComponent/KYCStatus";

import { usePovegoContext } from "../context";
import { useSelector } from "../store";

function Settings() {
  const history = useNavigate();
  const { settingOptions, setSettingOptions } = usePovegoContext();
  const { isKycCompleted } = useSelector((state) => state.auth);

  const handleAddressBookClick = () => {
    if (!isKycCompleted) {
      history("/kyc", { state: { showAlert: true } });
    } else {
      setSettingOptions("address");
    }
  };

  const handleKycStatusClick = () => {
    history("/kyc", { state: { showAlert: true } });
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          padding: "16px 0",
        }}
      >
        {settingOptions !== "settings" && (
          <img
            src={depositarrow}
            alt="back"
            style={{
              position: "absolute",
              marginRight: "400px",
              cursor: "pointer",
            }}
            onClick={() => setSettingOptions("settings")}
          />
        )}
        <Typography
          sx={{
            fontSize: 20,
            color: "#fff",
            fontWeight: 500,
            fontFamily: '"Inter", sans-serif',
            // textAlign: "center",
            // flexGrow: 1,
          }}
        >
          {settingOptions === "settings" && "Ayarlar"}
          {settingOptions === "address" && "Adres defteri"}
          {settingOptions === "newaddress" && "Yeni adres ekle"}
          {settingOptions === "edit" && "Adresi düzenle"}
          {settingOptions === "kycstatus" && "KYC Doğrulama"}
        </Typography>
        <img
          src={cross}
          alt="close"
          style={{
            position: "absolute",
            marginLeft: "350px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          onClick={() => history("/anasayfa")}  // Redirects to the homepage
        />
      </Box>

      <Box sx={{ paddingBottom: "12px", maxWidth: "400px", margin: "24px auto 0" }}>
        {settingOptions === "settings" && (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                cursor: "pointer",
                marginBottom: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Hesap bilgisi
              </Typography>
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                cursor: "pointer",
                marginBottom: "5px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Güvenlik Ayarları
              </Typography>
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                cursor: "pointer",
                marginBottom: "5px",
              }}
              onClick={handleAddressBookClick}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Adres Defteri
              </Typography>
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                cursor: "pointer",
              }}
              onClick={handleKycStatusClick}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 20,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                KYC Doğrulama
              </Typography>
              <img
                src={arrow}
                alt="arrow"
                style={{ transform: "rotate(-90deg)" }}
              />
            </Box>
          </Box>
        )}

        {settingOptions === "address" && <AddressBook />}
        {settingOptions === "newaddress" && <NewAddress />}
        {settingOptions === "edit" && <EditAddress />}
        {settingOptions === "kycstatus" && <KYCStatus />}
      </Box>
    </React.Fragment>
  );
}

export default Settings;
