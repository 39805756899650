import React, { useState } from "react";
import { Box, Typography } from "@mui/material";

import arrow from "../../assets/images/arrow-down.png";
import copy from "../../assets/images/copy.png";

import toast from "react-hot-toast";
import copyTextToClipboard from 'copy-text-to-clipboard'

import { useSelector } from "../../store";
import { TurkeyBanks } from "../../config";

function Bank() {

  const [expandedSection, setExpandedSection] = useState(null);
  const { referenceId } = useSelector((state) => state.auth)
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleCopy = (text) => {
    copyTextToClipboard(text)
    toast.success("Adres kopyalandı")
  };

  return (
    <React.Fragment>

      {
        TurkeyBanks.map((item, key) => {
          return (
            <Box
              key={key}
              sx={{
                padding: "21px 20px",
                borderRadius: "16px",
                backgroundColor: "#1b1b1b",
                marginBottom: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => toggleSection(item.symbol)}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={item.logo} alt="ziraat logo" style={{ marginRight: "10px" }}></img>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                      fontSize: 20,
                      display: "flex",
                      alignItems: "center",
                      fontFamily: '"Inter", sans-serif',
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{
                    transform: expandedSection === item.symbol ? "rotate(0deg)" : "rotate(-90deg)",
                  }}
                ></img>
              </Box>
              {expandedSection === item.symbol && (
                <Box sx={{ marginTop: "25px" }}>
                  <Typography
                    sx={{
                      color: "#7D7D7D",
                      fontSize: "14px",
                      fontFamily: '"Roboto", sans-serif;',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    Alıcı adı
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: '"Inter", sans-serif;',
                      maxWidth: "350px",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.receipt}
                    <img
                      src={copy}
                      alt="copy"
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopy(item.receipt)}
                    ></img>
                  </Typography>

                  <Typography
                    sx={{
                      color: "#7D7D7D",
                      fontSize: "14px",
                      fontFamily: '"Roboto", sans-serif;',
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: "12px"

                    }}
                  >
                    Referans no
                  </Typography>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: '"Inter", sans-serif;',
                      maxWidth: "350px",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {referenceId}
                    <img
                      src={copy}
                      alt="copy"
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopy(referenceId)}
                    ></img>
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                    <Typography
                      sx={{
                        color: "#7D7D7D",
                        fontSize: "14px",
                        fontFamily: '"Roboto", sans-serif;',
                      }}
                    >
                      IBAN
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "17px",
                      fontWeight: 500,
                      fontFamily: '"Inter", sans-serif;',
                      maxWidth: "350px",
                      marginTop: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.iban}
                    <img
                      src={copy}
                      alt="copy"
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleCopy(item.iban)}
                    ></img>
                  </Typography>
                </Box>
              )}
            </Box>

          )
        })
      }
    </React.Fragment>
  );
}

export default Bank;