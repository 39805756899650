import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import React from "react";
import plus from "../../assets/images/website/plus.png";

function FaqSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box sx={{ marginTop: {xs: '58px' , lg: "84px"} }}>
          <Typography
            sx={{
              color: "#212529",
              fontSize: 36,
              fontWeight: 700,
              fontFamily: '"Inter", sans-serif',
              lineHeight: "44px",
              textAlign: "center",
            }}
          >
            Sıkça Sorulan Sorular
          </Typography>
          <Typography
            sx={{
              color: "#212529",
              fontSize: {xs: 18 , lg :22},
              fontWeight: 400,
              fontFamily: '"Inter", sans-serif',
              lineHeight: "30px",
              marginTop: "24px",
              textAlign: "center",
            }}
          >
            Bize sıkça sorulan soruların yanıtlarını burada bulabilirsiniz. 
          </Typography>
        </Box>
        <Box sx={{ margin: {xs: '39px 0px 89px' , lg: "50px 0px 165px"} }}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            sx={{
              border: expanded === "panel1" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel1" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Nasıl çalışır?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                Tokenizasyon ve blockchain hakkında temel bilgiler. 
                Povego, gerçek dünya varlıklarını dijital tokenlara dönüştürerek, bu varlıkları blokzincir üzerinde güvenli ve şeffaf bir şekilde saklar. 
                Tokenizasyon, fiziksel varlıkların dijital temsillerini oluşturarak, daha geniş kitlelere yatırım yapma imkânı sunar. 
                Blockchain teknolojisi sayesinde, her işlem kaydedilir ve geri dönülemez şekilde doğrulanır.


              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            sx={{
              border: expanded === "panel2" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel2" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Neden Povego'yu tercih etmeliyiz?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                Povego, güvenilir ve yenilikçi yatırım fırsatları sunarak rakiplerinden ayrılır. Çünkü Povego tokenları, fiziki karşılığa ve gerçek varlığa dayalıdır. Dolayısıyla tokenların değerinde gerçek varlık fiyatlarından bağımsız olarak ani fiyat hareketleri yaşanmaz.  
                Povego, yalnızca kendi çıkardığı tokenlarla sınırlı kalmayıp, kullanıcıların tokenize etmek istedikleri varlıkların da işlem görebileceği bir platform sunar. Kullanıcılar, belirli varlıklarını tokenize etmek için başvurabilir ve bu varlıklar platformda alım-satıma açılır.
                Bu hizmet, yatırımcılara daha geniş ve çeşitli yatırım imkânlar sunar. 

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            sx={{
              border: expanded === "panel3" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel3" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                RWA nedir?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                Gerçek dünya varlıkları (RWA'lar), dijitalleştirilmiş ve bir blokzincirinde token olarak temsil edilmiş gayrimenkul, emtia veya güzel sanatlar gibi fiziksel varlıkları ifade eder.
                Tokenlaştırma olarak bilinen bu süreç, bu varlıkların kesirli mülkiyetine izin verir, yani yatırımcılar daha büyük bir varlığın daha küçük kısımlarını alıp satabilirler. 
                RWA'lar, yatırım portföylerini çeşitlendirmek ve geleneksel varlık sınıflarına daha likit ve erişilebilir bir şekilde maruz kalmak için benzersiz bir fırsat sunar.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            sx={{
              border: expanded === "panel4" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel4" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel4bh-content"
              id="panel4bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Charget nedir?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                Charget, elektrik üretimi ve tüketimini tokenize eden POVEGO'nun yenilikçi bir ürünüdür. 
                Enerji sektörünün elektrik tokenizasyonu olan Charget, kullanıcıların enerji sektörüne yatırım yapmalarını kolaylaştırır ve enerji ticaretini daha erişilebilir hale getirir. 
                Charget ile hem sürdürülebilir enerji projelerine destek olabilir, hem de geleceğin enerji piyasasında yerinizi alabilirsiniz.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            sx={{
              border: expanded === "panel5" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel5" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel5bh-content"
              id="panel5bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
              PovCacao Token Nedir?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                
              Tokenize edilmiş kakao, tarımsal varlıkların dijital token'lar aracılığıyla temsil edilmesi işlemidir. 
              Bu yenilikçi yaklaşımla Povego, yatırımcılara kakao varlıklarına dijital ve güvenli bir şekilde yatırım yapma imkânı tanır. 
              Kakao ürünleri, güvenli ve denetlenen depolarda saklanarak, dijital token'lar aracılığıyla yatırımcılara sunulur. 
              Bu token'lar, kakao piyasasındaki değer dalgalanmalarından yararlanma fırsatı sağlar ve küresel ölçekte değerli bir tarımsal ürün olan kakao üzerinden kazanç elde etme imkânı sunar. 
              Kısacası Povego ile Tokenize edilmiş kakao, tarım sektöründe yatırım yapmanın modern, güvenilir ve şeffaf bir yoludur.

              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
            sx={{
              border: expanded === "panel6" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel6" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel6bh-content"
              id="panel6bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                PovNut Token Nedir?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
               Tokenize edilmiş fındık, tarımsal varlıkların dijital token'lar aracılığıyla temsil edilmesi işlemidir. 
               Bu yenilikçi yaklaşımla Povego, yatırımcılara fındık varlıklarına dijital ve güvenli bir şekilde yatırım yapma imkânı tanır. 
               Fındık, güvenli ve denetlenen depolarda saklanarak, dijital token'lar aracılığıyla yatırımcılara sunulur. 
               Bu token'lar, fındık piyasasındaki değer dalgalanmalarından yararlanma fırsatı sağlar ve küresel ölçekte değerli bir tarımsal ürün olan fındık üzerinden kazanç elde etme imkânı sunar. 
               Kısacası Povego ile Tokenize edilmiş fındık, tarım sektöründe yatırım yapmanın modern, güvenilir ve şeffaf bir yoludur.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
            sx={{
              border: expanded === "panel7" && "1px solid #E63B1F",
              borderRadius: "6px",
              marginBottom: "12px",
            }}
          >
            <AccordionSummary
              expandIcon={
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    transform:
                      expanded === "panel7" ? "rotate(45deg)" : "rotate(0deg)",
                  }}
                ></img>
              }
              aria-controls="panel7bh-content"
              id="panel7bh-header"
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                Nasıl işlem yaparım?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  color: "#0004F575E",
                  fontSize: 18,
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  lineHeight: "22px",
                }}
              >
                 Povego’ya üye olduktan sonra banka hesabınızdan Türk Lirası transfer ederek kolayca alım-satım yapabilir, toplam varlıklarınızı ve hesap hareketlerinizi takip edebilirsiniz. 
                Povego’ya sadece kendi banka hesabınızdan para transfer edebilirsiniz. 3. kişilerin hesabından veya vergi kimlik numarası ile açılmış hesaplardan yapılan transferler kabul edilmemektedir. 
                EFT işlemi gerçekleştirecekseniz alıcı adını “Povego A.Ş.” olarak girmelisiniz. Havale yapacaksanız, IBAN numarasını girdikten sonra alıcı adı otomatik olarak ekrana gelecektir.
                Göndermek istediğiniz tutarı girdikten sonra işlem açıklamasını  boş bırakarak işleminizi tamamlayabilirsiniz. Bankanız işlem açıklamasını boş bırakmanıza izin vermiyorsa, ad soyad bilgilerinizi girebilirsiniz.
                Türk Lirası yatırma işleminizi, panelde Hesap Hareketleri bölümünden kolayca takip edebilirsiniz. Yatırdığınız tutar Povego hesabına ulaştıktan sonra kısa süre içerisinde hesabınıza aktarılacaktır. 

              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default FaqSection;
