import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import router
import Router from './Router';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Toaster
        position="top-right"
        reverseOrder={false}
      />

      <Router />
    </GoogleOAuthProvider>
  );
}

export default App;