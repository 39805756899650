import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { Box, Button, Typography, TextField } from "@mui/material";

import usd from "../../assets/images/usd.png";
import avax from "../../assets/images/avax.png";
import arrow from "../../assets/images/arrow-down.png";
import try_logo from "../../assets/images/try_logo.png";
import chgr_logo from "../../assets/images/chgr_logo.png";

import { useSelector } from "../../store";
import { removedBankBookById, removedCryptoBookById, updateBankBookById, updateCryptoBookById, } from "../../apis";
import { DefaultAssets } from "../../config";
import { usePovegoContext } from "../../context";

function EditAddress() {
  const { userId } = useSelector((state) => state.auth);
  const { setSettingOptions, addressBook } = usePovegoContext()

  const [showNetwork, setShowNetwork] = useState(false);
  const [showCurrency, setShowCurrency] = useState(false);

  const [label, setLabel] = useState("");
  const [address, setAddress] = useState("");
  const [asset, setAsset] = useState(1);
  const [showBanks, setShowBanks] = useState(false);
  const [network, setNetwork] = useState("AVAX C-Chain");

  const handleUpdate = async () => {
    if (!label.trim() || !address.trim()) {
      toast.error("İsim ve adres alanları boş olamaz.");
      return;
    }

    try {
      const params = {
        user_id: userId,
        label,
        address,
        network: showBanks ? "Bank" : network,
        asset_id: asset,
      };
      const data = showBanks ? await updateBankBookById(addressBook.id, params) : await updateCryptoBookById(addressBook.id, params)
      if (data) {
        setSettingOptions('address');
        toast.success("Adres defteri güncellendi.");
      } else {
        toast.error("Adres eklenemedi");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Adres eklenemedi");
    }
  };

  const handleRemove = async () => {
    try {
      const data = addressBook.asset_id === DefaultAssets[2].asset_id ? await removedBankBookById(addressBook.id) : await removedCryptoBookById(addressBook.id)
      if (data) {
        setSettingOptions('address');
        toast.success("Adres başarı ile kaldırıldı.");
      } else {
        toast.error("Adres kaldırılamadı");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Adres kaldırılamadı");
    }
  };

  useEffect(() => {
    if (addressBook) {
      setLabel(addressBook.label);
      setAddress(addressBook.address);
      setAsset(addressBook.asset_id);
      setNetwork(addressBook.asset_id === 3 ? "Bank" : addressBook.network);
      setShowBanks(addressBook.asset_id === DefaultAssets[2].asset_id);

    }
  }, [addressBook])

  const handleAssetSelect = (selectedAsset) => {
    setAsset(selectedAsset);
    setShowCurrency(false);
    if (selectedAsset === DefaultAssets[2].asset_id) {
      setNetwork(1);
      setShowBanks(true);
      setAddress("");
    } else {
      setNetwork("AVAX C-Chain");
      setShowBanks(false);
    }
  };

  // const handleBankSelect = (bankName) => {
  //   setNetwork(bankName);
  //   setShowNetwork(false);
  // };

  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: "#1b1b1b",
          position: "relative",
          borderRadius: "16px",
          marginBottom: "10px",
        }}
      >
        {/* Asset section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: { xl: "120px", sm: "110px", xs: "120px" },
            padding: "0px 16px",
            cursor: "pointer",
          }}
          onClick={() => setShowCurrency(!showCurrency)}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 14,
                color: "#7d7d7d",
                fontFamily: '"Roboto", sans-serif;',
              }}
            >
              Varlık
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
              <img
                src={asset === DefaultAssets[0].asset_id ? usd : asset === DefaultAssets[2].asset_id ? try_logo : chgr_logo}
                alt={asset}
                style={{ paddingRight: "10px" }}
              ></img>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: 20,
                  fontWeight: 600,
                  marginRight: "14px",
                  fontFamily: '"Inter", sans-serif',
                }}
              >
                {DefaultAssets.filter((item) => item.asset_id === asset)[0].name}
              </Typography>
            </Box>
          </Box>
          <Box>
            <img
              src={arrow}
              alt="arrow"
              style={{
                transform: showCurrency ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            ></img>
          </Box>
        </Box>

        {/* Crypto section */}
        {showCurrency && (
          <Box
            sx={{
              paddingBottom: "12px",
              maxHeight: "200px",
              overflowY: "auto",
              scrollbarColor: "#1b1b1b #131313",
              scrollbarWidth: "thin",
            }}
            className="content visible"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px 16px",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#131313",
                cursor: "pointer",
              }}
              onClick={() => handleAssetSelect(DefaultAssets[0].asset_id)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={usd} alt="usd" style={{ marginRight: "10px" }}></img>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 20,
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  USDT
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px 16px",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#131313",
                cursor: "pointer",
                marginTop: "5px",
              }}
              onClick={() => handleAssetSelect(DefaultAssets[2].asset_id)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={try_logo} alt="try_logo" style={{ marginRight: "10px" }}></img>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 20,
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  TRY
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px 16px",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#131313",
                cursor: "pointer",
                marginTop: "5px",
              }}
              onClick={() => handleAssetSelect(DefaultAssets[1].asset_id)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={chgr_logo} alt="chgr_logo" style={{ marginRight: "10px" }}></img>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 20,
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  CHGR
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "#1b1b1b",
          position: "relative",
          borderRadius: "16px",
          marginBottom: "10px",
        }}
      >
        {/* Label and Address/IBAN section */}
        <Box sx={{ padding: "16px" }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Label (Optional)"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": { color: "#7D7D7D" },
              "& .MuiInputBase-root": { color: "#fff" },
              "& .MuiOutlinedInput-notchedOutline": { borderColor: "#7D7D7D" },
              marginBottom: "16px",
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label={showBanks ? "IBAN" : "Address"}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            sx={{
              "& .MuiInputLabel-root": { color: "#7D7D7D" },
              "& .MuiInputBase-root": { color: "#fff" },
              "& .MuiOutlinedInput-notchedOutline": { borderColor: "#7D7D7D" },
              marginBottom: "16px",
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#1b1b1b",
          position: "relative",
          borderRadius: "16px",
          marginBottom: "10px",
        }}
      >
        {/* Network section */}
        {
          asset !== DefaultAssets[2].asset_id && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                minHeight: { xl: "120px", sm: "110px", xs: "120px" },
                padding: "0px 16px",
                cursor: "pointer",
              }}
              onClick={() => setShowNetwork(!showNetwork)}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7d7d7d",
                    fontFamily: '"Roboto", sans-serif;',
                  }}
                >
                  Ağ
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                  <img
                    src={
                      avax
                    }
                    alt={network}
                    style={{ paddingRight: "10px" }}
                  ></img>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: 600,
                      marginRight: "14px",
                      fontFamily: '"Inter", sans-serif',
                    }}
                  >
                    {network}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <img
                  src={arrow}
                  alt="arrow"
                  style={{
                    transform: showNetwork ? "rotate(0deg)" : "rotate(-90deg)",
                  }}
                ></img>
              </Box>
            </Box>

          )
        }

        {/* {showNetwork && asset === DefaultAssets[2].asset_id && (
          <Box
            sx={{
              paddingBottom: "12px",
              maxHeight: "200px",
              overflowY: "auto",
              scrollbarColor: "#1b1b1b #131313",
              scrollbarWidth: "thin",
            }}
            className="content visible"
          >
            {
              TurkeyBanks.map((item, key) => {
                return (
                  <Box
                    key={key}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0px 16px",
                      padding: "24px 20px",
                      marginBottom: "12px",
                      borderRadius: "16px",
                      backgroundColor: "#131313",
                      cursor: "pointer",
                    }}
                    onClick={() => handleBankSelect(item.id)}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={item.logo} alt={item.name} style={{ marginRight: "10px" }}></img>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
        )} */}

        {showNetwork && asset !== DefaultAssets[2].asset_id && (
          <Box sx={{ paddingBottom: "12px" }} className="content visible">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "0px 16px",
                padding: "24px 20px",
                borderRadius: "16px",
                backgroundColor: "#131313",
                cursor: "pointer",
              }}
              onClick={() => {
                setNetwork("AVAX C-Chain");
                setShowNetwork(false);
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={avax} alt="avax" style={{ marginRight: "10px" }}></img>
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 20,
                    fontFamily: '"Inter", sans-serif',
                  }}
                >
                  AVAX C-Chain
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {/* 
      {error && (
        <Typography sx={{ color: 'red', marginBottom: '10px' }}>
          {error}
        </Typography>
      )} */}

      <Button
        sx={{
          fontWeight: 600,
          fontSize: 18,
          color: "#fff",
          backgroundColor: "#E63B1F",
          borderRadius: "16px",
          padding: "11px 0px",
          width: "100%",
          marginTop: "10px",
          textTransform: "none",
          fontFamily: '"Inter", sans-serif',
          "&::first-letter": {
            textTransform: "capitalize",
          },
          "&:hover": {
            background: "#E63B1F",
          },
        }}
        onClick={handleUpdate}
      >
        Güncelle
      </Button>
      <Button
        sx={{
          fontWeight: 600,
          fontSize: 18,
          color: "#fff",
          backgroundColor: "#E63B1F",
          borderRadius: "16px",
          padding: "11px 0px",
          width: "100%",
          marginTop: "10px",
          textTransform: "none",
          fontFamily: '"Inter", sans-serif',
          "&::first-letter": {
            textTransform: "capitalize",
          },
          "&:hover": {
            background: "#E63B1F",
          },
        }}
        onClick={handleRemove}
      >
        Kaldır
      </Button>
    </React.Fragment>
  );
}

export default EditAddress;
