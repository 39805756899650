import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/images/arrow-down.png";

const NotificationComponent = ({ notify, notification }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleArrowClick = () => {
        navigate("/notification"); 
    };

    return (
        <Box sx={{
            borderRadius: "16px",
            backgroundColor: "#1b1b1b",
        }}>
            <Box
                onClick={() => setOpen(!open)}
                sx={{
                    marginTop: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "24px 20px",
                    cursor: "pointer",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography sx={{ color: '#fff', backgroundColor: '#e63b1f', padding: '4px 11px', borderRadius: '50%', marginRight: "10px" }}>
                        {notification.length}
                    </Typography>
                    <Typography
                        sx={{
                            color: "#fff",
                            fontSize: 20,
                            display: "flex",
                            alignItems: "center",
                            fontFamily: '"Inter", sans-serif',
                        }}
                    >
                        {notify.name}
                    </Typography>
                </Box>
                <img
                    src={arrow}
                    alt="arrow"
                    style={{
                        transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    onClick={handleArrowClick}
                />
            </Box>
            <Box
                className={`notify ${open ? "visible" : "hidden"}`}
            >
                {
                    notification.length ?
                        <Stack spacing={1} sx={{ overflow: "auto", position: "relative", height: "100px" }} >
                            {
                                notification.map((item, key) => {
                                    return (
                                        <Stack spacing={0.5} px={4} key={key}>
                                            <Typography sx={{ color: "white" }}>{key + 1}. {item.text}</Typography>
                                        </Stack>
                                    )
                                })
                            }
                        </Stack>
                        :
                        <Typography sx={{ color: "white", textAlign: "center" }}>Bildirim yok!</Typography>
                }
            </Box>
        </Box>
    )
}

export default NotificationComponent;
