import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import Link from react-router-dom

import { Button, Container, Typography } from "@mui/material";

import { CustomTextField } from "../components";

import google from "../assets/images/google.png";

import toast from "react-hot-toast";

import { googleLogin, register } from "../apis";
import { dispatch } from "../store";
import { Login } from "../store/reducers/auth";
import { usePovegoContext } from "../context";
import { useGoogleLogin } from "@react-oauth/google";


function SignupComponent() {
  const navigate = useNavigate();
  const { loading, setLoading } = usePovegoContext()
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const registerWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setLoading(true)

        const data = await googleLogin({ token: tokenResponse.access_token });

        if (data) {
          toast.success('Kayıt başarıyla tamamlandı!')
          dispatch(Login(data))
          navigate('/anasayfa');
        }

        setLoading(false)

      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    },
    onError: (error) => {
      console.log('Google giriş hatası:', error);
      toast.error('Kayıt başarısız oldu.')
    }
  });


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true)
    try {
      const data = await register(
        {
          name: name,
          mail: email,
          password: password
        }
      )
      if (data) {
        setLoading(false)
        toast.success('Kayıt başarıyla tamamlandı!')
        dispatch(Login(data))
        navigate('/anasayfa');
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error)
      toast.error('Kayıt başarısız oldu.')
    }
  };

  return (
    <Container maxWidth="xl">
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
          maxWidth: "400px",
          margin: "auto",
        }}
        onSubmit={handleSubmit}
      >
     
        <CustomTextField
          label="Ad & Soyad"
          variant="filled"
          type="text"
          autoComplete="off"
          required
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <CustomTextField
          label="Email"
          variant="filled"
          type="email"
          required
          autoComplete="off"
          sx={{ margin: "5px 0px 0px" }}
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CustomTextField
          label="Şifre"
          className="swap-input"
          variant="filled"
          type="password"
          sx={{ margin: "5px 0px 10px 0px" }}
          autoComplete="off"
          fullWidth
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          sx={{
            fontWeight: 600,
            fontSize: 18,
            color: "#fff",
            backgroundColor: "#E63B1F",
            borderRadius: "16px",
            padding: "13px 0px",
            width: "100%",
            textTransform: 'none',
            fontFamily: '"Inter", sans-serif',
            "&::first-letter": {
              textTransform: "capitalize",
            },
            '&:hover': {
              background: "#E63B1F",
            }
          }}
          disabled={loading}
          type="submit"
        >
          {
            loading ? "Kayıt Ol..." : "Kayıt Ol"
          }

        </Button>

        <Typography
          sx={{
            textAlign: "center",
            color: "#e4e4e7",
            fontSize: 14,
            padding: "10px 0px",
            lineHeight: "22px",
          }}
        >
          VEYA
        </Typography>
        <Button
          sx={{
            fontWeight: 600,
            fontSize: 16,
            color: "#000",
            backgroundColor: "#fff",
            borderRadius: "16px",
            padding: "15px 0px",
            width: "100%",
            textTransform: "none",
            "&:hover": {
              background: "#E63B1F",
            },
          }}
          onClick={registerWithGoogle}
        >
          <img src={google} style={{ marginRight: "15px" }} alt="google" />
          Google ile Kayıt Ol
        </Button>

        <Typography
          sx={{
            textAlign: "center",
            color: "#fff",
            fontSize: 14,
            padding: "18px 0px 0px",
            lineHeight: "22px",
          }}
        >
          Zaten bir hesabınız mı var?{" "}
          <Link
            to='/login'
            style={{
              color: "#E63B1F",
              fontWeight: 600,
              textDecoration: "none",
            }}
          >
            Giriş Yap
          </Link>
        </Typography>
      </form>
    </Container>
  );
}

export default SignupComponent;
