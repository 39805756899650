import axios from "axios";
import { API_BASE_URL } from "../config";
import toast from "react-hot-toast";

import { dispatch, store } from "../store";
import { Logout } from "../store/reducers/auth";

const axiosServices = axios.create();

axiosServices.interceptors.request.use(
    (config) => {
        config.baseURL = API_BASE_URL;
        const state = store.getState();
        const accessToken = state.auth.token;
        if (accessToken) {
            config.headers.authorization = `Bearer ${accessToken}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        const { response } = error;
        if (response && response.status === 400) {
            // toast.error(response.data.message)
        } else if (response && response.status === 401) {
            dispatch(Logout())
            // toast.error(response.data.message)
        } else if (response && response.status === 404) {
            // toast.error(response.data.message)
        } else if (response && response.status === 413) {
            // toast.error(response.data.message)
        } else if (response && response.status === 429) {
            // toast.error(response.data.message)
        } else {
        }
        return Promise.reject(error);
    }
);

export default axiosServices;