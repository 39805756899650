import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function AboutUs() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
            
              

              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 700,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 36, lg: 54 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
               Hakkımızda
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              
              Povego token alım satımı yapabildiğiniz bir pazaryeridir.<br/><br/>
              Türkiye’nin gerçek varlığa dayalı blockchain tabanlı yatırım platformudur.<br/><br/>
              2023 yılında teknoloji ve finans dünyasından uzman bir ekip tarafından kuruldu.<br/><br/>
              Türkiye'nin önde gelen finans markalarına 6 yıldır  finansal iletişim hizmeti veren, Türkiye’nin ilk Finansal İletişim Ajansı FİA'nın güçlü tecrübesi, Povego’nun mihenk taşı oldu. Finans dünyasına adım attığımızdan bu yana, sermaye piyasalarının baş aktörlerine sunduğumuz, iletişim ekosistemine ilham olan finansal iletişim danışmanlığı tecrübemizi, şimdi de Türkiye'de blockchain tabanlı yatırımları yaygınlaştırmak için başlattığımız Povego ile pekiştirdik. İlk tokenizasyon projemiz olan elektrik token’ı Charget, enerji sektörüyle ilgili olarak 2024 yılında başarılı bir şekilde hayata geçti. Povego olarak, geleceğin yatırım dünyasındaki öncü rolümüzü, istikrarlı bir şekilde sürdürmeyi hedefliyoruz.<br/><br/>

              Yatırım dünyasında yeni bir çağ başlatıyoruz. Yenilikçi platformumuzla, tokenize edilmiş varlıklar aracılığıyla yatırımcılarımıza daha güvenli, şeffaf ve erişilebilir bir yatırım ortamı sunuyoruz. Token ekosistemimize katılarak hem bireysel hem de kurumsal yatırımcılara yeni fırsatlar sunmayı amaçlıyoruz. <br/><br/>

              Yatırım dünyasına alternatif bir kanal olarak çıkan Povego, gerçek ticarete dayalı karşılığı ürün, hizmet ve ticari olan yatırıma değer varlıkları tokenize ederek alım satım yapabileceğiniz güvenli bir pazaryeridir. <br/><br/>

              <strong>Povego'nun Misyonu ve Vizyonu</strong><br/><br/>

              <strong>Misyonumuz</strong><br/><br/>
              
              Fiziksel varlıkları blokzincir teknolojisiyle birleştirerek, yatırımcılara, güvenli ve yenilikçi bir yatırım ekosistemi oluşturma misyonuyla hareket ediyoruz.<br/><br/>
              
              <strong>Vizyonumuz</strong><br/><br/>
              
              Türkiye’nin ilk gerçek varlığa dayalı blokzincir tabanlı yatırım platformu olarak geleceğin yatırım dünyasındaki öncü rolümüzü global arenaya taşımayı hedefliyoruz.<br/><br/>

              <strong>FİA İletişim Ajansı ve Danışmanlık Anonim Şirketi</strong><br/><br/>

              Finansal hizmetler sektöründe uzmanlaşmış, Türkiye’nin ilk finansal iletişim ajansı olan FİA, 2019 yılından bu yana faaliyetlerini sürdürmektedir. Doğrudan veya iştirakleri yoluyla, ülkemizin en değerli finans kurumlarına finansal iletişim danışmanlığı hizmeti sunan FİA İletişim Ajansı, sahip olduğu tecrübeyi kullanarak pek çok yeni projeye hayat vermektedir. Ajans ve çeşitli alanlarda faaliyet gösteren grup firmalarıyla birlikte, yüzlerce çalışanın yer aldığı bir habitatta faaliyetlerini yürüten FİA, aktif olarak Ziraat Finans Grubu’ndan Ziraat Bankası/Ziraat Katılım/Ziraat Yatırım/Ziraat Teknoloji/Ziraat Portföy ve Ziraat GSYO’ya, Borsa İstanbul Grubu’ndan Borsa İstanbul/Takas İstanbul/ Merkezi Kayıt İstanbul firmalarına hizmet vermektedir. Aktif olarak 28 çalışanı bulunmaktadır. <br/><br/>

              2023 yılında tüm paylarına sahip olduğu Povego şirketini kurmuştur.  Esas sözleşmesinde de belirtildiği gibi, her türlü dijital varlık alım satım işlemini gerçekleştirmek ve bu amaçla platform faaliyetleri yürütmek üzere kurulan Povego, inovasyon ve finans sektörünün kesişim noktasında kendini konumlandırmaktadır. Fiziki varlığa sahip ve aynı zamanda sağlam finansal dayanaklı tokenların işlem göreceği bu platform, yatırım ve ticarete güvenilir bir ortam sunmayı hedeflemekte; küresel yatırımcılardan kurumlara, bireysel yatırımcılardan tasarruf sahiplerine kadar tüm kesimleri dijital varlıkların dinamik dünyasına katılmaya teşvik etmektedir.<br/><br/>


</Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default AboutUs;
  