import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import arrow from "../../assets/images/website/launcharrow.png";
import android from "../../assets/images/website/android_1.png";
import apple from "../../assets/images/website/apple-ios.png";
import weblogo from "../../assets/images/website/weblogo.png";
import downarrow from "../../assets/images/website/downarrow.png";
import earth from "../../assets/images/website/footerearth.png";
import twitter from "../../assets/images/website/x.png";
import linkedin from "../../assets/images/website/Linkedin.png";
import instagram from "../../assets/images/website/instagram.png";
import fb from "../../assets/images/website/fb.png";
import youtube from "../../assets/images/website/youtube.png";
import { Link, useNavigate } from "react-router-dom";
import aydinlatma from "../../assets/images/aydinlatma-metni.pdf";
import kisisel from "../../assets/images/kisisel-veri.pdf";
import kullanim from "../../assets/images/kullanim-sözlesmesi.pdf";
import cerez from "../../assets/images/gizlilik.pdf";


function Footer() {
  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundColor: "#E63B1F",
          padding: { xs: window.location.pathname === '/' && "71px 0px 0px", lg: window.location.pathname === '/' && "140px 0px 0px" },
        }}
        className="footer_back"
      >
        <Container maxWidth="xl">
          <Box sx={{ margin: "auto" , display : window.location.pathname === '/' ? 'block' : 'none' }}>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 700,
                fontFamily: '"Inter", sans-serif',
                fontSize: { xs: 40, lg: 54 },
                lineHeight: { xs: "52px", lg: "65px" },
                textAlign: "center",
              }}
            >
              Povego'yu bugün deneyin
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 400,
                fontFamily: '"Inter", sans-serif',
                fontSize: { xs: 18, lg: 22 },
                padding: "24px 0px 40px",
                margin: "auto",
                maxWidth: "580px",
                textAlign: "center",
              }}
            >
              RWA'lara yatırım yapmanın en kolay yolu. Bugün kayıt olun!
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={apple} alt="apple" style={{ cursor : 'pointer'}}></img>
              <img
                src={android}
                alt="android"
                style={{ marginLeft: "40px" , cursor : 'pointer' }}
              ></img>
            </Box>
            <Button
              sx={{
                fontWeight: 500,
                fontSize: 18,
                color: "#E63B1F",
                backgroundColor: "#fff",
                borderRadius: "48px",
                padding: "16px 43px",
                textTransform: "none",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px auto 0px",
                fontFamily: '"Inter", sans-serif',
                "&::first-letter": {
                  textTransform: "capitalize",
                },
                "&:hover": {
                  background: "#fff",
                },
              }}
              onClick={() => navigate("/login")}
            >
              Povego'yu deneyin
              <img src={arrow} alt="arrow" style={{ marginLeft: "10px" }}></img>
            </Button>
          </Box>

          <Grid
            container
            sx={{ padding: {xs: '140px 0px 30px' , lg: "140px 0px 62px"}, borderBottom: {xs: 'none' , lg: "1px solid #fff"} }}
          >
            <Grid
              item
              xs={12}
              md={3}
              sx={{ textAlign: { xs: "center", md: "start" } }}
            >
              <img
                src={weblogo}
                alt="weblogo"
                style={{ cursor: "pointer" }}
              ></img>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                marginTop: { xs: "80px", md: "0px" },
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <ul style={{ listStyle: "none", margin: "0px", padding: "0px" }}>
                <li>
                  <Typography
                    sx={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#fff",
                      marginBottom: "15px",
                    }}
                  >
                    Povego
                  </Typography>
                </li>
                <li>
                  <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/ne'}
                  >
                  Povego Nedir
                  </Link>
                </li>
                <li>
                  
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/neden'}
                  >
                  Neden Povego
                  </Link>
                </li>
                <li>
                  
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/hakkimizda'}
                  >
                  Hakkımızda
                  </Link>
                </li>
                <li>
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/takim'}
                  >
                  Ekibimiz
                  </Link>
                </li>
                <li>
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/operasyon'}
                  >
                  Nasıl işlem yapılır
                  </Link>
                </li>
                
              </ul>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                marginTop: { xs: "80px", md: "0px" },
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <ul style={{ listStyle: "none", margin: "0px", padding: "0px" }}>
                <li>
                  <Typography
                    sx={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#fff",
                      marginBottom: "15px",
                    }}
                  >
                    Sözleşmeler
                  </Typography>
                </li>
                <li>
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/aydinlatma'}
                  >
                  Aydınlatma Metni
                  </Link>
                </li>
                <li>
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/kisisel'}
                  >
                  Kişisel Verilerin İşlenmesi  
                  </Link>
                </li>
                <li>
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/kullanim'}
                  >
                  Kullanım Sözleşmesi
                  </Link>
                </li>
                <li>
                <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'/cerezler'}
                  >
                  Gizlilik ve Çerez Politikası
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              sx={{
                marginTop: { xs: "80px", md: "0px" },
                textAlign: { xs: "center", md: "start" },
              }}
            >
              <ul style={{ listStyle: "none", margin: "0px", padding: "0px" }}>
                <li>
                  <Typography
                    sx={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "18px",
                      fontWeight: 700,
                      color: "#fff",
                      marginBottom: "15px",
                    }}
                  >
                    Şirket
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                    }}
                  >
                    Maslak Mah. Büyükdere Cad. No: 237 Noramin İş Merkezi Kat: 4 Sarıyer/İstanbul<br/>
                    (0212) 236 99 25<br/>
                    info@povego.com.tr
                  </Typography>
                </li>
                
                
              </ul>
            </Grid>
          </Grid>
          <Box
            sx={{
              padding: {xs: '50px 0px 20px' , lg: "50px 0px 104px"},
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <Box
              sx={{
                listStyle: "none",
                margin: "0px",
                display: "flex",
                alignItems: "center",
                padding: "0px",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: '"Inter", sans-serif',
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#fff",
                  marginLeft: {xs: '0px' , lg: "30px"},
                  marginBottom : {xs: '20px' , lg: '0px'},
                  cursor: "pointer",
                }}
              >
                ©2024 Povego
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" , justifyContent : {xs: 'center' , lg: 'end'} , borderTop : { xs : '1px solid #fff' , lg: 'none'} , width : {xs: '100%' , lg: 'auto'} , paddingTop : {xs: '18px' , lg: '0px'} }}>
                
              <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'https://www.facebook.com/povegotr/'}
                  >
                <img
                src={fb}
                alt="facebook"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              ></img>
              </Link>
              <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'https://www.linkedin.com/company/povegotr'}
                  >
                <img
                src={linkedin}
                alt="linkedin"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              ></img>
              </Link>
              
              <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'https://www.instagram.com/povegotr'}
                  >
                <img
                src={instagram}
                alt="instagram"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              ></img>
              </Link>
              <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'https://www.youtube.com/@povegoTR'}
                  >
                <img
                src={youtube}
                alt="youtube"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              ></img>
              </Link>
              <Link
                    style={{
                      fontFamily: '"Inter", sans-serif',
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#fff",
                      marginBottom: "15px",
                      cursor: "pointer",
                      textDecoration: "none"
                  }}
                  to={'https://x.com/povegoTR'}
                  >
                <img
                src={twitter}
                alt="twitter"
                style={{ marginLeft: "32px", cursor: "pointer" }}
              ></img>
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default Footer;
