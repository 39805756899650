
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Input, Stack, Typography } from "@mui/material";

// import images
import usd from "../assets/images/usd.png";
import cross from "../assets/images/cross.png";
import arrow from "../assets/images/arrow-down.png";
import try_logo from "../assets/images/try_logo.png";
import chgr_logo from "../assets/images/chgr_logo.png";

import { dispatch, useSelector } from "../store";
import { DefaultAssets, TurkeyBanks } from "../config"
import { getBalances, getBankBook, getCryptoBook, withdrawBank, withdrawCrypto } from "../apis";
import toast from "react-hot-toast";
import { UpdateBalances } from "../store/reducers/crypto";
import { usePovegoContext } from "../context";


function Withdraw() {
  const history = useNavigate();
  const { balances, assets } = useSelector((state) => state.crypto)
  const { userId, token } = useSelector((state) => state.auth);
  const { loading, setLoading, setSettingOptions } = usePovegoContext()
  const [amount, setAmount] = useState(0);
  const [bankBook, setBankBook] = useState([]);
  const [details, setDetails] = useState(false);
  const [cryptoBook, setCryptoBookes] = useState([]);
  const [showCurreny, setShowCurreny] = useState(false);
  const [withdrawWWallet, setWithdrawWWallet] = useState({});
  const [selectCurrency, setSelectCurrency] = useState(DefaultAssets[0].asset_id)

  const add = () => {
    setSettingOptions('newaddress')
    history("/anasayfa/ayarlar")
  }
  const fetchAddresses = async () => {
    if (!userId || !token) {
      toast.error("Kullanıcı ID veya Auth Token eksik.")
      return;
    }

    try {
      const crypto = await getCryptoBook(userId);
      const bank = await getBankBook(userId);
      setCryptoBookes(crypto)
      setBankBook(bank);
    } catch (error) {
      toast.error("Adres alınamadı")
    }
  };

  const handleWithdraw = async () => {
    setLoading(true)
    try {
      if (balances.filter((item) => item.id === selectCurrency)[0].balance < amount || amount <= 0) {
        toast.error("Dorğu miktar girin")
        return
      }

      let data;
      if (selectCurrency === DefaultAssets[2].asset_id) {
        const params = {
          bank_id: TurkeyBanks.filter((item) => item.name === withdrawWWallet.network)?.[0]?.id,
          user_id: userId,
          amount: Number(amount),
          to_bank: withdrawWWallet.address
        }

        data = await withdrawBank(params)
      } else {
        const params = {
          asset_id: selectCurrency,
          amount: Number(amount),
          user_id: userId,
          to_address: withdrawWWallet.address
        }
        data = await withdrawCrypto(params)
      }

      if (data) {
        await loadBalance()
        toast.success("Çekim başarılı")
      }
      setLoading(false)
    } catch (error) {
      console.log(error, "error")
      toast.error("Çekim başarısız")
      setLoading(false)

    }
  }

  const loadBalance = async () => {
    try {
      const balances = await getBalances(userId)
      const sorted = await assets.map((asset) => {
        let balance = 0;
        const data = balances.filter((item) => item.asset_id === asset.id)
        if (data.length > 0) {
          balance = data[0].balance
        }

        return {
          ...asset,
          balance
        }
      })
      dispatch(UpdateBalances(sorted))

    } catch (error) {
      console.error('Hata:', error);
    }
  };

  useEffect(() => {
    setWithdrawWWallet(selectCurrency === DefaultAssets[2].asset_id ? bankBook[0] : cryptoBook[0])
  }, [selectCurrency, bankBook, cryptoBook])

  useEffect(() => {
    if (userId && token) {
      fetchAddresses();
    }
    // eslint-disable-next-line
  }, [userId, token]);

  return (
    <Box>

      <Box sx={{ maxWidth : '400px' , margin : 'auto' , position : 'relative'}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "24px",
          }}
        >
          <Typography
            sx={{
              fontSize: 20,
              color: "#fff",
              fontWeight: 500,
              fontFamily: '"Inter", sans-serif',

            }}
          >
            {details ? "Adres seçin" : "Para çekme"}
          </Typography>
          <img
            src={cross}
            alt="new"
            style={{ position: "absolute", right: "0px", cursor: "pointer" }}
            onClick={() => history('/anasayfa')}
          ></img>
        </Box>
        {
          details ?
            <Box>
              {(selectCurrency === DefaultAssets[2].asset_id ? bankBook : cryptoBook).map((book, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "24px 20px",
                    borderRadius: "16px",
                    backgroundColor: "#1b1b1b",
                    cursor: "pointer",
                    marginTop: index !== 0 ? "5px" : "0px",
                  }}
                  onClick={() => { setDetails(false); setWithdrawWWallet(book) }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={DefaultAssets.filter((item) => item.asset_id === book.asset_id)[0].logo} alt="arrow"></img>
                    <Box sx={{ marginLeft: "10px" }}>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: 19,
                          fontWeight: 600,
                          display: "flex",
                          alignItems: "center",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        {book.label}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#7d7d7d",
                          fontSize: 13,
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        {`${book.address.substring(0, 6)}...${book.address.substring(book.address.length - 6)}`}
                      </Typography>
                    </Box>
                  </Box>
                  <img
                    src={arrow}
                    alt="arrow"
                    style={{ transform: "rotate(-90deg)" }}
                  ></img>
                </Box>
              ))}

              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "11px 0px",
                  width: "100%",
                  marginTop: "10px",
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}
                onClick={() => add()}
              >
                Yeni adres ekle
              </Button>
            </Box>
            :
            <Box sx={ {   maxWidth: "400px",
              minWidth: { sm: "400px" },
              margin: "auto", }}>
              <Box
                sx={{
                  backgroundColor: "#1b1b1b",
                  position: "relative",
                  borderRadius: "16px",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    minHeight: { xl: "120px", sm: "110px", xs: "120px" },
                    padding: "0px 16px",
                    marginTop: "10px",

                  }}
                >
                  <Box
                    sx={{
                      marginTop: "10px",
                      marginBottom: "10px",

                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "#7d7d7d",
                        fontFamily: '"Inter", sans-serif;',
                        fontWeight: 500,
                      }}
                    >
                      Tutar
                    </Typography>
                    <Input
                      className="swap-input"
                      value={amount}
                      onChange={(e) => {
                        if (/^\d+$/.test(Number(e.target.value)) || /^\d+\.\d+$/.test(e.target.value) || Number(e.target.value) === 0) {
                          setAmount(e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value)

                        }
                      }
                      }
                      sx={{
                        fontSize: 33,
                        color: "#fff",
                        fontWeight: 500,
                        fontFamily: '"Inter", sans-serif',
                        border: "none !important",
                        maxWidth: '200px'
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: "#7d7d7d",
                        fontFamily: '"Inter", sans-serif;',
                        fontWeight: 500,
                        marginBottom: "10px",
                      }}
                    >

                      Bakiye: {balances && Number(Number(balances[selectCurrency - 1].balance).toFixed(4))}

                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      color: "#fff",
                      fontSize: 20,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      marginBottom: "10px",

                    }}
                    onClick={() => setShowCurreny(!showCurreny)}
                  >
                    {selectCurrency === DefaultAssets[2].asset_id && <img src={try_logo} alt="usd" style={{ paddingRight: "10px" }}></img>}
                    {selectCurrency === DefaultAssets[0].asset_id && <img src={usd} alt="usd" style={{ paddingRight: "10px" }}></img>}
                    {selectCurrency === DefaultAssets[1].asset_id && <img src={chgr_logo} alt="usd" style={{ paddingRight: "10px" }}></img>}
                    <Typography
                      sx={{
                        marginRight: "14px",
                        fontFamily: '"Inter", sans-serif',
                        fontWeight: 600,
                      }}
                    >
                      {DefaultAssets[selectCurrency - 1].name}
                    </Typography>
                    <img
                      src={arrow}
                      alt="arrow"
                      style={{
                        transform: showCurreny ? "rotate(180deg)" : "rotate(0deg)",
                      }}
                    ></img>
                  </Box>
                </Box>
                <Box
                  sx={{ paddingBottom: showCurreny && "12px" }}
                  className={`withdrawcontent ${showCurreny ? "visible" : "hidden"}`}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0px 16px",
                      padding: "24px 20px",
                      borderRadius: "16px",
                      backgroundColor: "#131313",
                      cursor: 'pointer',
                      border: selectCurrency === DefaultAssets[2].asset_id ? "2px solid #1772E1" : "2px solid transparent",
                    }}
                    onClick={() => setSelectCurrency(DefaultAssets[2].asset_id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={try_logo}
                        alt="trylogo"
                        style={{ marginRight: "10px" }}
                      ></img>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        TRY
                      </Typography>
                    </Box>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 2.73389L5.49926 6L10.7329 1" stroke={selectCurrency === DefaultAssets[2].asset_id ? '#fff' : "#7d7d7d"} strokeWidth="2" />
                    </svg>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0px 16px",
                      padding: "24px 20px",
                      borderRadius: "16px",
                      backgroundColor: "#131313",
                      marginTop: "5px",
                      border: selectCurrency === DefaultAssets[0].asset_id ? "2px solid #1772E1" : "2px solid transparent",
                      cursor: 'pointer'
                    }}
                    onClick={() => setSelectCurrency(DefaultAssets[0].asset_id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={usd}
                        alt="trylogo"
                        style={{ marginRight: "10px" }}
                      ></img>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        USDT
                      </Typography>
                    </Box>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 2.73389L5.49926 6L10.7329 1" stroke={selectCurrency === DefaultAssets[0].asset_id ? '#fff' : "#7d7d7d"} strokeWidth="2" />
                    </svg>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "0px 16px",
                      padding: "24px 20px",
                      borderRadius: "16px",
                      backgroundColor: "#131313",
                      marginTop: "5px",
                      cursor: 'pointer',
                      border: selectCurrency === DefaultAssets[1].asset_id ? "2px solid #1772E1" : "2px solid transparent",
                    }}
                    onClick={() => setSelectCurrency(DefaultAssets[1].asset_id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={chgr_logo}
                        alt="trylogo"
                        style={{ marginRight: "10px" }}
                      ></img>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 600,
                          fontSize: 20,
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          fontFamily: '"Inter", sans-serif',
                        }}
                      >
                        CHGR
                      </Typography>
                    </Box>
                    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.5 2.73389L5.49926 6L10.7329 1" stroke={selectCurrency === DefaultAssets[1].asset_id ? '#fff' : "#7d7d7d"} strokeWidth="2" />
                    </svg>
                  </Box>

                </Box>
              </Box>


              <Box
                sx={{
                  minHeight: { xl: "120px", sm: "110px", xs: "120px" },
                  marginTop: "5px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#1b1b1b",
                    position: "relative",
                    padding: "16px",
                    borderRadius: "16px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#7D7D7D",
                      fontSize: "14px",
                      fontFamily: '"Inter", sans-serif;',
                      fontWeight: 500,
                    }}
                  >
                    Kime
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ cursor: "pointer" }} onClick={() => { withdrawWWallet?.address ? setDetails(true) : add() }}>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: "19px",
                        fontWeight: 600,
                        fontFamily: '"Inter", sans-serif;',
                        marginTop: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      {
                        withdrawWWallet ? withdrawWWallet.label : "Adres Ekle"
                      }
                    </Typography>
                    <img
                      src={arrow}
                      alt="arrow"
                      style={{ transform: "rotate(-90deg)", height: "100%" }}

                    ></img>
                  </Stack>
                  {
                    withdrawWWallet?.address && (
                      <Box>
                        <Typography
                          sx={{
                            color: "#7D7D7D",
                            fontSize: "14px",
                            fontFamily: '"Inter", sans-serif;',
                            marginTop: "28px",
                            fontWeight: 500,
                          }}
                        >
                          Address
                        </Typography>
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: "17px",
                            fontWeight: 500,
                            fontFamily: '"Inter", sans-serif;',
                            maxWidth: "350px",
                            marginTop: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {`${withdrawWWallet.address.substring(0, 10)}...${withdrawWWallet.address.substring(withdrawWWallet.address.length - 10)}`}
                        </Typography>
                      </Box>
                    )
                  }
                  {/* <Typography
                    sx={{
                      color: "#7D7D7D",
                      fontSize: "14px",
                      fontFamily: '"Roboto", sans-serif;',
                      marginTop: "28px",
                    }}
                  >
                    {
                      selectCurrency === DefaultAssets[2].asset_id ? "Bank" : "Network"
                    }
                  </Typography> */}
                  {/* {
                    withdrawWWallet ?
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "19px",
                          fontWeight: 600,
                          fontFamily: '"Inter", sans-serif;',
                          maxWidth: "350px",
                          marginTop: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={selectCurrency === DefaultAssets[2].asset_id ? TurkeyBanks.filter((item) => item.name === withdrawWWallet?.network)?.[0]?.logo : avax}
                          alt="avax"
                          style={{ marginRight: "10px" }}
                        ></img>
                        {
                          withdrawWWallet?.network
                        }

                      </Typography>
                      :
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "19px",
                          fontWeight: 600,
                          fontFamily: '"Inter", sans-serif;',
                          maxWidth: "350px",
                          marginTop: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Add Bank
                      </Typography>

                  } */}
                </Box>
              </Box>
              <Button
                sx={{
                  fontWeight: 600,
                  fontSize: 18,
                  color: "#fff",
                  backgroundColor: "#E63B1F",
                  borderRadius: "16px",
                  padding: "11px 0px",
                  width: "100%",
                  marginTop: "10px",
                  // marginRight:"480px"
                  textTransform: "none",
                  fontFamily: '"Inter", sans-serif',
                  "&::first-letter": {
                    textTransform: "capitalize",
                  },
                  "&:hover": {
                    background: "#E63B1F",
                  },
                }}

                // eslint-disable-next-line
                disabled={loading || (balances && balances.filter((item) => item.id === selectCurrency)[0].balance < amount || amount <= 0) || !withdrawWWallet}
                onClick={() => handleWithdraw()}
              >
                {loading ? "Çekiliyor" : "Onay"}
              </Button>
            </Box>
        }
      </Box>

    </Box>
  );
}

export default Withdraw;