import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import copyText from 'copy-text-to-clipboard'

import avax from "../../assets/images/avax.png";
import copy from "../../assets/images/copy.png";
import arrow from "../../assets/images/arrow-down.png";
// import etherium from "../../assets/images/etherium.png";

import toast from "react-hot-toast";
import { useSelector } from "../../store";

function Crypto() {
  const { wallet } = useSelector((state) => state.auth);

  const [expandedSection, setExpandedSection] = useState(null);

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleCopy = (text) => {
    copyText(text)
    toast.success("Adres kopyalandı")
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "21px 20px",
          borderRadius: "16px",
          backgroundColor: "#1b1b1b",
          marginBottom: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => toggleSection('avax')}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={avax} alt="avax logo" style={{ marginRight: "10px" }}></img>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 600,
                fontSize: 20,
                display: "flex",
                alignItems: "center",
                fontFamily: '"Inter", sans-serif',
              }}
            >
              AVAX C-Chain
            </Typography>
          </Box>
          <img
            src={arrow}
            alt="arrow"
            style={{
              transform: expandedSection === 'avax' ? "rotate(0deg)" : "rotate(-90deg)",
            }}
          ></img>
        </Box>
        {expandedSection === 'avax' && (
          <Box sx={{ marginTop: "25px" }}>
            <Typography
              sx={{
                color: "#7D7D7D",
                fontSize: "14px",
                fontFamily: '"Roboto", sans-serif;',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              Yatırma Adresi
              {/* {copyText && <Typography    sx={{
                color: "#fff",
                fontSize: "14px",
                fontFamily: '"Roboto", sans-serif;',
                marginLeft : '10px'
              }}>
                Metin Kopyalandı!
              </Typography>} */}
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "17px",
                fontWeight: 500,
                fontFamily: '"Inter", sans-serif;',
                maxWidth: "350px",
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {`${wallet.substring(0, 15)}...${wallet.substring(wallet.length - 15)}`}
              <img
                src={copy}
                alt="copy"
                style={{
                  marginLeft: "4px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleCopy(wallet)}
              ></img>
            </Typography>
          </Box>
        )}
      </Box>

      {/* <Box
        sx={{
          padding: "21px 20px",
          borderRadius: "16px",
          backgroundColor: "#1b1b1b",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => toggleSection('ethereum')}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={etherium} alt="etherium logo" style={{ marginRight: "10px" }}></img>
            <Typography
              sx={{
                color: "#fff",
                fontWeight: 600,
                fontSize: 20,
                display: "flex",
                alignItems: "center",
                fontFamily: '"Inter", sans-serif',
              }}
            >
              Ethereum (ERC20)
            </Typography>
          </Box>
          <img
            src={arrow}
            alt="arrow"
            style={{
              transform: expandedSection === 'ethereum' ? "rotate(0deg)" : "rotate(-90deg)",
            }}
          ></img>
        </Box>
        {expandedSection === 'ethereum' && (
          <Box sx={{ marginTop: "25px" }}>
            <Typography
              sx={{
                color: "#7D7D7D",
                fontSize: "14px",
                fontFamily: '"Roboto", sans-serif;',
              }}
            >
              Yatırma Adresi
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                fontSize: "17px",
                fontWeight: 500,
                fontFamily: '"Inter", sans-serif;',
                maxWidth: "350px",
                marginTop: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              0xf9bF22CC029bF4B257da93d81 832F5ADe462d{" "}
              <img
                src={copy}
                alt="copy"
                style={{
                  marginLeft: "4px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
                onClick={() => handleCopy("0xf9bF22CC029bF4B257da93d81832F5ADe462d")}
              ></img>
            
            </Typography>
        
          </Box>
        )}
      </Box> */}
    </React.Fragment>
  );
}

export default Crypto;
