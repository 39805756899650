import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";

import { CustomLoginTextField } from "../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../apis";
import toast from "react-hot-toast";

function CreatePassword() {
  const navigate = useNavigate()
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");
  if (!token) {
    navigate('/reset-password')
  }

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!password && password !== confirmPassword) {
      toast.error("Şifreler eşleşmiyor")
      return
    }

    const data = await resetPassword(password, token)
    if (data.status) {
      toast.success(data.message)
      navigate('/login')

    } else {
      toast.error(data.message)
    }
  }
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
            maxWidth: "400px",
            margin: "auto",
            position: "relative",
          }}
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "37px",
            }}
          >
            <Typography
              sx={{
                fontSize: 20,
                color: "#fff",
                fontWeight: 500,
                fontFamily: '"Inter", sans-serif',
              }}
            >
              Yeni Şifre Oluştur
            </Typography>
          </Box>
          <CustomLoginTextField
            label="Şifre"
            variant="filled"
            type="password"
            autoComplete="off"
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <CustomLoginTextField
            label="Şifreyi Doğrula"
            variant="filled"
            type="password"
            autoComplete="off"
            fullWidth
            required
            value={confirmPassword}
            sx={{ marginTop: "5px" }}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            sx={{
              fontWeight: 600,
              fontSize: 18,
              color: "#fff",
              backgroundColor: "#E63B1F",
              borderRadius: "16px",
              padding: "13px 0px",
              width: "100%",
              textTransform: "none",
              marginTop: "10px",
              "&:hover": {
                background: "#E63B1F",
              },
            }}
            type="submit"
          >
            Onayla
          </Button>
        </form>
      </Container>
    </React.Fragment>
  );
}

export default CreatePassword;
