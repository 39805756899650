import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import logo from "../../assets/images/website/weblogo.png";
import downarrow from "../../assets/images/website/downarrow.png";
import burger_icon from "../../assets/images/website/burger_icon.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "../../store";
function Header({ setOpenSidebar }) {
  const navigate = useNavigate()
  const { username } = useSelector((state) => state.auth);
  return (
    <React.Fragment>
      <Box sx={{backgroundColor: "#E63B1F"}}>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: { xs: '15px 0px', lg: '0px' }
          }}
        >
          <Link to='/'><img src={logo} alt="logo" style={{ cursor: "pointer" }}></img></Link>
          <Box sx={{ display: { xs: 'block', lg: 'none' } }} onClick={() => setOpenSidebar(true)}>
            <img src={burger_icon} alt="logo" style={{ cursor: "pointer" }}></img>
          </Box>
          <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
            <ul
              style={{ listStyle: "none", display: "flex", alignItems: "center" }}
            >
              <li>
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#fff",
                  margin: "0px 16px",
                  cursor: "pointer",
                  textDecoration: "none"
                }}
                to={'/ne'}
              >
                Povego nedir
              </Link>
              </li>
              <li>
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#fff",
                  margin: "0px 16px",
                  cursor: "pointer",
                  textDecoration: "none"
                }}
                to={'/nasil'}
              >
                Nasıl çalışır
              </Link>
              </li>
              <li>
              <Link
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#fff",
                  margin: "0px 16px",
                  cursor: "pointer",
                  textDecoration: "none"
                }}
                to={'/urunler'}
              >
                Ürünler
              </Link>
              </li>
              <li>
                <Button
                  sx={{
                    fontWeight: 500,
                    fontSize: 18,
                    color: "#E63B1F",
                    backgroundColor: "#fff",
                    borderRadius: "48px",
                    padding: "14px 38px",
                    marginLeft: '34px',
                    textTransform: "none",
                    fontFamily: '"Inter", sans-serif',
                    "&::first-letter": {
                      textTransform: "capitalize",
                    },
                    "&:hover": {
                      background: "#fff",
                    },
                  }}
                  onClick={() => navigate("/login")}
                >
                  {username ? username : "Giriş"}
                </Button>
              </li>
            </ul>
          </Box>
        </Box>
      </Container>
      </Box>
    </React.Fragment>
  );
}

export default Header;