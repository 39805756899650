import React, { useState } from "react";

import { useNavigate, Link } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

import toast from "react-hot-toast";
import { Button, Container, Typography } from "@mui/material";

import google from "../assets/images/google.png";

import { googleLogin, login } from "../apis";
import { dispatch } from "../store";
import { usePovegoContext } from "../context";
import { Login } from "../store/reducers/auth";
import { CustomLoginTextField } from "../components";

function LoginComponent() {
  const navigate = useNavigate();
  const { loading, setLoading } = usePovegoContext();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        setLoading(true);

        const data = await googleLogin({ token: tokenResponse.access_token });

        if (data) {
          toast.success("Başarıyla giriş yaptınız!");
          dispatch(Login(data));
          navigate("/anasayfa");
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    },
    onError: (error) => {
      console.log("Google giriş hatası:", error);
      toast.error("Giriş başarısız oldu.");
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const data = await login({
        mail: email,
        password: password,
      });

      if (data) {
        setLoading(false);
        toast.success("Başarıyla giriş yaptınız!");
        dispatch(Login(data));
        navigate("/anasayfa");
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error);
      toast.error("Giriş başarısız oldu.");
    }
  };

  return (
    <Container maxWidth="xl">
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "90vh",
          maxWidth: "400px",
          margin: "auto",
        }}
        onSubmit={handleSubmit}
      >
        <CustomLoginTextField
          label="Email"
          variant="filled"
          type="email"
          autoComplete="off"
          fullWidth
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CustomLoginTextField
          label="Şifre"
          variant="filled"
          type="password"
          sx={{ margin: "5px 0px 0px 0px" }}
          autoComplete="off"
          fullWidth
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Typography
          sx={{
            textAlign: "center",
            color: "#fff",
            fontSize: 14,
            padding: "15px 0px",
            lineHeight: "22px",
          }}
        >
          Şifrenizi mi unuttunuz?
          <Link
            style={{
              color: "#E63B1F",
              fontWeight: 600,
              textDecoration: "none",
              marginLeft: "4px",
            }}
            to={'/reset-password'}
          >
            Buradan sıfırlayın
          </Link>
        </Typography>
        <Button
          sx={{
            fontWeight: 600,
            fontSize: 18,
            color: "#fff",
            backgroundColor: "#E63B1F",
            borderRadius: "16px",
            padding: "13px 0px",
            width: "100%",
            textTransform: "none",
            "&:hover": {
              background: "#E63B1F",
            },
          }}
          disabled={loading}
          type="submit"
        >
          {loading ? "Giriş Yap..." : "Giriş Yap"}
        </Button>
        <Typography
          sx={{
            textAlign: "center",
            color: "#e4e4e7",
            fontSize: 14,
            padding: "10px 0px",
            lineHeight: "22px",
          }}
        >
          VEYA
        </Typography>
        <Button
          sx={{
            fontWeight: 600,
            fontSize: 16,
            color: "#000",
            backgroundColor: "#fff",
            borderRadius: "16px",
            padding: "15px 0px",
            width: "100%",
            textTransform: "none",
            "&:hover": {
              background: "#fff",
            },
          }}
          onClick={loginWithGoogle}
        >
          <img src={google} style={{ marginRight: "15px" }} alt="google" />
          Google ile Giriş Yap
        </Button>

        <Typography
          sx={{
            textAlign: "center",
            color: "#fff",
            fontSize: 14,
            padding: "18px 0px 0px",
            lineHeight: "22px",
          }}
        >
          Hesabınız yok mu?{" "}
          <Link
            to="/kayit"
            style={{
              color: "#E63B1F",
              fontWeight: 600,
              textDecoration: "none",
            }}
          >
            Ücretsiz kayıt olun
          </Link>
        </Typography>

      </form>
    </Container>
  );
}

export default LoginComponent;
