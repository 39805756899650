import axios from "../helpers/axios"

export const register = async (params) => {
    const { data } = await axios.post("/auth/register", params)
    return data;
}


export const login = async (params) => {
    const { data } = await axios.post("/auth/login", params)
    return data;
}

export const googleLogin = async (params) => {
    const { data } = await axios.post("/auth/google", params)
    return data;
}

export const getCryptoBook = async (userId) => {
    const { data } = await axios.get(`/crypto-book/${userId}`)
    return data;
}

export const getBankBook = async (userId) => {
    const { data } = await axios.get(`/bank-book/${userId}`)
    return data;
}


export const withdrawCrypto = async (params) => {
    const { data } = await axios.post(`/withdraw/crypto`, params)
    return data;
}


export const submitKYC = async (params, userId) => {
    const { data } = await axios.post(`/kyc/submit/${userId}`, params)
    return data;
}

export const getKYC = async (userId) => {
    const { data } = await axios.get(`/kyc/${userId}`)
    return data;
}



export const withdrawBank = async (params) => {
    const { data } = await axios.post(`/withdraw/bank`, params)
    return data;
}


export const updateCryptoBookById = async (bookId, params) => {
    const { data } = await axios.patch(`/crypto-book/${bookId}`, params)
    return data;
}

export const updateBankBookById = async (bookId, params) => {
    const { data } = await axios.patch(`/bank-book/${bookId}`, params)
    return data;
}


export const removedCryptoBookById = async (bookId) => {
    const { data } = await axios.delete(`/crypto-book/${bookId}`)
    return data;
}

export const removedBankBookById = async (bookId) => {
    const { data } = await axios.delete(`/bank-book/${bookId}`)
    return data;
}

export const addBankBook = async (params) => {
    const { data } = await axios.post(`/bank-book`, params)
    return data;
}

export const addCryptoBook = async (params) => {
    const { data } = await axios.post(`/crypto-book`, params)
    return data;
}

export const getForgetEmail = async (email) => {
    const { data } = await axios.get(`/auth/password/${email}`)
    return data;
}

export const resetPassword = async (password, token) => {
    const { data } = await axios.post(`/auth/reset/password`, { password, token })
    return data;
}

export const getBalances = async (userId) => {
    const { data } = await axios.get(`/users/usable_balance/${userId}`)
    return data;
}

export const getNotifications = async (userId) => {
    const { data } = await axios.get(`/notification/all/${userId}`)
    return data;
}

export const markALLRead = async (userId) => {
    const { data } = await axios.put(`/notification/all/${userId}`)
    return data;
}

export const getNotificationsByType = async (type, userId) => {
    const { data } = await axios.put(`/notification/${type}/${userId}`)
    return data;
}

export const getActivity = async (userId) => {
    const { data } = await axios.get(`/notification/activities/${userId}`)
    return data;
}

export const getAssets = async () => {
    const { data } = await axios.get(`/crypto-asset`)
    return data;
}


export const swapAssets = async (params) => {
    const { data } = await axios.post(`/swap`, params)
    return data;
}