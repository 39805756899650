import USDTLogo from "../assets/images/usd.png";
import TRYLogo from "../assets/images/try_logo.png";
import ChgrLogo from "../assets/images/chgr_logo.png";

import kuveyt from "../assets/images/kuveyt.svg";
import vakif from "../assets/images/vakif.svg";
// import ybank from "../assets/images/yapibank.svg";

import withdraw from "../assets/images/withdraw.png";
import deposit from "../assets/images/deposit.png";

import translations from './../translation.json';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const NotificationType = [
    {
        value: 'TRANSFER_HISTORY',
        name: "Transfer geçmişi"
    },
    {
        value: 'ANNOUNCEMENT',
        name: "Duyurular"
    },
    {
        value: 'SYSTEM_MESSAGES',
        name: "Sistem mesajları"
    },
]

export const ActivityType = {
    SWAP: { name: "Swap", logo: withdraw },
    BANK_DEPOSIT: { name: "Bank Deposit", logo: deposit },
    DEPOSIT: { name: "Deposit", logo: deposit },
    BANK_WITHDRAW: { name: "Bank Withdraw", logo: withdraw },
    WITHDRAW: { name: "Withdraw", logo: withdraw }
}

const translate = (text) => translations[text] || text;

export const DefaultAssets = [
    { asset_id: 1, name: 'USDT', logo: USDTLogo, description: translate('TETHER') },
    { asset_id: 2, name: 'CHGR', logo: ChgrLogo, description: translate('CHARGET') },
    { asset_id: 3, name: 'TRY', logo: TRYLogo, description: translate('TURKISH LIRA') },
];

export const TurkeyBanks = [
    { id: 1, name: "Kuveyt Türk", receipt: "POVEGO FİNANSAL TEKNOLOJİ A.Ş.", iban: "TR35 0020 5000 0989 4245 4000 01", logo: kuveyt, symbol: "kuveyt" },
    { id: 2, name: "Vakıfbank", receipt: "POVEGO FİNANSAL TEKNOLOJİ A.Ş.", iban: "TR39 0001 5001 5800 7324 6545 10", logo: vakif, symbol: "vakif" },
    // { id: 3, name: "Yapı Kredi Bankası", receipt: "Povego A.Ş.", iban: "TR01 0001 0054 1254 0785 4420 05", logo: ybank, symbol: "yapi" },
];


export const KYCStatus = {
    "PENDING": 'PENDING',
    "APPROVED": 'APPROVED',
    "REJECTED": 'REJECTED',
}