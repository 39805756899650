import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function Operation() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
            
              

              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 700,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 36, lg: 54 },
                  lineHeight: { xs: '44px', lg: "65px" },
                  maxWidth: '800px',
                  margin: { xs: 'auto', lg: 0 },
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
               Nasıl işlem yaparım?
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              Povego’ya üye olduktan sonra banka hesabınızdan Türk Lirası transfer ederek kolayca alım-satım yapabilir, toplam varlıklarınızı ve hesap hareketlerinizi takip edebilirsiniz. Povego’ya sadece kendi banka hesabınızdan para transfer edebilirsiniz. 3. kişilerin hesabından veya vergi kimlik numarası ile açılmış hesaplardan yapılan transferler kabul edilmemektedir.<br/><br/>
              EFT işlemi gerçekleştirecekseniz alıcı adını “Povego ……….. A.Ş.” olarak girmelisiniz. Havale yapacaksanız, IBAN numarasını girdikten sonra alıcı adı otomatik olarak ekrana gelecektir.<br/><br/>
              Göndermek istediğiniz tutarı girdikten sonra işlem açıklamasını  boş bırakarak işleminizi tamamlayabilirsiniz. Bankanız işlem açıklamasını boş bırakmanıza izin vermiyorsa, ad soyad bilgilerinizi girebilirsiniz.<br/><br/>
              Türk Lirası yatırma işleminizi, panelde Hesap Hareketleri bölümünden kolayca takip edebilirsiniz. Yatırdığınız tutar Povego hesabına ulaştıktan sonra kısa süre içerisinde hesabınıza aktarılacaktır. <br/><br/>
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default Operation;
  