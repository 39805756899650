import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, IconButton, Input, Dialog, Stack } from "@mui/material";

import arrow from "../../assets/images/arrow-down.png";
import SwapImg from "../../assets/images/swap-arrow.svg";

import { dispatch, useSelector } from "../../store";
import { usePovegoContext } from "../../context";
import { Swap } from "../../store/reducers/crypto";
import toast from "react-hot-toast";
import { DefaultAssets } from "../../config";

function Sell() {
  const navigate = useNavigate();
  // const { showCurreny, setShowCurreny } = usePovegoContext();
  const { balances } = useSelector((state) => state.crypto);
  const { isLoggedIn, userId, isKycCompleted } = useSelector((state) => state.auth);

  const [type, setType] = useState(false);
  const [buyAmount, setBuyAmount] = useState(0);
  const [sellAmount, setSellAmount] = useState(0);
  // const [sellBalance, setSellBalance] = useState(0);
  const [sellCurrency, setSellCurrency] = useState({});
  const [buyCurrency, setBuyCurrency] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [modalType, setModalType] = useState('sell');


  useEffect(() => {
    const load = async () => {
      const sorted = DefaultAssets.map((item) => {
        return {
          ...item,
          balance: balances.filter((blance) => blance.id === item.asset_id)[0].balance,
          price: balances.filter((blance) => blance.id === item.asset_id)[0].price
        }
      });
      setCurrencies(sorted)
      setSellCurrency(sorted[0])
      setBuyCurrency(sorted[1])
    }

    if (balances.length > 0 && isLoggedIn) {
      load()
    }
  }, [balances, isLoggedIn])

  const swapCurrency = () => {
    const buy = buyCurrency
    const sell = sellCurrency

    setBuyCurrency(sell)
    setSellCurrency(buy)

    const amount = (sellAmount * buy.price) / sell.price;
    setBuyAmount(amount);
  }

  const handleCurrency = (currency) => {
    if (modalType === 'sell' && currency.asset_id !== buyCurrency.asset_id) {
      setSellCurrency(currency);
      const amount = (sellAmount * currency.price) / buyCurrency.price;
      setBuyAmount(amount);
      setShowCurrencyModal(false)

    } else if (modalType === 'buy' && currency.asset_id !== sellCurrency.asset_id) {
      setBuyCurrency(currency);
      const amount = (buyAmount * currency.price) / sellCurrency.price;
      setSellAmount(amount);
      setShowCurrencyModal(false)
    }
  }

  const handleAmountFocus = () => {
    if (!isKycCompleted) {
      navigate("/kyc", { state: { showAlert: true } });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = {
      userId: userId,
      fromAssetId: sellCurrency.asset_id,
      toAssetId: buyCurrency.asset_id,
      fromAmount: sellAmount,
      toAmount: buyAmount,
    };

    if (sellCurrency.balance < sellAmount || sellAmount === 0) {
      toast.error("Giriş hatası");
      return;
    }

    dispatch(Swap(data));

    if (!isLoggedIn) {
      navigate("/home");
    } else {
      navigate("/home/swap");
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        {/* sell section  */}

        <Box
          sx={{
            backgroundColor: "#1b1b1b",
            position: "relative",
            borderRadius: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: { xl: "122px", sm: "122px", xs: "122px" },
              // minHeight: { xl: "110px", sm: "110px", xs: "110px" },
              padding: "0px 16px",
            }}
          >
            <Box xs={8}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#7d7d7d",
                  fontFamily: '"Roboto", sans-serif;',
                }}
              >
                Sat
              </Typography>
              <Input
                className="swap-input"
                value={sellAmount}
                onChange={(e) => {
                  if (
                    /^\d+$/.test(Number(e.target.value)) ||
                    /^\d+\.\d+$/.test(e.target.value) ||
                    Number(e.target.value) === 0
                  ) {
                    setSellAmount(
                      e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value
                    );
                    setBuyAmount(
                      Number(e.target.value) * sellCurrency.price / buyCurrency.price
                    );
                  }
                }}
                onFocus={handleAmountFocus}
                sx={{
                  fontSize: 33,
                  color: "#fff",
                  fontWeight: 500,
                  fontFamily: '"SF Mono", monospace',
                  border: "none",
                  maxWidth: "200px",
                }}
              />
            </Box>
            <Box
              xs={4}
              sx={{
                color: "#7d7d7d",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  paddingTop: "40px",
                  color: "#fff",
                  fontSize: 20,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => { setShowCurrencyModal(true); setModalType('sell') }}
              >
                <Box component="img" src={sellCurrency.logo} sx={{ paddingRight: "10px" }} />

                <Typography
                  sx={{
                    marginRight: "14px",
                    fontFamily: '"Inter", sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {sellCurrency.name}
                </Typography>

                <img
                  src={arrow}
                  alt="arrow"
                // style={{
                //   transform:
                //     showCurreny && isLoggedIn ? "rotate(180deg)" : "rotate(0deg)",
                // }}
                />
              </Box>
              {isLoggedIn && (
                <Box
                  sx={{
                    paddingTop: "3px",
                    color: "#7d7d7d",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: '"SF Mono", monospace',
                      fontWeight: 500,
                    }}
                  >
                    Bakiye:{" "}
                    <span style={{ fontFamily: '"SF Mono", monospace' }}>
                      {Number(Number(sellCurrency.balance).toFixed(3))}
                    </span>
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Dialog onClose={() => setShowCurrencyModal(false)} open={showCurrencyModal} sx={{ borderRadius: "10px" }}>
          <Stack className="currency-modal">
            <Typography variant="h6" textAlign="center">
              Bir para birimi seçin
            </Typography>
            <Stack pt={5}>
              {
                currencies.map((item, key) => {
                  return (
                    <Stack direction="row" key={key} alignItems="center" justifyContent="space-between" onClick={() => handleCurrency(item)} className="currency-item" >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box component="img" src={item.logo}>
                        </Box>
                        <Typography sx={{ fontWeight: 600 }}>
                          {item.name}
                        </Typography>
                      </Stack>

                      <Typography sx={{ fontWeight: 600 }}>
                        {Number(Number(item.balance).toFixed(3)) ?? 0}
                      </Typography>
                    </Stack>
                  )
                })
              }
            </Stack>
          </Stack>

        </Dialog>


        <Box sx={{ position: "relative" }}>
          <IconButton
            style={{
              color: "white",
              backgroundColor: "#1b1b1b",
              borderRadius: "12px",
              border: "4px solid #131313",
              position: "absolute",
              top: "-16px",
              left: "50%",
              transform: "translate(-50% , -0%)",
              zIndex: 10,
            }}
            onClick={() => swapCurrency()}
          >
            <img src={SwapImg} style={{ height: "22px", width: "22px" }} alt="longarrow" />
          </IconButton>
        </Box>

        <Box
          sx={{
            backgroundColor: "#1b1b1b",
            position: "relative",
            borderRadius: "16px",
            marginTop: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: { xl: "122px", sm: "122px", xs: "122px" },
              padding: "0px 16px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "#7d7d7d",
                  fontFamily: '"Roboto", sans-serif;',
                }}
              >
                Al
              </Typography>
              <Input
                className="swap-input"
                value={buyAmount}
                onChange={(e) => {
                  if (
                    /^\d+$/.test(Number(e.target.value)) ||
                    /^\d+\.\d+$/.test(e.target.value) ||
                    Number(e.target.value) === 0
                  ) {
                    setBuyAmount(
                      e.target.value.indexOf(".") === -1 ? Number(e.target.value) : e.target.value
                    );
                    setSellAmount(
                      Number(e.target.value) * buyCurrency.price / sellCurrency.price
                    );
                  }
                }}
                onFocus={handleAmountFocus}
                sx={{
                  fontSize: 33,
                  color: "#fff",
                  fontWeight: 500,
                  fontFamily: '"SF Mono", monospace',
                  border: "none",
                  maxWidth: "200px",
                }}
              />
            </Box>
            <Box
              xs={4}
              sx={{
                color: "#7d7d7d",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                flexDirection: "column",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  paddingTop: "40px",
                  color: "#fff",
                  fontSize: 20,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => { setShowCurrencyModal(true); setModalType('buy') }}
              // onClick={() => setShowCurreny(!showCurreny)}
              >
                <Box component="img" src={buyCurrency.logo} sx={{ paddingRight: "10px" }} />

                <Typography
                  sx={{
                    marginRight: "14px",
                    fontFamily: '"Inter", sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {buyCurrency.name}
                </Typography>

                <img
                  src={arrow}
                  alt="arrow"
                // style={{
                //   transform:
                //     showCurreny && isLoggedIn ? "rotate(180deg)" : "rotate(0deg)",
                // }}
                />
              </Box>
              {isLoggedIn && (
                <Box
                  sx={{
                    paddingTop: "3px",
                    color: "#7d7d7d",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontFamily: '"SF Mono", monospace',
                      fontWeight: 500,
                    }}
                  >
                    Bakiye:{" "}
                    <span style={{ fontFamily: '"SF Mono", monospace' }}>
                      {Number(Number(buyCurrency.balance).toFixed(3))}
                    </span>
                  </Typography>
                </Box>
              )}
            </Box>

          </Box>
        </Box>

      </Box>

      <Button
        sx={{
          fontWeight: 600,
          fontSize: 18,
          color: "#fff",
          backgroundColor: "#E63B1F",
          borderRadius: "16px",
          padding: "13px 0px",
          marginTop: "10px",
          textTransform: "none",
          fontFamily: '"Inter", sans-serif',
          "&::first-letter": {
            textTransform: "capitalize",
          },
          "&:hover": {
            background: "#E63B1F",
          },
        }}
        disabled={
          balances.length > 0 && (sellCurrency.balance < sellAmount || sellAmount === 0)
        }
        fullWidth
        onClick={(e) => (isLoggedIn ? onSubmit(e) : navigate("/login"))}
      >
        {isLoggedIn ? "Takas özeti" : "Giriş Yap"}
      </Button>
    </React.Fragment >
  );
}

export default Sell;
