import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { Box, Button, Container, Typography } from "@mui/material";
import { CustomLoginTextField } from "../components";

import cross from "../assets/images/cross.png";

import { getForgetEmail } from "../apis";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const history = useNavigate();

  const handleReset = async (e) => {
    e.preventDefault();
    const data = await getForgetEmail(email);
    if (data.status) {
      toast.success(data.message)
    } else {
      toast.error(data.message)
    }
  }
  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "90vh",
            maxWidth: "400px",
            margin: "auto",
            position: "relative",
          }}
          onSubmit={handleReset}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: '37px'
            }}
          >
            <Typography
              sx={{
                fontSize: 20,
                color: "#fff",
                fontWeight: 500,
                fontFamily: '"Inter", sans-serif',
              }}
            >
              Şifreyi Sıfırla
            </Typography>
            <img
              src={cross}
              alt="new"
              style={{ position: "absolute", right: "20px", cursor: "pointer" }}
              onClick={() => history("/login")}
            ></img>
          </Box>
          <CustomLoginTextField
            label="Email"
            variant="filled"
            type="email"
            autoComplete="off"
            fullWidth
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Button
            sx={{
              fontWeight: 600,
              fontSize: 18,
              color: "#fff",
              backgroundColor: "#E63B1F",
              borderRadius: "16px",
              padding: "13px 0px",
              width: "100%",
              textTransform: "none",
              marginTop: "10px",
              "&:hover": {
                background: "#E63B1F",
              },
            }}
            type="submit"
          >
            Gönder
          </Button>

        </form>
      </Container>
    </React.Fragment>
  );
}

export default ResetPassword;
