import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import depositarrow from "../assets/images/depositarrow.png";

import { useSelector } from "../store";
import { NotificationType } from "../config";
import { usePovegoContext } from "../context";
import { getNotifications, markALLRead } from "../apis";

import NotificationComponent from "./NotificationComponent/NotificationComponent";

function Notification() {
  const history = useNavigate();
  const { setNotify } = usePovegoContext();
  const [notifications, setNotifications] = useState([]);
  const { userId, token } = useSelector((state) => state.auth);

  const fetchNotifications = async () => {
    try {
      const data = await getNotifications(userId);
      if (data) {
        setNotifications(data);
      } else {
        console.error('Uyarılar alınamadı', data);
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const markAllRead = async () => {
    try {
      const data = await markALLRead(userId);
      if (data) {
        setNotify(false);
        fetchNotifications(); // Refresh notifications after marking all as read
      } else {
        console.error('Uyarılar okundu olarak işaretlenemedi');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  useEffect(() => {
    if (userId && token) {
      fetchNotifications();
    }
    // eslint-disable-next-line 
  }, [userId, token]);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          marginBottom: 2,
          padding: "16px 0",
          textAlign: "center",
        }}
      >
        <img
          src={depositarrow}
          alt="back"
          style={{
            position: "absolute",
            marginRight:"375px",
            cursor: "pointer",
          }}
          onClick={() => history("/anasayfa")} // Burada history("/anasayfa") kullanıyoruz
        />
        <Typography
          sx={{
            fontSize: { xs: 18, md: 20 },
            color: "#fff",
            fontWeight: 500,
            fontFamily: '"Inter", sans-serif',
          }}
        >
          Notifications
        </Typography>
      </Box>

      <Box
        sx={{
          maxWidth: "350px",
          width: "100%",
          margin: "0 auto",
          paddingBottom: "12px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: { xs: "24px", md: "24px" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "16px 20px",
            borderRadius: "16px",
            backgroundColor: "#1b1b1b",
            marginBottom: "16px",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontWeight: 500,
              fontSize: { xs: 20, md: 24 },
              fontFamily: '"Inter", sans-serif',
            }}
          >
            {notifications.filter(notification => notification.isNew).length} unread message
          </Typography>
          <Button
            sx={{
              fontWeight: 600,
              fontSize: 16,
              color: "#fff",
              backgroundColor: "#E63B1F",
              borderRadius: "16px",
              padding: "10px 0px",
              marginTop: '20px',
              width: "100%",
              textTransform: "none",
              fontFamily: '"Inter", sans-serif',
              "&::first-letter": {
                textTransform: "capitalize",
              },
              "&:hover": {
                background: "#E63B1F",
              },
            }}
            onClick={markAllRead}
          >
            Hepsini okundu olarak işaretle
          </Button>
        </Box>
        <Box sx={{ width: "112%" }}>
          {NotificationType.map((item, index) => (
            <NotificationComponent
              notify={item}
              notification={notifications.filter(
                (notification) => notification.type === item.value
              )}
              key={index}
            />
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default Notification;
