import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function Cookies() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              <strong>GİZLİLİK POLİTİKASI</strong><br/><br/>
            
              
              <strong>1. Giriş</strong><br/><br/>
              <strong>Kapsam ve Amaç:</strong> Povego olarak, kullanıcılarımızın gizliliğini korumak en önemli önceliklerimizdendir. Bu Gizlilik Politikası, kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı, paylaştığımızı ve koruduğumuzu açıklamaktadır. www.povego.com.tr adresindeki platformumuz aracılığıyla topladığımız tüm bilgiler bu politika kapsamında ele alınmaktadır.<br/><br/>
              <strong>Tanımlar:</strong><br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel Bilgi:</strong> Kimliği belirli veya belirlenebilir bir gerçek kişiye ilişkin her türlü bilgi.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Veri Sorumlusu:</strong> Kişisel verilerin işlenme amaçlarını ve araçlarını belirleyen kişi veya kuruluş.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen kişi veya kuruluş.<br/><br/>
<strong>2. Toplanan Bilgiler</strong><br/><br/>
<strong>Kişisel Bilgiler:</strong><br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Adınız ve Soyadınız:</strong> Kimliğinizi doğrulamak ve size hitap etmek için.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• E-posta Adresiniz:</strong> Hesabınızla ilgili iletişim kurmak ve bilgilendirmeler göndermek için.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Telefon Numaranız:</strong> Acil durumlarda veya doğrulama amacıyla iletişim kurmak için.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Kimlik Bilgileriniz:</strong> Yasal gereklilikler ve dolandırıcılık önleme amacıyla.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Mali Bilgileriniz:</strong> Ödeme işlemlerini gerçekleştirmek ve finansal güvenliği sağlamak için.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• IP Adresiniz:</strong> Platformumuzun güvenliğini sağlamak ve dolandırıcılık faaliyetlerini izlemek için.<br/><br/>
<strong>Kişisel Olmayan Bilgiler:</strong><br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Kullanım Verileri:</strong> Platformu nasıl kullandığınız hakkında bilgiler.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Cihaz Bilgileri:</strong> Cihaz türü, işletim sistemi, tarayıcı türü gibi teknik bilgiler.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Demografik Bilgiler:</strong> Yaş, cinsiyet, ilgi alanları gibi bilgiler.<br/><br/>
<strong>Çerezler ve Benzer Teknolojiler:</strong><br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Çerezler:</strong> Kullanıcı deneyimini iyileştirmek ve platformumuzu optimize etmek amacıyla tarayıcınıza yerleştirilen küçük veri dosyaları.<br/><br/>
<strong>&nbsp;&nbsp;&nbsp;&nbsp;• Web İşaretçileri:</strong> Kullanıcı davranışlarını izlemek ve analiz etmek için kullanılan küçük grafik dosyaları.<br/><br/>
<strong>3. Bilgilerin Kullanımı</strong><br/><br/>
<strong>Hizmet Sunumu:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel bilgileriniz, platformumuzdaki hizmetleri sunmak, yönetmek ve geliştirmek amacıyla kullanılmaktadır. Örneğin, hesabınızın oluşturulması, işlemlerinizin gerçekleştirilmesi ve müşteri desteği sağlanması.<br/><br/>
<strong>İletişim:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel bilgilerinizi, sizinle iletişim kurmak, hizmetlerimiz hakkında bilgilendirmek ve taleplerinizi yanıtlamak amacıyla kullanabiliriz. Bu, promosyon teklifleri ve hizmet güncellemeleri hakkında e-postalar göndermeyi içerebilir.<br/><br/>
<strong>Güvenlik:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Platformumuzun güvenliğini sağlamak ve dolandırıcılık faaliyetlerini önlemek için kişisel bilgilerinizi kullanabiliriz. Bu, kimlik doğrulama süreçleri ve güvenlik önlemleri içerebilir.<br/><br/>
<strong>Hukuki Yükümlülükler:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Yasal ve düzenleyici gerekliliklere uymak amacıyla kişisel bilgilerinizi kullanmamız gerekebilir. Bu, mahkeme emirlerine yanıt verme veya yasal yükümlülüklerimizi yerine getirme gibi durumları kapsar.<br/><br/>
<strong>4. Bilgilerin Paylaşımı</strong><br/><br/>
<strong>Hizmet Sağlayıcılar:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Hizmetlerimizi sağlamak amacıyla üçüncü taraf hizmet sağlayıcılarla bilgilerinizi paylaşabiliriz. Bu sağlayıcılar, veri işleme hizmetleri, müşteri destek hizmetleri ve teknik hizmetler sunabilir.<br/><br/>
<strong>İş Ortakları:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• İş ortaklarımızla pazarlama ve işbirliği amaçları doğrultusunda kişisel bilgilerinizi paylaşabiliriz. Bu, ortak pazarlama kampanyaları ve iş geliştirme faaliyetlerini içerebilir.<br/><br/>
<strong>Yasal Gereklilikler:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Yasal mercilerden gelen talepler doğrultusunda veya yasal yükümlülüklerimizi yerine getirmek amacıyla kişisel bilgilerinizi paylaşmamız gerekebilir. Bu, suç soruşturmaları veya hukuki süreçlerle ilgili talepleri kapsar.<br/><br/>
<strong>5. Bilgilerin Saklanması ve Güvenliği</strong><br/><br/>
<strong>Saklama Süresi:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel bilgilerinizi, toplama amaçlarını gerçekleştirmek için gerekli olan süre boyunca saklarız. Bu süre, yasal ve düzenleyici gerekliliklere göre değişebilir. Gereksiz hale gelen kişisel bilgileriniz güvenli bir şekilde silinir veya anonim hale getirilir.<br/><br/>
<strong>Güvenlik Önlemleri:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel bilgilerinizi korumak için uygun teknik ve idari güvenlik önlemleri alırız. Bu önlemler arasında veri şifreleme, erişim kontrolleri, güvenlik duvarları ve düzenli güvenlik denetimleri yer alır.<br/><br/>
<strong>6. Kullanıcı Hakları</strong><br/><br/>
<strong>Erişim ve Düzeltme:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel bilgilerinize erişme, bunları güncelleme veya düzeltme hakkına sahipsiniz. Bu haklarınızı kullanmak için bize başvurabilirsiniz.<br/><br/>
<strong>Silme ve Kısıtlama:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel bilgilerinizin silinmesini veya işlenmesinin kısıtlanmasını talep edebilirsiniz. Bu talepler, yasal gereklilikler ve işleme amaçları doğrultusunda değerlendirilecektir.<br/><br/>
<strong>İtiraz ve Veri Taşınabilirliği:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Kişisel verilerinizin işlenmesine itiraz etme ve verilerinizi yapılandırılmış, yaygın olarak kullanılan ve makine tarafından okunabilir bir formatta alma hakkına sahipsiniz.<br/><br/>
<strong>7. Çocukların Gizliliği</strong><br/><br/>
<strong>Çocuklardan Bilgi Toplama:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• 18 yaşın altındaki çocuklardan bilerek bilgi toplamıyoruz. Eğer bir çocuğun bize kişisel bilgi verdiğini fark edersek, bu bilgiyi mümkün olan en kısa sürede sileriz.<br/><br/>
<strong>Ebeveyn Onayı:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Çocuğunuzun kişisel bilgilerini bizimle paylaşmasını istemiyorsanız, lütfen bu konuda çocuğunuza rehberlik edin.<br/><br/>
<strong>8. Değişiklikler ve Güncellemeler</strong><br/><br/>
<strong>Politika Değişiklikleri:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Gizlilik Politikamızda yapacağımız değişiklikleri bu sayfada yayınlayarak sizi bilgilendiririz. Değişiklikler yayınlandığı anda yürürlüğe girer.<br/><br/>
<strong>Bilgilendirme:</strong><br/><br/>
Önemli değişiklikler yapıldığında, e-posta veya platformumuz üzerinden size bildirimde bulunabiliriz.<br/><br/>
<strong>9. İletişim Bilgileri</strong><br/><br/>
<strong>Veri Sorumlusu:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• POVEGO FİNANSAL TEKNOLOJİ ANONİM ŞİRKETİ<br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• MASLAK MAH. BÜYÜKDERE CAD. NORAMİN İŞ MERKEZİ NO: 237 İÇ KAPI NO: 414 SARIYER/ İSTANBUL<br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• mail@povego.com.tr<br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• 0212 236 99 25<br/><br/>
<strong>İletişim Kanalları:</strong><br/><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Gizlilik politikamız hakkında sorularınız veya talepleriniz için bizimle iletişime geçebilirsiniz.<br/><br/>
<strong>ÇEREZ POLİTİKALARI</strong><br/><br/>
<strong>1. Çerez Nedir?</strong><br/><br/>
<strong>Çerez Tanımı:</strong> Çerezler, bir web sitesini ziyaret ettiğinizde tarayıcınıza yerleştirilen küçük veri dosyalarıdır.<br/><br/>
<strong>Çerez Türleri:</strong> Oturum çerezleri, kalıcı çerezler, birinci taraf çerezler ve üçüncü taraf çerezler gibi farklı türde çerezler bulunmaktadır.<br/><br/>
<strong>2. Kullanılan Çerezler</strong><br/><br/>
<strong>Zorunlu Çerezler:</strong> Web sitemizin çalışması için gerekli olan çerezlerdir. Bu çerezler, oturum açma ve güvenlik gibi temel işlevleri sağlar.<br/><br/>
<strong>Performans Çerezleri:</strong> Ziyaretçi davranışlarını izleyerek web sitemizin performansını analiz eder ve iyileştirir.<br/><br/>
<strong>Fonksiyonel Çerezler:</strong> Web sitemizi daha kişiselleştirilmiş bir deneyim sunmak için kullanılır. Dil ve bölge tercihleri gibi ayarlarınızı hatırlar.<br/><br/>
<strong>Hedefleme ve Reklam Çerezleri:</strong> Size ilgili reklamlar sunmak ve reklam kampanyalarının etkinliğini ölçmek için kullanılır.<br/><br/>
<strong>3. Çerezlerin Kullanım Amaçları</strong><br/><br/>
<strong>Hizmetlerin İyileştirilmesi:</strong> Çerezler, web sitemizin performansını ve işlevselliğini artırmak için kullanılır. Örneğin, hangi sayfaların daha sık ziyaret edildiğini analiz ederek kullanıcı deneyimini iyileştirebiliriz.<br/><br/>
<strong>Kullanıcı Deneyiminin Kişiselleştirilmesi:</strong> Çerezler, kullanıcı tercihlerinizi ve ayarlarınızı hatırlayarak web sitemizi daha kişisel hale getirir. Örneğin, dil tercihlerinizi veya giriş bilgilerinizi saklayarak size daha uygun bir deneyim sunar.<br/><br/>
<strong>Analiz ve Performans Ölçümü:</strong> Çerezler, web sitemizin kullanımını analiz etmek ve performansını ölçmek için kullanılır. Bu, ziyaretçi sayısını, sayfa görüntüleme sayılarını ve kullanıcı etkileşimlerini izlememizi sağlar.<br/><br/>
<strong>4. Çerez Yönetimi</strong><br/><br/>
<strong>Çerez Ayarları:</strong> Çerezlerin yönetimi ve kontrolü için çeşitli araçlar sunmaktayız. Tarayıcı ayarları üzerinden çerez tercihlerinizi yönetebilirsiniz. Ayrıca, web sitemiz üzerinden çerez kullanımını kabul etme veya reddetme seçeneklerini sunuyoruz.<br/><br/>
<strong>Çerezleri Reddetme:</strong> Tarayıcı ayarlarınızı kullanarak çerezleri reddedebilirsiniz. Ancak, çerezleri reddetmenin bazı web sitesi işlevlerinin çalışmamasına neden olabileceğini unutmayın.<br/><br/>
<strong>Tarayıcı Ayarları:</strong> Çerez ayarlarını değiştirmek için tarayıcınızın ayarlarını kullanabilirsiniz. Her tarayıcıda çerez ayarları farklılık gösterebilir, bu nedenle tarayıcınızın yardım bölümüne başvurmanız gerekebilir.<br/><br/>
<strong>5. Üçüncü Taraf Çerezleri</strong><br/><br/>
<strong>Üçüncü Taraf Hizmet Sağlayıcılar:</strong> Web sitemizde üçüncü taraf hizmet sağlayıcılar tarafından yerleştirilen çerezler bulunmaktadır. Bu çerezler, analiz ve pazarlama amaçlarıyla kullanılabilir.<br/><br/>
<strong>Üçüncü Taraf Çerezlerinin Kullanım Alanları:</strong> Üçüncü taraf çerezleri, reklam ağları, sosyal medya platformları ve analitik hizmet sağlayıcılar tarafından kullanılabilir. Bu çerezler, reklamların hedeflenmesi, kullanıcı davranışlarının analiz edilmesi ve hizmetlerin optimize edilmesi amacıyla kullanılabilir.<br/><br/>
<strong>6. Çerez Politikası Değişiklikleri</strong><br/><br/>
<strong>Güncellemeler ve Bilgilendirme:</strong> Çerez Politikamızı zaman zaman güncelleyebiliriz. Değişiklikleri bu sayfada yayınlayarak sizi bilgilendiririz. Önemli değişiklikler yapıldığında, e-posta veya platformumuz üzerinden size bildirimde bulunabiliriz.<br/><br/>



              
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default Cookies;
  