import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function Declaration() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              <strong>Aydınlatma Metni</strong><br/><br/>
            

İşbu sözleşme POVEGO FİNANSAL TEKNOLOJİ ANONİM ŞİRKETİ (“Povego”) ile www.povego.com.tr Sitesine kaydolan (“kullanıcı”) arasında yapılmıştır.<br/><br/>
Kullanıcıların kişisel verilerinin güvenliği sağlamak bizim en önde gelen ilkelerimizdendir. Bu ilkemizden yola çıkarak, POVEGO Aydınlatma Metni ile size;<br/><br/>
	•	Veri Sorumlusu olarak şirketimizin kimliği,<br/>
	•	Kişisel verilerinizin şirketimiz tarafından hangi amaçla toplandığı (işlendiği)<br/>
	•	Kişisel verilerinizi kimlere ve hangi amaçla aktarabileceğimizi,<br/>
	•	Kişisel verilerinizi nasıl edindiğimizi ve bunun yasal nedenlerini,<br/>
	•	Son olarak kişisel verilerinizle ilgili olarak haklarınızın neler olduğunu,<br/><br/>
Açıklamak isteriz.<br/><br/>
Bu bilgileri sizlerle paylaşmadan önce, açıklanması gerekli bazı hususlar şunlardır:<br/><br/>
<strong>1. Kişisel veri nedir?</strong> Gerçek kişinin kimliğinin belirlenmesine yarayan tüm bilgiler kişisel veridir. Örneğin; kişilerin adı, soyadı, telefon numarası, eğitim bilgisi kişisel verilerden bazılarıdır.<br/><br/>
<strong>2. Veri İşleme faaliyeti nedir?</strong> Kişisel verilerin otomatik olan ya da olmayan yollarla, yani bilişim sistemleri üzerinden veya manuel olarak; elde edilmesi, kaydedilmesi, muhafaza edilmesi, aktarılması, sınıflandırılması ya da kullanımının engellenmesi gibi her türlü işlem anlamına gelir.<br/><br/>
<strong>3. Veri Sorumlusu Kimdir?</strong> Veri işleme faaliyetinin neden ve nasıl olacağını belirleyen gerçek ya da tüzel kişidir. Bu bakımdan, Şirketimiz bir veri sorumlusudur.Veri Sorumlusu olarak Şirketimiz, POVEGO FİNANSAL TEKNOLOJİ ANONİM ŞİRKETİ yani kısaca Povego’dur. <br/><br/>
Şirketimiz ilgili daha detaylı bilgi için https:// www.povego.com.tr adlı internet sitemizin İletişim bölümünü ziyaret edebilirsiniz.<br/><br/>
Yukarıdaki açıklamalar ışığında, kişisel verilerinizin işlenmesine ilişkin Aydınlatma Metni kapsamındaki açıklamalarımız aşağıdaki gibidir:<br/><br/>
<strong>1. POVEGO’dan Bilgi Almak, Şirketimiz ile İletişime Geçmek İçin İşbu Başvuru Formunu Doldurmanız Halinde Hangi Kişisel Verileriniz İşlenmektedir?</strong><br/><br/>

<strong>İşbu Başvuru Formu Doldurmanız Halinde;</strong><br/><br/>
<strong>Hangi Kişisel Verileriniz İşlenmektedir?</strong><br/><br/>
İşlenen Kişisel Veriler<br/><br/>
<strong>•	Kimlik Bilgileri</strong><br/>
&nbsp;&nbsp;&nbsp;&nbsp;• Adınız ve soyadınız<br/>
&nbsp;&nbsp;&nbsp;&nbsp;•	Kimlik bilgileriniz<br/>
&nbsp;&nbsp;&nbsp;&nbsp;•	Adresiniz<br/>
&nbsp;&nbsp;&nbsp;&nbsp;•	Eğitim durumu,yaş, cinsiyet, yetenek bilgileriniz<br/><br/>
	<strong>•	İletişim Bilgileri</strong><br/>
  &nbsp;&nbsp;&nbsp;&nbsp;•	Telefon numaranız<br/>
  &nbsp;&nbsp;&nbsp;&nbsp;•	E-posta adresiniz<br/><br/>
	•	<strong>Diğer</strong><br/>
  &nbsp;&nbsp;&nbsp;&nbsp;•	İndirim Kodu Bilginiz<br/><br/>

  <strong>2. Kişisel Verilerin İşlenme Amaçları, Veri İşlemenin Hukuki Sebebi Ve Kişisel Verilerin Toplama Yöntemi,işlenmesi, aktarılması</strong><br/><br/>
2.1. POVEGO, www.povego.com.tr  sitesi üzerinden KULLANICI tarafından kendisine iletilen bilgileri “Gizlilik Politikası” ve “Web Sitesi Kullanım Koşulları” hükümleri doğrultusunda kullanabilir. Bu bilgileri işleyebilir, bir veritabanı üzerinde muhafaza edebilir. POVEGO aynı zamanda; KULLANICI’nın kimliği, adresi, elektronik posta adresi, telefon numarası, IP adresi, sitenin hangi bölümlerini ziyaret ettiği, domain tipi, browser tipi, ziyaret tarihi, saati vs bilgileri de istatistiki değerlendirme, kampanyaların duyurusunu yapma ve kişiye yönelik hizmetler sunma gibi amaçlarla kullanabilir.<br/><br/>
2.2. POVEGOya ait kişisel bilgiler işlenebilecektir. KULLANICI bu duruma açıkça muvafakat etmiş olup paylaşımına onayı vardır.<br/><br/>
2.3. KULLANICI, kendisi tarafından iletilen kişisel bilgilerin POVEGO, mevcut ve ilerideki iştirakleri, bağlı şirketleri, ortakları, halefleri ve/veya bunların belirleyecekleri üçüncü kişiler-kuruluşlar tarafından her türlü tanıtım, reklam, promosyon, iletişim, satış, pazarlama, mağaza kartı, kredi kartı ve üyelik uygulamaları için süresiz olarak veya öngörecekleri süre ile kaydedilmesine, yazılı/manyetik arşivlerde tutulmasına, muhafazasına, işlenmesine, güncellenmesine, paylaşılmasına, transferine ve kullanılmasına, aksi belirtilmediği sürece SMS, internet, mektup, telefon vb. kanallardan temasa geçmelerine izin vermektedir. Şayet KULLANICI, veri paylaşım tercihlerini değiştirmek isterse, bu talebini, POVEGO tarafından belirtilen iletişim kanallarına iletebilecektir. www.onlineegitimmerkezi.net sitesine üyelik, ürün/hizmet alma ve bilgi güncelleme amaçlı girilen bilgiler, keza kredi kartı ve banka kartlarına ait gizli bilgiler diğer internet kullanıcıları tarafından görüntülenmemektedir.<br/><br/>
2.4.KULLANICI tarafından İNTERNET SİTESİ'nde girilen bilgilerin ve işlemlerin güvenliği için gerekli önlemler, POVEGO tarafındaki  sistem altyapısında, bilgi ve işlemin mahiyetine göre günümüz teknik imkanları ölçüsünde alınmıştır. Bununla beraber, söz konusu bilgiler KULLANICI cihazından girildiğinden KULLANICI tarafında korunmaları ve ilgisiz kişilerce erişilememesi için, virüs ve benzeri zararlı uygulamalara ilişkin olanlar dahil, gerekli tedbirlerin alınması sorumluluğu KULLANICI'ya aittir.<br/><br/>
2.5. Kişisel veriler, KULLANICInın ilgi alanlarına yönelik sitedeki yeniliklerden ve etkinliklerden sizleri haberdar etmek, siteyi tercihleriniz doğrultusunda geliştirmek amacıyla kullanılabilir. POVEGO bu bilgileri işleyebilir, bir veritabanı üzerinde muhafaza edebilir. <br/><br/>
2.6 Kullanıcıya ait kişisel veriler, iş bu gizlilik politikası ve Kullanıcı sözleşmesinde tanımlı olan haller haricinde üyelik formlarından topladığı bazı bilgileri, söz konusu üye kullanıcının haberi ya da aksi bir talimatı olmaksızın, üçüncü şahıslarla kesinlikle paylaşmamakta, faaliyet dışı hiçbir nedenle ticari amaçla kullanmamakta ve kesinlikle satmamaktadır.<br/><br/>
<strong>3. Kişisel verilerinize ilişkin olarak haklarınız nelerdir?</strong><br/><br/>
Kişisel Verilerin Korunması Kanunu’nun 11. maddesi gereğince aşağıda sunulan haklara sahip olduğunuzu hatırlatmak isteriz:<br/><br/>
	•	Kişisel verilerinizin işlenip işlenmediğini öğrenme,<br/>
	•	Kişisel verileriniz işlenmişse buna ilişkin bizden bilgi talep etme,<br/>
	•	Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,<br/>
	•	Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,<br/>
	•	Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,<br/>
	•	KVK Kanunu ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,<br/>
	•	İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme,<br/>
	•	Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.<br/>
POVEGO, Kanun’a uygun olarak, idari ve teknik imkanlar dahilinde; Kullanıcıların kişisel verilerini kullanmalarını mümkün kılınması için gerekli olan sistemleri yürütmektedir.Haklarınıza ilişkin taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’e uygun şekilde yazılı olarak ya da kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da bize daha önce bildirdiğiniz ve sistemimizde kayıtlı olan elektronik posta adresini kullanarak aşağıda belirtmiş olduğumuz adreslere her zaman iletebilirsiniz. Talebiniz, niteliğine göre en kısa sürede ve en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret şirket tarafından ilgili kişiden tahsil edilebilir.<br/><br/>
<strong>Veri Sorumlusu:</strong> POVEGO Teknoloji ve İletişim Anonim Şirketi<br/><br/>
<strong>E-posta:</strong><br/>
<strong>KEP adresi:</strong><br/>
<strong>İletişim Adresi:</strong><br/><br/>
 
Kişisel verilerinizin işleme faaliyetleriyle ilgili gerekli açıklamalar bu Aydınlatma Metninde yer almaktadır. Yine de bu konuya ilişkin daha detaylı bilgi talep etmeniz halinde, internet sitemizde yer alan Kişisel Verilerin İşlenmesi ve Korunması Politikamızı inceleyebilir ya da bizleri arayabilirsiniz.<br/><br/>

              
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default Declaration;
  