import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Container,
    Typography,
  } from "@mui/material";

  import { Link, useNavigate } from "react-router-dom";
  import { useSelector } from "../store";

  import React, { useEffect } from "react";
  import plus from "../assets/images/website/plus.png";
  
  function Usage() {
    const [expanded, setExpanded] = React.useState(false);
    const navigate = useNavigate()
    const { username } = useSelector((state) => state.auth);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    return (
      <React.Fragment>
        <Container maxWidth="xl">
            <Box
              sx={{
                maxWidth: "1400px",
                padding: {xs: '65px 0px 107px' , lg: "140px 0px"},
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: 400,
                  fontFamily: '"Inter", sans-serif',
                  fontSize: { xs: 18, lg: 22 },
                  padding: "24px 0px 60px",
                  textAlign: { xs: 'center', lg: 'start' }
                }}
              >
              <strong>KULLANIM SÖZLEŞMESİ</strong><br/><br/>
            
1. Taraflar<br/><br/>
1.1.<br/><br/>
İşbu sözleşme ve sözleşmenin ayrılmaz birer parçası olan eklerden oluşan Povego Kullanım Sözleşmesi (bundan böyle kısaca ‘Kullanım Sözleşmesi’ olarak anılacaktır), “Povego Bilişim A.Ş (bundan sonra Povego olarak anılacaktır)” ile Site’ye üye olan “Kullanıcı” arasında, Kullanıcı’nın Site’ye elektronik ortamda online olarak üye olması anında, Site’de sunulan hizmetleri kullanırken karşılıklı hak ve yükümlülükleri belirtmek amacı ile düzenlenmiştir.<br/><br/>

2. Tanımlar<br/><br/>
2.1. POVEGO FİNANSAL TEKNOLOJİ ANONİM ŞİRKETİ<br/><br/>
Adres: Maslak Mah. Büyükdere Cad. No: 237 Noramin İş Merkezi Kat: 4 Sarıyer/İstanbul<br/><br/>

2.2. Cüzdan: Kripto varlıkların transfer edilebilmesini ve bu varlıkların ya da bu varlıklara ilişkin özel ve açık anahtarların çevrim içi veya çevrim dışı olarak depolanmasını sağlayan yazılım, donanım, sistem ya da uygulamaları,<br/><br/>
2.3. Kripto varlık: Dağıtık defter teknolojisi veya benzer bir teknoloji kullanılarak elektronik olarak oluşturulup saklanabilen, dijital ağlar üzerinden dağıtımı yapılan ve değer veya hak ifade edebilen gayri maddi varlıkları,<br/><br/>
2.4. Kripto varlık hizmet sağlayıcı: Platformları, kripto varlık saklama hizmeti sağlayan kuruluşları ve bu Kanuna dayanılarak yapılacak düzenlemelerde kripto varlıkların ilk satış ya da dağıtımı dâhil olmak üzere kripto varlıklarla ilgili olarak hizmet sağlamak üzere belirlenmiş diğer kuruluşları,<br/><br/>
2.5. Kripto varlık saklama hizmeti: Platform müşterilerinin kripto varlıklarının veya bu varlıklara ilişkin cüzdandan transfer hakkı sağlayan özel anahtarların saklanmasını, yönetimini veya Kurulca belirlenecek diğer saklama hizmetlerini,<br/><br/>
2.6. Platform: Kripto varlık alım satım, ilk satış ya da dağıtım, takas, transfer, bunların gerektirdiği saklama ve belirlenebilecek diğer işlemlerin bir veya daha fazlasının gerçekleştirildiği kuruluşları,<br/><br/>

2.4. Kullanıcı<br/><br/>
Site’ye üye olan ve Site’de sunulan Hizmet’lerden yararlanan gerçek kişi<br/><br/>

2.5. Site<br/><br/>
www.povego.com.tr alan adından ve bu alan adına bağlı alt alan adlarından oluşan internet sitesi<br/><br/>

3.BAŞLANGIÇ<br/><br/>
Söz konusu olan kullanım sözleşmesi, kullanıcının www.povego.com.tr sitesine elektronik olarak online ortamda üye olması ile başlar. Kullanıcı Site’ye üye olurken işbu sözleşmenin tüm hükümlerini okuyarak kabul ettiğini taahhüt eder.<br/><br/>

4.AMAÇ<br/><br/>
Söz konusu olan kullanım sözleşmesi ile sitede sunulan hizmetler kullanılırken Povego ve siteye üye olan kullanıcı arasındaki karşılıklı hak ve yükümlülükleri düzenlenmiştir. Povego’e ait www.povego.com.tr internet sitesi bir alış satış platformudur. Site üzerinden kullanıcılar birbirleri arasında alış ve satış yaparlar. Site, bu alış satışa aracılık eder. Bu sebeple sitede oluşan fiyatları Povego belirlemez, alıcı ve satıcılar belirlemiş olur. Fiyatlar satışa konu tokenlara dayanak teşkil eden varlıkların değerine göre sitedeki platformda belirlenir.<br/><br/>

5. Hak ve Yükümlülükler<br/><br/>
5.1.<br/><br/>
Povego hizmetine bağlı tüm servislerin, alan adlarının, yazılım kodlarının, ara yüzlerinin, içeriklerinin, ürün incelemelerinin, videolarının, algoritmalarının, çizimlerinin, modellerinin, tasarımlarının ve diğer tüm fikri sınai hakların sahibi, (üçüncü partilerden sağlanan içerik ve uygulamalar hariç olmak üzere) Povego Bilişim A.Ş.’dir. Sunulan servislerin yazılım, tasarım ve telif hakları tamamen Povego’e aittir. Servislerin ve hizmetlerin, bununla bağlantılı sayfaların kopyalanmasına, çoğaltılmasına ve yayılmasına, ayrıca tersine mühendislik işlemlerine tabi tutulmasına hiçbir şekilde izin verilmemektedir.

5.2.<br/><br/>
Kullanıcı; hesabını ve haklarını üçüncü şahıslara devredemez, satamaz, kendi üyeliğini her ne nam ve ad altında olursa olsun kendinden başkasına kullandıramaz. Bu durumda Povego kullanıcının hesabını iptal etme, dondurma, askıya alma hakkına sahiptir. Kullanıcının kripto varlıkları bu durumdan etkilenmez.<br/><br/>

5.3.<br/><br/>
Söz konusu site’ye üye olurken verilen bilgilerin doğruluğundan ve gizliliğinden, ayrıca şifre ve kullanıcı adının korunmasından, başka kimselerle paylaşılmamasından kullanıcının kendisi sorumludur. Bu bilgilerin yetkisiz kişiler tarafından ele geçirilmesi ve Povego servislerinin kullanılması halinde doğabilecek zararlardan ve sorumluluklardan Povego hiçbir şekilde sorumluluk kabul etmemektedir.<br/><br/>

5.4.<br/><br/>
Söz konusu Site’nin, kullanıcının hatası sonucu doğacak, her türlü zarar, sorumluluk ve/veya kayıplardan dolayı Povego şirketi, yönetim kurulu üyeleri, yöneticileri, çalışanları ve bu sitede yer alan bilgileri hazırlayan kişilerin hiçbirinin hukuki ve cezai sorumluluğu bulunmamaktadır.<br/><br/>

5.5.<br/><br/>
Kullanıcılar yalnızca T.C. hukukuna uygun amaçlarla Site üzerinde işlem yapabilirler. Kullanıcıların hukuka aykırı işlem yapmalarının tespit edilmesi halinde, Povego hiçbir şekilde sorumlu değildir. Povego, işbu konuda her türlü hukuki yola başvurma, ilgili kolluk kuvvetlerine bildirme ve gerekli güvenlik tedbirlerini alma, hakkına sahiptir.<br/><br/>

5.6.<br/><br/>
Kullanıcıların Site üzerinde aldığı hizmetleri kullanarak yaptığı her işlem ve eylemdeki hukuki ve cezai sorumluluk kendilerine aittir. Povego’un hiçbir hukuki ya da idari sorumluluğu bulunmamaktadır.<br/><br/>

5.7.<br/><br/>
Kullanıcılar sitede hesap sahibi olmak için bir üye formu doldururlar. Siteye üye olan kullanıcı, günlük 750,00-TL. (yediyüzellitürklirası) ve üzerinde Türk Lirası işlemleri için (hem yatırma hem çekme işlemleri için) kimlik (T.C. kimlik no’ya sahip T.C. Nüfus Cüzdanı, T.C. Kimlik kartı, T.C.Ehliyet) ve adres bilgilerini belgelemek zorundadır. Kullanıcı tarafından kimlik ve adres bilgilerinin belgelenmemesi halinde, Povego kullanıcıya ait hesap üzerinden hiçbir işlem yapılmasına izin vermeyecektir. Kullanıcı tarafından belgelenen kimlik ve adres bilgileri sistemin Türkiye Cumhuriyeti Kanun ve mevzuatlarına uyarlı olması amacıyla talep edilmekte olup, yetkili makamlarca talep edilmesi halinde ilgili makamlar ile paylaşılacaktır. Povego, bu haller dışında kullanıcıya ait kimlik ve adres bilgilerini hiçbir gerçek ve/veya tüzel kişi ile paylaşmayacağını taahhüt eder.<br/><br/>

5.8.<br/><br/>
Povego, işlemlerin şüpheli görülmesi halinde, kullanıcıların yaptıkları para transferlerini iade etme hakkına sahiptir.<br/><br/>

5.9.<br/><br/>
Para yatırma işlemlerinin, Kullanıcı ile aynı isimde kayıtlı olan banka hesabından yapılması zorunludur.<br/><br/>

5.10.<br/><br/>
ATM’ler üzerinden kartsız bankacılık yöntemleri ile transfer yapılmamalıdır. Bu tip işlemlerin saptanması durumunda, Povego söz konusu transferleri iade etme hakkına sahiptir.<br/><br/>

5.11.<br/><br/>
Para yatırma ve çekme işlemler zamanında beyan edilen zamanların dışında işlem yapılması durumunda Povego doğacak veya doğabilecek zararlar veya kayıplardan sorumlu değildir.<br/><br/>

5.12.<br/><br/>
Povego reddettiği ve gerçekleştirmediği transferlerin iadesi için kimlik ibrazı ve transfer gerçekleştiren kişinin banka hesabı bilgisini isteme hakkında sahiptir.<br/><br/>

5.13.<br/><br/>
Povego para yatırma ve çekme kurallarında değişiklik yapma hakkına tek taraflı olarak sahiptir. Para yatırma ve çekme kuralları, site üzerindeki destek sayfalarında ve diğer sayfalarda bulunan uyarılar ve kuralların bütünüdür.<br/><br/>

5.14.<br/><br/>
Povego destek hizmetlerini yalnızca destek@povego.com.tr e-posta adresi üzerinden sağlamaktadır. Povego bu e-posta adresi dışında bir yöntem ile kullanıcılarına destek hizmeti sağlamaz, destek yazışmalarında şifre sormaz, kullanıcılarından kripto varlık göndermeleri için bir kripto varlık adresi bildirmez. Bu husus bir tebliğ niteliğinde olup, Povego bahsi geçen e-posta adresi dışındaki bir adresten gönderilmiş bir e-posta sebebiyle kullanıcılarının uğradığı ya da uğraması muhtemel zarar ve kayıplardan sorumlu tutulamaz.<br/><br/>

5.15.<br/><br/>
Kullanıcılar kendi hesaplarının güvenliklerini sağlamak zorundadır. Tavsiye edilen güvenlik önlemleri:<br/><br/>

5.15.1.<br/><br/>
Güçlü bir şifre kullanılması<br/><br/>

5.15.2.<br/><br/>
Kullanıcı adı ve şifresinin Povego çalışanları dahil kimseyle paylaşılmaması<br/><br/>

5.15.3.<br/><br/>
Site’de kullanılan şifrenin yalnızca Site’de kullanılması(başka hiçbir yerde kullanılmaması)<br/><br/>

5.15.4.<br/><br/>
Site’ye her zaman “https” yoluyla bağlantı kurulması ve siteye girişlerde “https://www.povego.com.tr” ya da “https://povego.com.tr” adreslerinin kontrol edilmesidir.<br/><br/>

Povego tarafından bildirilen güvenlik önlemleri, tavsiye niteliğindedir. Belirtilen güvenlik önlemleri alınmış olmasına rağmen, kullanıcının oluşmuş ya da oluşacak olan zararlarından Povego hiçbir şekilde sorumlu tutulamaz.<br/><br/>

5.16.<br/><br/>
Povego Türkiye’nin ilk ve tek token pazaryeridir. Povego bir aracı sitedir. Sitede oluşan fiyatları Povego belirlemez. Fiyatlarda olan değişikliklerden dolayı kullanıcıların zarar veya kayıplara uğramasından ve oluşan ya da oluşacak zararlardan Povego sorumlu tutulamaz.<br/><br/>

5.17.<br/><br/>
Povego benzer diğer alış satış platformlarından bağımsız bir şirkettir. Herhangi bir kurum veya kuruluşun temsilcisi ve vekili değildir. Kullanıcı tarafından Povego haricinde bir alış satış platformunda yapılan alış satış işlemlerinden ve bu işlemlerden oluşan ya da oluşacak zararlardan Povego hiçbir şekilde sorumlu tutulamaz.<br/><br/>

5.18.<br/><br/>
Kripto varlıklara yatırım yapmanın getirdiği belli başlı riskler vardır. Kullanıcılar işbu sözleşmenin kabul edilip onaylanması ile kripto varlıklara yatırımının risklerini anladıklarını ve yatırımın sebep olduğu ya da olabileceği zarar ve ziyanlardan Povego’ü sorumlu tutmayacaklarını beyan ve taahhüt ederler. Fiyat değişimlerinden oluşan kâr ve zararlardan kullanıcılar sorumludur. Kullanıcıların, bir kayıp ya da zarar yaşaması durumunda Povego’ten hiçbir hak talep edemezler.<br/><br/>

5.19.<br/><br/>
Povego üzerinden satın alınan kripto varlıkların nasıl kullanıldığının, nereye ve ne amaçla transfer edildiğinin, bir suçta kullanılıp kullanılmadığının, teknik olarak takibi mümkün değildir. Kripto varlıkların kötüye kullanılmasından doğan ya da doğacak, kullanıcı ya da üçüncü kişilerin zarar ve kayıplarından, menfi veya müspet zararlarından Povego sorumlu tutulamaz.<br/><br/>

5.20.<br/><br/>
Kripto varlık kullanımı ile ilgili yasal sorumluluklar ve vergi mükellefiyetlerinden kullanıcılar bizzat sorumludur. Povego, Kullanıcıların kazanç ve kayıplarından oluşan vergisel mükellefiyetlerinden sorumlu tutulamaz.<br/><br/>

5.21.<br/><br/>
Povego, Kullanıcılar adına muhafaza edilen hesaplarda bulunan kripto varlıkların güvenliği için basiretli bir tüccar olarak azamı özeni göstermekle yükümlüdür. Kullanıcılara ait kripto varlıklar herhangi bir siber saldırıya karşı internet bağlantısı olmayan ortamlarda (cold storage) saklanır. Ancak Povego’ün tüm bu çabalarına rağmen yine de oluşabilecek hırsızlık / dolandırıcılık olaylarından dolayı Povego sorumlu tutulamaz.<br/><br/>

5.22.<br/><br/>
Site, sadece Türkiye Cumhuriyeti’nde ikamet eden Türkiye Cumhuriyeti Vatandaşı gerçek kişilere hizmet verir.<br/><br/>

5.23.<br/><br/>
Kullanıcı, 18 yaşından büyük olmalıdır ve işbu sözleşme kapsamında siteye üye olup kullanabilmesi için Türkiye Cumhuriyeti Merkezi Nüfus İdaresi Sistemine göre 18 yaşından büyük olduğunu beyan ve taadüt eder. Povego, herhangi bir nedenle hesap sahibinin 18 yaşından küçük olduğunu tespit ettiği veya 18 yaşından küçüklerce kullanıldığından şüphelendiği Kullanıcı hesaplarını uyarı olmaksızın sonlandırma/askıya alma hakkına sahiptir. 18 yaşından büyük olduğunu taahhüt eden Kullanıcı, bildirdiği tüm bilgilerin doğruluğundan res’en sorumludur. Povego, Kullanıcı ile ilgili tüm işlemlerde bu beyana itimat eder ve buna göre davranır.<br/><br/>

5.24.<br/><br/>
Her Kullanıcı, sadece bir Kullanıcı hesabına sahip olabilir. Kullanıcı Site’yi sadece işbu Sözleşme’de tanımlanan hizmetlerden faydalanılması amacı ile kullanabilir. Povego, aynı kişi tarafından kullanılan birden fazla Kullanıcı hesabı bulunması durumunda ilgili Kullanıcı hesaplarını ihbarsız olarak sonlandırma/askıya alma hakkına sahiptir.

5.25.<br/><br/>
Povego, işlemlerin teknik hatalardan dolayı, gerçekçi olmayan fiyatlardan gerçekleşmesi gibi durumlarda, sistemi düzeltmek ve doğru çalışmasını sağlamak adına, bu işlemleri iptal edebilir veya geri alabilir. İptal ve geri alma sırasında bu Kullanıcı hesapları dondurulabilir. Bu tarz işlemlerden dolayı Povego sorumlu tutulamaz ve hak talep edilemez.<br/><br/>

5.26.<br/><br/>
Kullanıcıların Povego ile sözlü ve yazılı iletişimde, saygısızlık ve hakaret içeren tavırları karşısında, Povego`ün Kullanıcı hesabını dondurma, kapatma ve hukuki yollara başvurma hakkı saklıdır.<br/><br/>

5.27.<br/><br/>
Kullanıcılar hesaplarında bekleyen paralar ile ilgili olarak faiz veya buna benzer gelir hakkı talep edemez.<br/><br/>

5.28.<br/><br/>
Povego kripto varlık cüzdan adresleri kripto varlık altyapıları gereği değişebilmektedir, Povego kullanıcılarına önceden haber vermeksizin bu adresleri değiştirebilir. Kullanıcılar her kripto varlık yatırma işlemi öncesi ilgili kripto varlık cüzdan adreslerini kontrol etmelidir ve bu şekilde işlem yapmalıdır. Herhangi bir kayıpta Povego sorumlu tutulamaz.<br/><br/>

7. HUKUKİ ve CEZAİ MÜEYYİDELER<br/><br/>
7.1.<br/><br/>
Kullanıcı, Kripto paralar ile ilgili BDDK, SPK, TCMB gibi devlet kuruluşları tarafından yapılan ve bundan sonra yapılacak tüm açıklamaları okumuş ve kabul etmiş olduğunu taahadüt eder.<br/><br/>

7.2.<br/><br/>
Kullanıcı tarafından sitenin hukuka aykırı amaçlarla kullanılması durumunda kullanıcı işbu sözleşmenin (5.1.6.). maddesi uyarınca doğacak tüm hukuki ve cezai müeyyidelerden sorumludur. Bu hususta Povego’u gayri kabili rücu ettiğini kabul, beyan ve taahhüt eder.<br/><br/>

7.3.<br/><br/>
Kullanıcı, siteyi Türkiye Cumhuriyeti yasaları ve tüm mevzuatı kapsamında kullanacağını taahhüt eder. Yasalara aykırı kullanım halinde Povego, kullanıcıya ait tüm bilgileri yetkili merciler ile paylaşma hakkına ve yetkisine sahiptir. Bu husus gizliliğin ihlali kapsamında değerlendirilemez ve Povego’u her hangi bir sorumluluk atfedilemez.<br/><br/>

8. Ücretlendirme<br/><br/>
8.1.<br/><br/>
Povego, Hizmet’lerle ilgili ücretleri Site’nin ‘Destek’ bölümünde ilan edecektir. Site’nin yardım bölümüne http://destek.povego.com.tr adresinden ulaşılabilmektedir. Ücretler, destek bölümünde ilan edildiği andan itibaren geçerlilik kazanacaktır.<br/><br/>

8.2.<br/><br/>
Povego, her alış ve satış işlemlerinde kullanıcılarından kendi belirlediği bir oran üzerinden komisyon (hizmet bedeli) alma hakkına sahiptir. Povego, zaman zaman bu ücretler ve oranlar üzerinde değişiklik yapma hakkına sahiptir.<br/><br/>

8.3.<br/><br/>
Povego, her TL çekme işleminde kullanıcılarından kendi belirlediği bir işlem ücreti alma hakkına sahiptir. Kullanıcı, iş bu sözleşmenin kabul ve onaylanması ile, bu işlem ücretinin alınmasını kabul etmiş sayılacaktır.<br/><br/>

8.4.<br/><br/>
Kripto varlık transferleri iade edilemez, dolayısıyla Povego tarafından tahsis edilen komisyon veya işlem ücretlerinin iadesi mümkün değildir ve kullanıcıların bu ücretlerin iadesini isteme hakkı bulunmamaktadır.<br/><br/>

9. Gizlilik Politikası<br/><br/>
9.1.<br/><br/>
İş bu sözleşmenin kullanıcı tarafından kabul edilmesi ve onaylanması halinde, 6698 sayılı Kişisel Verilerin Korunması hakkındaki Kanundan doğan hak ve yükümlülükleri, Kullanıcı ve Povego karşılıklı olarak kabul edilmiş sayılacak ve Kanun kapsamına uygun olarak davranacaklarını kabul ve tahahhüt ederler.<br/><br/>

9.2.<br/><br/>
Povego, Kullanıcıların şahsi bilgilerini (kimlik, adres, fatura, telefon, e-mail, müşteri numarası, faks, demografik bilgiler) yasal zorunluluklar veya kullanıcının açık rızası haricinde üçüncü şahıslara vermeyeceğini taahhüt eder.<br/><br/>

9.3.<br/><br/>
Kullanıcıların vermiş olduğu bilgiler Kullanım Sözleşmesi’nde belirtilen kurallar ve amaçlar dışında herhangi bir kapsamda kullanılmayacak, üçüncü şahıslarla paylaşılmayacaktır.<br/><br/>

9.4.<br/><br/>
Povego, kullanıcıların IP adreslerini, Site’ye erişim sağladıkları cihazların marka ve modellerini, işletim sistemlerini ve tarayıcı bilgilerini tespit etmekte ve kayıt altına almaktadır. Povego, bu kayıtları kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak, sistem ve kullanıcı hesap güvenliğini sağlamak, sahtecilik ile mücadele etmek ve kanuni yükümlülüklere uymak amaçları için kullanabilir.<br/><br/>

9.5.<br/><br/>
Povego, sunmuş olduğu hizmetlerin ve servislerin daha efektif kullanılabilmesi amacıyla birçok 3. Parti kurum ve kuruluşlarla çeşitli şekillerde iş birliği yapabilir. Bu işbirliği; reklam, sponsorluk, izinli pazarlama, veri paylaşımı ve yasal diğer ticari yöntemlerle olabilir. Povego, iletişim faaliyetlerinde, kanunların öngördüğü şekilde izinli iletişim / pazarlama yapacağını, kullanıcının isteği dışında iletişim yapmamayı, kullanıcının sistemden ücretsiz ve kolayca çıkabilmesini sağlayacak araçlar sunmayı beyan ve taahhüt eder.<br/><br/>

9.6.<br/><br/>
Povego, Site içerisinde başkaca sitelere link(bağlantı) sağlayabilir. Anlaşmalı olduğu 3. Partilerin reklamlarını ve/veya çeşitli hizmetlere ilişkin başvuru formlarını yayınlayabilir, Kullanıcıları bu formlar ve reklamlar aracılığıyla reklam veren veya anlaşmalı 3. partilerin sitesine yönlendirebilir. Povego, bu bağlantı yoluyla erişilen diğer sitelerin gizlilik uygulama ve politikalarına ya da barındırdıkları içeriklere yönelik olmak üzere hiç bir sorumluluk taşımamaktadır.<br/><br/>

9.7.<br/><br/>
Povego, aşağıda sayılan hallerde ise işbu gizlilik bildirimi hükümleri dışına çıkarak kullanıcılara ait bilgileri üçüncü kişilere açıklayabilecektir. Bu durumlar:<br/><br/>

9.7.1.<br/><br/>
Kanun, KHK, Yönetmelik vb. yetkili hukuki makamlar tarafından çıkarılan ve yürürlükte bulunan yazılı hukuk kurallarının getirdiği zorunluluklara uyulmasının gerektiği haller,<br/><br/>

9.7.2.<br/><br/>
Povego’un kullanıcılarıyla arasındaki sözleşmelerin gereklerinin yerine getirilmesi ve bunların uygulamaya konulmalarıyla ilgili hallerde,<br/><br/>

9.7.3.<br/><br/>
Yetkili idari ve/veya adli makamlarca usul ve yöntemine uygun olarak yürütülen bir araştırma veya soruşturma doğrultusunda kullanıcılarla ilgili bilgi talep edilmesi hallerinde,<br/><br/>

9.7.4.<br/><br/>
Kullanıcıların haklarını veya güvenliklerini koruma amacıyla bilgi verilmesinin gerekli olduğu hallerde.<br/><br/>

9.8.<br/><br/>
Povego, kendisine verilen gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır olarak saklamayı yükümlülük olarak kabul ettiğini ve gizliliğin sağlanıp sürdürülmesi, gizli bilginin tamamının veya herhangi bir parçasının kamu alanına girmesini veya yetkisiz kullanıcıyı veya üçüncü bir kişiye açıklanmasını önleme gereği olan gerekli tüm tedbirleri almayı ve üzerine düşen tüm özeni tam olarak göstermeyi işbu bildirimle taahhüt etmektedir.<br/><br/>

9.9.<br/><br/>
Povego bir hesabın kaydıyla, kimlik doğrulama amacıyla talep edilen kişisel bilgileri paylaşmayı kabul edersiniz. Bu bilgi, özellikle Kara Para Aklama, terörizmin finansmanı, sahtecilik ve Povego platformundaki diğer mali suçların tespiti için kullanılmaktadır. Bu bilgileri sağlamanın yanı sıra, veri saklamaya yönelik küresel endüstri standartlarına uyumu kolaylaştırmak için, hesabınızın kullanım süresi ve hesabın kapanmasından sonraki 5 yıl boyunca söz konusu bilgilerin kaydını tutmamıza izin vermiş olursunuz. Ayrıca, kimliğinizi doğrulamak için veya dolandırıcılık gibi mali suçlara karşı sizi ve / veya bizi korumak için gerekli görülen doğrudan veya üçüncü taraflar aracılığıyla soruşturma yapma konusunda bize yetki vermektesiniz.<br/><br/>

10. Sorumsuzluk Beyanı<br/><br/>
10.1.
povego.com.tr Site’si üzerinde sunulan her tür içerik, 3. Partilerden ve kamuya açık kaynaklardan toplanan verilerden oluşmaktadır. Tüm veriler, raporlar, analizler, istatistikler herhangi bir düzenleme veya yönlendirme olmadan bilgileri otomatik işleme tabi tutmuş bir yazılım tarafından işlenmekte ve objektif olarak sunulmaktadır. Povego tarafından sunulan her türlü haber, raporlar ve bültenler; sadece bilgilendirmeye ve tavsiyeye yöneliktir ve doğrudan çözüm, sonuç, hukuki görüş, ekonomik, politik ve sosyolojik araştırma bilgisi özelliği taşımamaktadır ve kesin doğruluğu garanti edilmemektedir. Veriler birbiriyle çelişkili veya tutarsız olabilir. Bu tür durumlarda Povego hiçbir sorumluluk üstlenmez.<br/><br/>

10.2.<br/><br/>
Povego, KENDİSİ TARAFINDAN SUNULAN İÇERİKLERİN KULLANICI YOLUYLA HERHANGİ BİR KİŞİ VEYA KURULUŞ TARAFINDAN ELDE EDİLECEK SONUÇLARLA İLGİLİ OLARAK, HER TÜRLÜ TİCARETE ELVERİŞLİLİK, PERFORMANS, PAZARLANABİLİRLİK, BELLİ BİR AMACA UYGUNLUK VEYA BELİRLİ BİR AMACA UYGUNLUK GARANTİLERİ DE DÂHİL OLMAK ÜZERE İSTER AÇIK İSTER ÖRTÜLÜ OLSUN, HİÇBİR ŞEKİLDE GARANTİ VERMEZ. TÜM İÇERİKLER KULLANICIYA “OLDUĞU GİBİ” SUNULMAKTADIR. Povego, İÇERİKLERİN KULLANILMASINDAN DOĞABİLECEK KÂR KAYIPLARINDAN YA DA MENFİ ZARARLARDAN SORUMLU TUTULAMAZ. Povego TARAFINDAN SUNULAN İÇERİKLERDE, HERHANGİ BİR KİŞİYE / KURUMA / ŞİRKETE / MARKAYA YAPILAN ATIF, BU KİŞİLERİN / KURUMLARIN / ŞİRKETLERİN / MARKALARIN PİYASA DEĞERLERİNE, ÇEŞİTLİ KRİTERLERDEKİ SIRALAMALARINA, MARKA DEĞERLERİNE ETKİ YAPACAK VEYA HİSSE SENETLERİNİN SATIN ALINMASINA, SATILMASINA VEYA ELDE TUTULMASINA YÖNELİK BİR TAVSİYE DEĞİLDİR.<br/><br/>

11. Uygulanacak Hukuk ve Yetki<br/><br/>
11.1.<br/><br/>
Söz konusu sözleşme kapsamında povego.com.tr sitesinin kullanımından doğan ve/veya yasal uyarıda yer alan koşul ve hükümlere ilişkin ve/veya bu Site ile bağlantılı olarak çıkabilecek uyuşmazlıklarda işbu Kullanım Sözleşmesi ve Türkiye Cumhuriyeti Hukuku esas olup, İstanbul Mahkemeleri yetkilidir.<br/><br/>

12. Sözleşme Değişiklikleri<br/><br/>
12.1.<br/><br/>
Povego, bu yasal uyarıda yer alan tüm koşulları ve hükümleri önceden bildirmeye gerek kalmaksızın değiştirme ve güncelleme hakkına tek taraflı sahiptir.<br/><br/>

13. Yürürlük ve Kabul<br/><br/>
13.1.<br/><br/>
Bu internet sitesinin herhangi bir sayfasına girerek, Kullanım Sözleşmesi’nin tamamını okuduğunuzu, içeriğini bütünü ile anladığınızı ve tüm hükümlerini onayladığınızı kabul, beyan ve taahhüt etmektesiniz. Kullanım Sözleşmesi Povego tarafından Site üzerinde ilan edildiği tarihte yürürlük kazanır.<br/><br/>

13.2.<br/><br/>
Kullanım Sözleşmesi’nin şartlarını kabul etmeyen kullanıcıların Site’yi ve Site üzerinden sağlanan servisleri kullanmamaları gerekmektedir. Aksi halde doğmuş ya da doğacak olan zararlardan Povego sorumlu tutulamaz.<br/><br/>

13.3.<br/><br/>
Söz konusu sözleşme Povego tarafından www.povego.com.tr sitesinde ilan edildiği anda geçerlilik kazanır.<br/><br/>

              
              </Typography>
            </Box>
          </Container>
      </React.Fragment>
    );
  }
  
  export default Usage;
  